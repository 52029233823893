import { createSelector } from 'reselect';
import { SupportedTenantsEnum } from '../../constants/auth';

import { NO_DEPARTMENT } from '../../constants/organizations';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN, USER } from '../../constants/users';
import { hasPermission } from '../../utils/users';
import { IAppState } from '../reducers';
import {
  selectAdminType,
  selectDigitalBudgetValue,
  selectIsDigitalBudgetLoading,
  selectUserDepartmentId,
} from './auth';
import { selectBucketIsFetching, selectIsBulkLoading } from './bucket';
import { selectIsCampaignsFetching } from './campaigns';
import { selectIsOrganizationSummariesFetching } from './dashboard';
import { selectIsFulfillmentCentersFetching } from './fulfillmentCenters';
import {
  selectCRMIsLoading,
  selectCRMIsLoadingOpportunities,
  selectIsEmailIntegrationLoading,
  selectZapierIsLoading,
} from './integrations';
import { selectIsInventoryFetching, selectLowInventoryItems } from './inventories';
import { selectIsSingleOneLinkFetching } from './oneLink';
import {
  selectCurrentDepartmentRemainingBudget,
  selectIsDepartmentBudgetEnabled,
  selectOrganizationsIsLoading,
} from './organizations';
import { selectIsBusinessReasonsLoading } from './reasons';
import { selectIsSingleDeliveryReportsFetching } from './reports';
import { selectIsSignatureLoading } from './signature';
import { selectEmailTemplatesIsLoading, selectNoteTemplatesIsLoading, selectTemplateIsLoading } from './templates';
import { selectEngagementIsLoading } from './tools';
import { selectIsUsersFetching } from './users';

export const selectRootState = (state: IAppState) => state.shell;

export const selectIsShowDigitalBudget = createSelector(
  selectDigitalBudgetValue,
  selectAdminType,
  (digitalBudget, adminType) => {
    const isExist = typeof digitalBudget !== 'undefined' && digitalBudget !== null;
    return isExist && hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType);
  },
);

export const selectIsShowDepartmentBudget = createSelector(
  selectIsDepartmentBudgetEnabled,
  selectCurrentDepartmentRemainingBudget,
  selectAdminType,
  selectUserDepartmentId,
  (isEnabled, departmentBudget, adminType, depId) => {
    const isExist = typeof departmentBudget !== 'undefined' && departmentBudget !== null;
    return isEnabled && isExist && hasPermission([USER, DEPT_ADMIN], adminType) && depId !== NO_DEPARTMENT;
  },
);

export const selectIsThemeLoading = createSelector(selectRootState, (state) => state.isThemeLoading);

export const selectEmergencyNotification = createSelector(selectRootState, (state) => state.emergencyNotification);

export const selectIsShowContentLoader = createSelector(
  [
    selectBucketIsFetching,
    selectIsInventoryFetching,
    selectIsBulkLoading,
    selectIsUsersFetching,
    selectIsSingleDeliveryReportsFetching,
    selectIsSingleOneLinkFetching,
    selectCRMIsLoadingOpportunities,
    selectCRMIsLoading,
    selectNoteTemplatesIsLoading,
    selectEmailTemplatesIsLoading,
    selectTemplateIsLoading,
    selectIsEmailIntegrationLoading,
    selectIsBusinessReasonsLoading,
    selectIsDigitalBudgetLoading,
    selectZapierIsLoading,
    selectOrganizationsIsLoading,
    selectEngagementIsLoading,
    selectIsCampaignsFetching,
    selectIsFulfillmentCentersFetching,
    selectIsSignatureLoading,
    selectIsOrganizationSummariesFetching,
  ],
  (...flags: any) => flags.some((flag: boolean) => flag),
);

export const selectIsSidenavShown = createSelector(selectRootState, (shell) => (shell ? shell.sidenavIsShown : false));

export const selectIsBoxDetailsSidebarShown = createSelector(selectRootState, (shell) =>
  shell ? shell.boxDetailsSidebarIsShown : false,
);

export const selectIsDrawerShown = createSelector(selectRootState, (shell) => (shell ? shell.drawerIsShown : false));

export const selectIs2FADrawerShown = createSelector(selectRootState, (shell) =>
  shell ? shell.twoFASidenavIsShown : false,
);

export const selectIsFeedbackSidebarShown = createSelector(selectRootState, (shell) =>
  shell ? shell.feedbackSidenavIsShown : false,
);

export const selectIsSuggestGiftSidebarShown = createSelector(selectRootState, (shell) =>
  shell ? shell.suggestGiftSidenavIsShown : false,
);

export const selectMetadata = createSelector(selectRootState, (shell) => (shell ? shell.metadata : undefined));

export const selectTheme = createSelector(selectRootState, (shell) => (shell ? shell.theme : undefined));

export const selectCrmProviders = createSelector(selectMetadata, (metadata) =>
  metadata ? metadata.crm_providers : undefined,
);

export const selectEmailProviders = createSelector(selectMetadata, (metadata) =>
  metadata ? metadata.email_providers : undefined,
);

export const selectDelayedShippingBaseUrl = createSelector(selectMetadata, (metadata) =>
  metadata ? metadata.delayed_shipping_base_url : undefined,
);

export const selectFlags = createSelector(selectRootState, (shell) => shell.flags);

export const selectIsOrganizationFormExpanded = createSelector(
  selectFlags,
  (flags) => flags.isOrganizationFormExpanded,
);

export const selectDelayedShippingTextCustomizationTags = createSelector(selectMetadata, (metadata) =>
  metadata ? metadata.ds_text_customization_tags : null,
);

export const selectIsMskuOptionsFormShown = createSelector(selectFlags, (flags) => flags.isMskuOptionsFormShown);

export const selectIsAIExtensionInstalled = createSelector(selectFlags, (flags) => flags.isAIExtensionInstalled);

export const selectLowItemsSnoozedAt = createSelector(selectRootState, (shell) => shell.lowItemsSnoozedAt);

export const selectLowItemsShownAt = createSelector(selectRootState, (shell) => shell.lowItemsLastShownAt);

export const selectShouldShowLowInventoryWarning = createSelector(selectLowInventoryItems, (items) => !!items?.length);

export const selectAppTenant = createSelector(selectRootState, (shell) => shell.tenant);

export const selectIsJaneStreetTenant = createSelector(
  selectAppTenant,
  (tenant) => tenant === SupportedTenantsEnum.JaneStreet,
);

export const selectIsPaceTenant = createSelector(selectAppTenant, (tenant) => tenant === SupportedTenantsEnum.Pace);

export const selectIsVercelTenant = createSelector(selectAppTenant, (tenant) => tenant === SupportedTenantsEnum.Vercel);
