import { addDays } from 'date-fns';
import format from 'date-fns/format';

import { DELAYED_SHIPPING_EXPIRATION, MAX_DELAYED_SHIPPING_EXPIRATION_DATE, SEND_ON_HOLD } from '../constants/bucket';
import { DateFormatsEnum } from '../constants/date';
import { CRM_TYPE } from '../constants/shipping';
import {
  IFileInfo,
  IFlowStepProps,
  IOneLinkEngagementCandidate,
  TEngagementCandidate,
  TEngagementRequestPayload,
} from '../types/bucket';
import { ICampaign, ICampaignSummary } from '../types/campaigns';
import { IOnDemandItem } from '../types/inventories';
import { getBoxTotal } from './campaigns';
import { formatDate } from './date';
import { isItemMsku, isItemOnDemand } from './inventories';
import { isAwaitingAddress } from './reports';

export const getFileInfo = (file: File): IFileInfo | null => {
  if (!file) {
    return null;
  }
  const { name, lastModified, size } = file;

  return { name, lastModified, size };
};

export const formatLastModified = (date: number) => format(date, `d MMM, yyyy 'at' H:mm aaa`);

export const formatFileSize = (size: number) => {
  let unitPrefix = '';
  let sizeToShow = size.toString();

  if (size >= 100 && size < 1024 * 1024) {
    unitPrefix = 'K';
    sizeToShow = (size / 1024).toFixed(1);
  } else if (size >= 1024 * 1024) {
    unitPrefix = 'M';
    sizeToShow = (size / 1024 / 1024).toFixed(1);
  }

  return `${sizeToShow} ${unitPrefix}b`;
};

export const getMaxBoxPrice = (boxes?: (ICampaign | ICampaignSummary)[] | null) => {
  return boxes?.map(getBoxTotal).reduce((a, b) => Math.max(a, b)) || 0;
};

export const getBucketTotalPrice = (report?: TEngagementCandidate | null) => {
  if (!report) {
    return 0;
  }

  const { items, pick_campaigns: pickCampaigns } = report;

  return pickCampaigns ? getMaxBoxPrice(pickCampaigns) : getBoxTotal({ items });
};

export const getSubstringAfterChar = (str: string, char: string) => str?.substring(str.indexOf(char) + 1);

type EngagementMapperMetadata = Omit<IFlowStepProps, 'className' | 'SenderInfo' | 'isPYG'> & {
  isOnHold: boolean;
  crmType: 'salesforce' | 'hubspot' | 'none';
};

/**
 * Maps the engagement candidate to the engagement request payload.
 *
 * @param {TEngagementCandidate | IOneLinkEngagementCandidate} engagement - The engagement candidate to be mapped.
 * @param {EngagementMapperMetadata} metadata - Additional metadata for the engagement mapping.
 * @returns {TEngagementRequestPayload} - The mapped engagement request payload.
 */
export const mapEngagementCandidateToRequest = (
  engagement: TEngagementCandidate | IOneLinkEngagementCandidate,
  metadata: EngagementMapperMetadata,
): TEngagementRequestPayload => {
  const { items: engagementItems, pick_campaigns, ...restEngagement } = engagement;
  const { isOneLink, crmType, isOnHold: isToggleOnHoldEnabled } = metadata;
  const { on_hold_until, delayed_shipping_expiration } = engagement;

  const isDelayedShipping = !isOneLink && isAwaitingAddress(engagement as TEngagementCandidate);

  const onHoldUntilDefaultDate = addDays(new Date(), 30).toISOString();

  const items = engagementItems?.map((item) => {
    if (isItemOnDemand(item)) {
      const onDemandItem = item as IOnDemandItem;
      const { item_id, ...onDemandRequestItem } = onDemandItem;
      return onDemandRequestItem;
    }
    if (isItemMsku(item) && item.sku_options?.length) {
      const { sku_options, ...restItem } = item;

      return { ...restItem, sku_option_ids: sku_options?.map(({ item_id }) => item_id) };
    }

    return item;
  });

  const onHoldUntil = (() => {
    if (on_hold_until?.length) {
      return on_hold_until;
    }
    if (isToggleOnHoldEnabled) {
      return onHoldUntilDefaultDate;
    }
    return undefined;
  })();

  const expirationDate = (() => {
    if (!isDelayedShipping) {
      return;
    }
    if (delayed_shipping_expiration?.length) {
      return delayed_shipping_expiration;
    }

    return formatDate(MAX_DELAYED_SHIPPING_EXPIRATION_DATE.toISOString(), DateFormatsEnum.End);
  })();

  return {
    ...restEngagement,
    ...(pick_campaigns ? { pick_campaign_ids: pick_campaigns.map(({ box_id }) => box_id) } : {}),
    items,
    [SEND_ON_HOLD]: onHoldUntil,
    [DELAYED_SHIPPING_EXPIRATION]: expirationDate,
    ...(!isOneLink ? { [CRM_TYPE]: crmType } : {}),
  };
};
