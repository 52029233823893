import React from 'react';

import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-red-outline.svg';
import { ActionButton } from '../forms';

import styles from './DeleteAllWarning.module.scss';

interface IProps {
  onDeleteAll: () => void;
  onClose: () => void;
  message: string;
}

const DeleteAllWarning: React.FC<IProps> = ({ message, onDeleteAll, onClose }) => {
  return (
    <div className={styles.container}>
      <button className={styles.closeBtn} onClick={onClose} />
      <div className={styles.warning}>
        <WarningIcon />
        <h2>Are you sure?</h2>
        <p>{message}</p>
      </div>
      <div className={styles.buttons}>
        <ActionButton className={styles.delete} onClick={onDeleteAll}>
          Delete all
        </ActionButton>
        <ActionButton inline className={styles.cancel} onClick={onClose}>
          Cancel
        </ActionButton>
      </div>
    </div>
  );
};

export default DeleteAllWarning;
