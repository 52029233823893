import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as AlertIcon } from '../../assets/images/icon-alert.svg';
import { ReactComponent as BudgetSettingsIcon } from '../../assets/images/icon-budget-settings.svg';
import { ReactComponent as DepartmentIcon } from '../../assets/images/icon-building.svg';
import { ReactComponent as EnterIcon } from '../../assets/images/icon-enter.svg';
import { ReactComponent as FeedbackIcon } from '../../assets/images/icon-feedback.svg';
import { ReactComponent as SignatureIcon } from '../../assets/images/icon-signature-with-a-pen.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-round.svg';

import { endpoints, routes } from '../../constants/routing';
import { DIGITAL_BALANCE_EMAILS_DISABLED, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN, USER } from '../../constants/users';
import useFeatureBudgetErrors from '../../hooks/useFeatureBudgetErrors';
import useFetch from '../../hooks/useFetch';
import useFirebaseTools from '../../hooks/useFirebaseTools';
import useModal from '../../hooks/useModal';
import useYbugService from '../../hooks/useYbugService';
import { toggleUiElement } from '../../store/actions/shell';
import { selectAdminType, selectDigitalNotificationsDisabled, selectUserProfile } from '../../store/selectors/auth';
import {
  selectCurrentOrganization,
  selectIsDepartmentBudgetEnabled,
  selectIsDigitalBalanceLimitEnabled,
} from '../../store/selectors/organizations';
import {
  selectIsShowDepartmentBudget,
  selectIsShowDigitalBudget,
  selectShouldShowLowInventoryWarning,
} from '../../store/selectors/shell';
import { BUTTON_BUTTON } from '../../types/forms';
import { IApiError, IDropdownWithContentOptions, UISizeEnum } from '../../types/shell';
import { IToggleDigitalBalanceEmailsActionPayload } from '../../types/users';
import notification from '../../utils/notification';
import { hasPermission } from '../../utils/users';
import DropdownWithContent from '../DropdownWithContent/DropdownWithContent';
import { Checkbox, DepartmentBudget, DigitalBudget, Loader, LowInventoryMenuItem } from '../index';
import { ChangeOrganizationModal } from '../modals';
import UserInformation from '../UserInformation/UserInformation';

import styles from './HeaderMenu.module.scss';

const HeaderMenu: React.FC = () => {
  const dispatch = useDispatch();
  const { logout } = useFirebaseTools();
  const { openModal: openChangeOrgModal, closeModal: closeChangeOrgModal, Modal: ChangeOrgModal } = useModal();
  const { hasWarnings, setFeatureBudgetWarning } = useFeatureBudgetErrors();
  const { openYbugReport } = useYbugService();
  const hasLowInventoryWarning = useSelector(selectShouldShowLowInventoryWarning);

  const adminType = useSelector(selectAdminType);
  const userProfile = useSelector(selectUserProfile);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const isShowDigitalBudget = useSelector(selectIsShowDigitalBudget);
  const isShowDepartmentBudget = useSelector(selectIsShowDepartmentBudget);
  const isDepartmentBudgetEnabled = useSelector(selectIsDepartmentBudgetEnabled);
  const isDigitalBalanceNotificationsDisabled = useSelector(selectDigitalNotificationsDisabled);
  const isDigitalBalanceLimitFeatureEnabled = useSelector(selectIsDigitalBalanceLimitEnabled);

  const shouldShowDigitalNotificationsCheckbox = React.useMemo(() => {
    return isDigitalBalanceLimitFeatureEnabled && hasPermission([ORG_ADMIN], adminType);
  }, [adminType, isDigitalBalanceLimitFeatureEnabled]);

  const { isLoading: isDigitalReminderNotificationsLoading, make: toggleDigitalReminders } = useFetch<
    IToggleDigitalBalanceEmailsActionPayload,
    never
  >({
    method: 'PATCH',
    endpoint: endpoints.toggleDigitalBalanceEmails,
  });

  const changeOrgModal = React.useMemo(
    () => (
      <ChangeOrgModal className="common-modal">
        {() => {
          return <ChangeOrganizationModal onClose={closeChangeOrgModal} />;
        }}
      </ChangeOrgModal>
    ),
    [closeChangeOrgModal, ChangeOrgModal],
  );

  const handleOpenBudgetSidebar = React.useCallback(() => {
    dispatch(toggleUiElement({ drawerIsShown: true }));
  }, [dispatch]);

  const handleOpenFeedbackSidebar = React.useCallback(() => {
    openYbugReport();
  }, [openYbugReport]);

  const handleDigitalBudgedNotificationChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;

      toggleDigitalReminders({ body: { [DIGITAL_BALANCE_EMAILS_DISABLED]: checked } }).catch((error: IApiError) => {
        return notification.error(error.message, { content: error.message });
      });
    },
    [toggleDigitalReminders],
  );

  const menuWarningIcon = React.useMemo(() => {
    if (hasWarnings) {
      return <AlertIcon className={styles.alert} />;
    }

    if (hasLowInventoryWarning) {
      return <WarningIcon className={styles.alert} />;
    }
  }, [hasWarnings, hasLowInventoryWarning]);

  return (
    <React.Fragment>
      <DropdownWithContent
        label={() => (
          <React.Fragment>
            <div className={styles.dots} />
            <UserInformation avatarImageURL={userProfile?.photoURL} initialLetter={userProfile?.displayName[0]} />
            {menuWarningIcon}
          </React.Fragment>
        )}
        labelClassName={styles.menuLabel}
        contentClassName={styles.menuContent}
      >
        {({ close }: IDropdownWithContentOptions) => (
          <React.Fragment>
            <div className={styles.header}>
              {isShowDigitalBudget && <DigitalBudget onLowBudget={setFeatureBudgetWarning} />}
              {isDepartmentBudgetEnabled && isShowDepartmentBudget && (
                <DepartmentBudget onLowBudget={setFeatureBudgetWarning} />
              )}
              {shouldShowDigitalNotificationsCheckbox && (
                <React.Fragment>
                  <Loader isLoading={isDigitalReminderNotificationsLoading} size={UISizeEnum.Tiny} />
                  <Checkbox
                    containerClassName={styles.checkbox}
                    text="Don’t receive digital balance email"
                    checked={isDigitalBalanceNotificationsDisabled}
                    onChange={handleDigitalBudgedNotificationChange}
                  />
                </React.Fragment>
              )}
            </div>
            <div className={styles.content}>
              <div className={cn(styles.userName)}>{userProfile?.displayName}</div>
              <Link className={styles.control} type={BUTTON_BUTTON} to={routes.signatureSetup}>
                <SignatureIcon />
                <span>Signature Setup</span>
              </Link>
              {isDepartmentBudgetEnabled && hasPermission([DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN], adminType) && (
                <button
                  className={styles.control}
                  type={BUTTON_BUTTON}
                  onClick={() => {
                    handleOpenBudgetSidebar();
                    close();
                  }}
                >
                  <BudgetSettingsIcon />
                  <span>Departments management</span>
                </button>
              )}
              {!hasPermission([USER], adminType) && <LowInventoryMenuItem className={styles.control} onClick={close} />}
              {hasPermission([DISTRIBUTOR, SUPER_ADMIN], adminType) && (
                <button
                  className={styles.control}
                  type={BUTTON_BUTTON}
                  onClick={() => {
                    openChangeOrgModal();
                    close();
                  }}
                >
                  <DepartmentIcon />
                  <span>{currentOrganization?.name}</span>
                </button>
              )}
              <button
                className={styles.control}
                type={BUTTON_BUTTON}
                onClick={() => {
                  handleOpenFeedbackSidebar();
                  close();
                }}
              >
                <FeedbackIcon />
                <span>Leave Feedback</span>
              </button>
              <button
                className={styles.control}
                type={BUTTON_BUTTON}
                onClick={() => {
                  logout();
                  close();
                }}
              >
                <EnterIcon />
                <span>Log Out</span>
              </button>
            </div>
          </React.Fragment>
        )}
      </DropdownWithContent>
      {changeOrgModal}
    </React.Fragment>
  );
};

export default HeaderMenu;
