import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditIcon from '../../assets/images/icon-edit-button.svg';
import { Budget, Drawer, EditableTable, Price } from '../../components';
import { AddButton, TextIconButton, TextIconToggle } from '../../components/forms';
import { NumericInput } from '../../components/forms/inputs';
import { AVAILABLE_BUDGET, DEPARTMENT_TABLE_HEADERS, NAME, USED_BUDGET } from '../../constants/organizations';
import { DEPARTMENT_BUDGET_WARNING_RATE } from '../../constants/shell';
import useWindowSize from '../../hooks/useWindowSize';
import { addOrganizationDepartmentRequest, editOrganizationDepartmentRequest } from '../../store/actions/organizations';
import { selectCanEditOrganization } from '../../store/selectors/organizations';
import { EditableTableModes } from '../../types/forms';
import { IDepartment, IDepartmentDetails, ISimplifiedOrganization } from '../../types/organizations';
import { DrawerAnimationDirectionEnum, NotificationListEnum } from '../../types/shell';
import { ITableConfig } from '../../types/tables';
import notification from '../../utils/notification';
import { sortByName } from '../../utils/organizations';
import { handleApiError } from '../../utils/ui';

import variables from '../../_variables.scss';
import styles from './OrganizationSidebar.module.scss';

interface IProps {
  onActivate?: (id: string) => void;
  onDeactivate?: (id: string) => void;
  onEdit?: (id: string) => void;
  onClose: () => void;
  trigger: boolean;
  expanded: boolean;
  organization?: ISimplifiedOrganization;
  isRowLoading?: boolean;
  children?: React.ReactNode;
}

const OrganizationSidebar: React.FC<IProps> = ({
  onActivate,
  onDeactivate,
  onEdit,
  onClose,
  trigger,
  expanded,
  organization,
  isRowLoading,
  children,
}) => {
  const dispatch = useDispatch();
  const [activeDepartment, setActiveDepartment] = React.useState<IDepartmentDetails | null>(null);
  const { mobile, HD } = useWindowSize();

  const canEditOrganization = useSelector(selectCanEditOrganization);

  const originalDepartment = React.useMemo(
    () => organization?.org_departments?.find((d) => d.uid === activeDepartment?.uid),
    [organization, activeDepartment],
  );

  const handleClose = React.useCallback(() => {
    setActiveDepartment(null);
    onClose();
  }, [onClose]);

  const handleOnAddNewDepartment = React.useCallback(
    (uid: string) => () => setActiveDepartment({ name: '', org_id: uid, available_budget: void 0 }),
    [],
  );

  const renderHeader = React.useMemo(() => {
    // TODO: Replace `isOrgActive` with the real value when ready
    const isOrgActive = true;

    const { name, uid, logo_url } = organization || {};

    if (!name || !uid) {
      return null;
    }

    return (
      <div className={styles.header}>
        <button className={styles.closeBtn} onClick={handleClose} />
        {logo_url && <img className={styles.logo} src={logo_url} alt="" />}
        <div className={styles.mainInfo}>
          <h3 className={styles.name}>{name}</h3>
          {canEditOrganization && (
            <div className={styles.controls}>
              {mobile && (
                <AddButton className={cn(styles.actionBtn, styles.addBtn)} onClick={handleOnAddNewDepartment(uid)} />
              )}
              {expanded && (
                <React.Fragment>
                  {onEdit && (
                    <TextIconButton
                      trigger={mobile}
                      className={styles.actionBtn}
                      icon={EditIcon}
                      onClick={() => onEdit(uid)}
                      title="Edit"
                    />
                  )}
                  {onDeactivate && (
                    <TextIconToggle
                      title={isOrgActive ? 'Deactivate' : 'Activate'}
                      trigger={mobile}
                      active={isOrgActive}
                      textClassName={cn(styles.actionBtn, isOrgActive ? styles.activate : styles.deactivate)}
                      onClick={() => (isOrgActive ? onDeactivate?.(uid) : onActivate?.(uid))}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        {expanded && (
          <div className={styles.integrations}>
            <span className={styles.item}>
              <span className={styles.title}>Supporting CRM:</span>
              <span className={styles.value}>{organization?.org_options?.crm_currently_supports}</span>
            </span>
            <span className={styles.item}>
              <span className={styles.title}>Email System:</span>
              <span className={styles.value}>{organization?.org_options?.email_currently_supports}</span>
            </span>
          </div>
        )}
      </div>
    );
  }, [
    organization,
    mobile,
    handleClose,
    expanded,
    canEditOrganization,
    handleOnAddNewDepartment,
    onActivate,
    onDeactivate,
    onEdit,
  ]);

  const tableMode = React.useMemo(() => {
    const { uid, name } = activeDepartment || {};
    if (!uid && typeof name !== 'undefined') {
      return EditableTableModes.Add;
    }
    if (uid && typeof name !== 'undefined') {
      return EditableTableModes.Edit;
    }
    return undefined;
  }, [activeDepartment]);

  const isChanged = React.useMemo(
    () => !isEqual(originalDepartment, pick(activeDepartment, keys(originalDepartment))),
    [originalDepartment, activeDepartment],
  );

  const handleAddDepartment = React.useCallback(
    (department: Omit<IDepartmentDetails, 'uid' | 'created_date' | 'used_budget'>) => {
      if (department) {
        return new Promise((resolve, reject) => {
          dispatch(addOrganizationDepartmentRequest({ department, resolve, reject }));
        })
          .then(() => {
            notification.success(NotificationListEnum.Success, { content: `New Department was added` });
            setActiveDepartment(null);
          })
          .catch(handleApiError('Something bad happened. New Department reason was not added'));
      }
      notification.enterValidData('Department');
      return Promise.reject();
    },
    [dispatch],
  );

  const handleEditDepartment = React.useCallback(
    (dept: IDepartment | IDepartmentDetails) => {
      if (typeof dept.uid !== 'undefined') {
        const department = dept as IDepartment;
        return new Promise<IDepartment>((resolve, reject) => {
          const { uid } = organization || {};
          department.org_id = uid;
          dispatch(editOrganizationDepartmentRequest({ department, resolve, reject }));
        })
          .then(() => {
            setActiveDepartment(null);
            return department;
          })
          .catch(handleApiError('Something bad happened. Department was not edited'));
      }
      notification.enterValidData();
      return Promise.reject();
    },
    [dispatch, organization],
  );

  const departmentTableConfig: ITableConfig<IDepartment> = React.useMemo(() => {
    const departmentBudgetEnabled = organization?.org_options?.department_budget_enabled;

    return {
      columns: {
        /*
            If the Department Budget feature isn't enabled only the NAME header should be displayed.
            In other case, the whole array of headers should be displayed
            Also, according to the mock-ups the NAME header isn't needed on the `xss` - `xs` resolutions
          */
        headers: (() => {
          let headers: Partial<typeof DEPARTMENT_TABLE_HEADERS> = departmentBudgetEnabled
            ? DEPARTMENT_TABLE_HEADERS
            : { [NAME]: DEPARTMENT_TABLE_HEADERS[NAME] };

          if (mobile) {
            const { [NAME]: _, ...mobileHeaders } = DEPARTMENT_TABLE_HEADERS;
            headers = mobileHeaders;
          }

          return headers;
        })(),
        /*
            If the Department Budget feature isn't enabled only the NAME header should be displayed.
          */
        readOnly: (() => {
          const headers: (keyof IDepartment)[] = [USED_BUDGET];

          return departmentBudgetEnabled ? headers : void 0;
        })(),
        /*
            If the Department Budget feature isn't enabled only the NAME header should be displayed.
          */
        visible: (() => {
          const headers: (keyof IDepartment)[] = [NAME, USED_BUDGET, AVAILABLE_BUDGET];

          return departmentBudgetEnabled ? headers : headers.slice(0, 1);
        })(),
      },
      cells: {
        inputs: {
          [NAME]: {
            props: {
              placeholder: `Input Department`,
              className: styles.editableCell,
            },
          },
          [AVAILABLE_BUDGET]: {
            props: {
              placeholder: `Input Limit`,
              prefix: '$',
              className: styles.editableCell,
            },
            render: NumericInput,
          },
        },
        views: {
          [AVAILABLE_BUDGET]: {
            render: ({ available_budget: availableBudget }: IDepartment) => {
              return <Price value={availableBudget} thousandSeparator />;
            },
          },
          [USED_BUDGET]: {
            render: ({ used_budget: usedBudget = 0, available_budget: availableBudget = 0 }: IDepartment) => {
              return (
                <Budget
                  className={styles.budget}
                  usedBudget={usedBudget}
                  availableBudget={availableBudget}
                  lowBudgetRate={DEPARTMENT_BUDGET_WARNING_RATE}
                  remainingBudgetHint={'Remaining budget'}
                  availableBudgetHint={'Available budget'}
                />
              );
            },
          },
        },
      },
    };
  }, [organization, mobile]);

  const handleSubmit: (department: IDepartment | IDepartmentDetails) => Promise<void | IDepartment> =
    React.useMemo(() => {
      switch (true) {
        case tableMode === EditableTableModes.Add:
          return handleAddDepartment;
        case tableMode === EditableTableModes.Edit:
          return handleEditDepartment;
        default:
          return Promise.reject;
      }
    }, [tableMode, handleEditDepartment, handleAddDepartment]);

  const renderDepartments = React.useMemo(() => {
    // tslint:disable-next-line:variable-name
    const { org_departments, uid } = organization || {};

    if (!uid) {
      return null;
    }

    return (
      <EditableTable<IDepartment | IDepartmentDetails>
        className={styles.departments}
        listClassName={styles.list}
        headerClassName={styles.tableTitle}
        rowClassName={styles.row}
        mode={tableMode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { name, value } = e.currentTarget;
          setActiveDepartment((prevDepartmentValue) => ({
            ...(prevDepartmentValue ? prevDepartmentValue : {}),
            [name]: value,
          }));
        }}
        activeItem={activeDepartment}
        items={org_departments && org_departments.sort(sortByName())}
        onAdd={handleOnAddNewDepartment(uid)}
        isChanged={isChanged}
        onSave={handleSubmit}
        onEdit={setActiveDepartment}
        onCancel={() => setActiveDepartment(null)}
        isLoading={isRowLoading}
        checkEditMode={(department: IDepartment | Partial<IDepartmentDetails>) =>
          department?.uid === activeDepartment?.uid
        }
        placeholderLabel="No departments yet"
        customSavedMessage="Saved"
        config={departmentTableConfig}
      />
    );
  }, [
    tableMode,
    organization,
    activeDepartment,
    isChanged,
    isRowLoading,
    handleSubmit,
    departmentTableConfig,
    handleOnAddNewDepartment,
  ]);

  const from = React.useMemo(() => (mobile ? variables.orgSidebarMinHeight : variables.orgSidebarMinWidth), [mobile]);
  const to = React.useMemo(() => {
    if (mobile) {
      return variables.orgSidebarMaxHeight;
    }

    if (HD) {
      return variables.orgSidebarMaxWidthHD;
    }

    return variables.orgSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  // Renders children instead of the standard layout to be able to show some custom UI, like custom loader, etc
  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={handleClose}
      withOverlay
      overlayClassName={styles.overlay}
    >
      {organization?.uid &&
        trigger &&
        (children || (
          <React.Fragment>
            {renderHeader}
            {renderDepartments}
          </React.Fragment>
        ))}
    </Drawer>
  );
};

export default OrganizationSidebar;
