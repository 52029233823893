import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as AlertIcon } from '../../assets/images/icon-alert.svg';
import { ReactComponent as BudgetIcon } from '../../assets/images/icon-film.svg';
import { FeatureBudgetEnum } from '../../types/shell';
import Price from '../Price/Price';

import styles from './FeatureBudget.module.scss';

export interface IFeatureBudgetProps {
  label: string;
  name: FeatureBudgetEnum;
  warningValue: number;
  budgetValue?: number | string | null;
  hint?: string;
  className?: string;
  onLowBudget?: (name: FeatureBudgetEnum, data: boolean) => void;
}

const FeatureBudget = ({
  label,
  name,
  warningValue,
  hint = '',
  budgetValue,
  onLowBudget,
  className,
}: IFeatureBudgetProps) => {
  const isLowBudget = React.useMemo(() => {
    if (typeof budgetValue === 'undefined' || budgetValue === null) {
      return false;
    }

    return budgetValue < warningValue;
  }, [budgetValue, warningValue]);

  React.useEffect(() => {
    if (onLowBudget) {
      onLowBudget(name, isLowBudget);
    }
  }, [onLowBudget, name, isLowBudget]);

  return (
    <div title={hint} className={cn(className, styles.userBudget)}>
      <div className={styles.iconWrapper}>
        <BudgetIcon />
        {isLowBudget && <AlertIcon className={styles.alert} />}
      </div>
      <div className={styles.info}>
        <span className={styles.description}>{label}&nbsp;</span>
        {typeof budgetValue === 'string' ? (
          <span title={hint} className={cn(styles.currency, { [styles.warning]: isLowBudget })}>
            {budgetValue}
          </span>
        ) : (
          <Price
            value={budgetValue || 0}
            hint={hint}
            thousandSeparator
            className={cn(styles.currency, { [styles.warning]: isLowBudget })}
          />
        )}
      </div>
    </div>
  );
};

export default FeatureBudget;
