import * as yup from 'yup';

import { ISelectorValue } from '../types/shell';
import { USA } from './countries';
import { NO_DEPARTMENT } from './organizations';
import { URL_VARS } from './routing';

export const USER = 'user';
export const ADMIN = 'admin';
export const SUPER_ADMIN = 'super';
export const ORG_ADMIN = 'org';
export const DEPT_ADMIN = 'dept';
export const DISTRIBUTOR = 'dist';

export const USER_LABEL = 'User';

export const ADDRESS1 = 'address1';
export const ADDRESS2 = 'address2';
export const CITY = 'city';
export const COUNTRY = 'country';
export const EMAIL = 'email';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const PHONE = 'phone';
export const STATE = 'state';
export const ZIP = 'zip';
export const DEPARTMENT_ID = 'department_id';
export const DISTRIBUTOR_ORG_IDS = 'distributor_org_ids';
export const ADMIN_TYPE = 'admin_type';
export const AVAILABLE_BUDGET = 'available_budget';
export const UID = 'uid';
export const CRM_SKIP_ALLOWED = 'crm_skip_allowed';
export const DIGITAL_BALANCE_EMAILS_DISABLED = 'digital_balance_emails_disabled';

export enum UserFilterTypesEnum {
  All,
  Admin,
  User,
}

export const USER_FILTERS = [
  {
    label: 'All',
    tabId: UserFilterTypesEnum.All,
  },
  {
    label: 'Admin',
    tabId: UserFilterTypesEnum.Admin,
  },
  {
    label: 'User',
    tabId: UserFilterTypesEnum.User,
  },
];

export const UserSuccessToastMessages = {
  [URL_VARS.NEW]: 'New user was added',
  [URL_VARS.EDIT]: 'User was edited',
};

export const USER_INITIAL_VALUES = {
  [ADMIN_TYPE]: '',
  [DEPARTMENT_ID]: NO_DEPARTMENT,
  [DISTRIBUTOR_ORG_IDS]: [] as string[],
  [EMAIL]: '',
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [ADDRESS1]: '',
  [ADDRESS2]: '',
  [CITY]: '',
  [STATE]: '',
  [ZIP]: '',
  [COUNTRY]: '',
  [PHONE]: '',
  [AVAILABLE_BUDGET]: 0,
  [CRM_SKIP_ALLOWED]: false,
};

export const USERS_VALIDATION_SCHEMA = yup.object().shape({
  [ADMIN_TYPE]: yup.string().required(),
  [DEPARTMENT_ID]: yup.string().required(),
  [DISTRIBUTOR_ORG_IDS]: yup.mixed().when(ADMIN_TYPE, {
    is: DISTRIBUTOR,
    then: yup.array().of(yup.string().required()).min(1, 'This field must have at least 1 value'),
    otherwise: yup.mixed().notRequired(),
  }),
  [EMAIL]: yup.string().email().required(),
  [FIRST_NAME]: yup.string().required(),
  [LAST_NAME]: yup.string().required(),
  [ADDRESS1]: yup.string().when(COUNTRY, {
    is: USA.two_digit_code,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  [ADDRESS2]: yup.string(),
  [CITY]: yup.string().required(),
  [STATE]: yup.string().when(COUNTRY, {
    is: USA.two_digit_code,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  [ZIP]: yup.string().required(),
  [COUNTRY]: yup.string().required(),
  [PHONE]: yup.string().required(),
  [AVAILABLE_BUDGET]: yup.number().moreThan(0).required(),
  [CRM_SKIP_ALLOWED]: yup.boolean(),
});

export const USER_FORM_REQUIRED_FIELDS_I18L = [] as string[];

export const USER_FORM_REQUIRED_FIELDS_USA = USER_FORM_REQUIRED_FIELDS_I18L.concat(ADDRESS1, STATE);

export const ROLES_SELECT_OPTIONS = (role?: string): ISelectorValue[] => {
  const roles = [
    {
      label: 'User',
      value: USER,
    },
    {
      label: 'Department Admin',
      value: DEPT_ADMIN,
    },
    {
      label: 'Organization Admin',
      value: ORG_ADMIN,
    },
    {
      label: 'Distributor',
      value: DISTRIBUTOR,
    },
  ];

  if (role === SUPER_ADMIN) {
    roles.push({
      label: 'Super Admin',
      value: SUPER_ADMIN,
    });
  }

  return roles;
};

export const USERS_EDITING_PERMISSION_LIST: Record<string, string[]> = {
  [SUPER_ADMIN]: [SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN, DEPT_ADMIN, USER, ''],
  [DISTRIBUTOR]: [ORG_ADMIN, DEPT_ADMIN, USER, ''],
  [ORG_ADMIN]: [ORG_ADMIN, DEPT_ADMIN, USER, ''],
};

export const USER_REASSIGN_REQUIRED_ERROR_CODE = 'UserReassignRequired';
