import cn from 'classnames';
import * as React from 'react';

import styles from './CheckboxFilter.module.scss';

interface IProps {
  isActive: boolean;
  label: string;
  onClick: () => void;
  className?: string;
}

const CheckboxFilter: React.FC<IProps> = ({ isActive, label, onClick, className }) => {
  return (
    <div className={styles.container}>
      <label className={cn(styles.label, className, { [styles.checked]: isActive })}>
        <input type="checkbox" checked={isActive} onChange={onClick} className={styles.checkbox} />
        {label}
      </label>
    </div>
  );
};

export default CheckboxFilter;
