import cn from 'classnames';
import sortBy from 'lodash/sortBy';
import * as React from 'react';

import { ILowInventoryCountItem } from '../../../types/dashboard';
import { isItemDigital } from '../../../utils/inventories';
import { InputLabel } from '../../forms';
import { NoResultsPlaceholder } from '../../index';
import LowInventoryItem from './LowInventoryItem/LowInventoryItem';

import styles from './LowItemsWidget.module.scss';

interface IProps {
  className?: string;
  items: ILowInventoryCountItem[] | null;
}

const LowItemsWidget: React.FC<IProps> = ({ className, items }) => {
  const sortedItems = React.useMemo(() => sortBy(items, ['count.value', 'name']), [items]);

  return (
    <div className={cn(className, styles.container)}>
      <InputLabel value="Low number of items" />
      <div className={cn(styles.list, { [styles.empty]: !items?.length })}>
        {!sortedItems?.length ? (
          <NoResultsPlaceholder
            className={styles.placeholder}
            imageClassName={styles.image}
            label="No current issues"
          />
        ) : (
          sortedItems?.map(
            ({
              count,
              item_id,
              image_url,
              name,
              price,
              sku,
              type,
              item_customization_allowed: isCustomizable,
              preorder_allowed: isPreorder,
            }) => {
              const isDigital = isItemDigital({ type });
              return (
                <LowInventoryItem
                  itemId={item_id}
                  className={styles.itemWrapper}
                  iconClassName={styles.iconInner}
                  count={count}
                  key={item_id}
                  type={type}
                  iconUrl={image_url}
                  title={name}
                  price={price}
                  sku={sku}
                  isDigital={isDigital}
                  isCustomizable={isCustomizable}
                  isPreorder={isPreorder}
                />
              );
            },
          )
        )}
      </div>
    </div>
  );
};

export default LowItemsWidget;
