import * as React from 'react';
import { SupportedTenantsEnum } from '../constants/auth';

import { IPromiseCallbacks } from './redux';

//#region Common Enums

export enum InventorySidebarTypeEnum {
  InventoryItem,
  Campaign,
}

export enum InventorySidebarModeEnum {
  View,
  Select,
}

export enum DrawerAnimationDirectionEnum {
  Horizontally,
  Vertically,
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum UISizeEnum {
  Normal = 'normal',
  Medium = 'medium',
  Small = 'small',
  Tiny = 'tiny',
}

export enum StatusTooltipTypeEnum {
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}
export enum FeatureBudgetEnum {
  Digital = 'digital',
  Department = 'department',
}

export enum FilterTypesEnum {
  Organization,
  Individual,
  Department,
}

export enum SearchByTypesEnum {
  Name = 'name',
  ProductSKU = 'product_sku',
}

export enum CopyableLinkTypesEnum {
  Tracking,
  Shipping,
  DigitalGift,
  OneLink,
}

export enum SupportedFileTypesEnum {
  CSV = 'csv',
}

export enum PositionStylesEnum {
  Right = 'right',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
}

export enum BoxDetailsSidebarModeEnum {
  Edit = 'edit',
  View = 'view',
}

//#region Shell Enums

export enum StatusEnum {
  Available = 'available',
  NotAvailable = 'not_available',
  Failed = 'failed',
}

export enum NotificationListEnum {
  DateRangeError = 'date-range',
  SOBUserHasNoGmailIntegration = 'sob-user-has-no-gmail-integration',
  ComingSoon = 'coming-soon',
  OutOfMoney = 'out-of-money',
  PYGCampaignIsNotSelected = "pyg-campaign-isn't-selected",
  RestrictionOnSelectionFromSelect = 'restriction-on-selection-from-select',
  DownloadData = 'download-data',
  CanNotEdit = 'can-not-edit',
  AccessDenied = 'access-denied',
  NoAsset = 'no-asset',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  EnterValidData = 'enter-valid-data',
  AddIntegrationSuccess = 'add-integration-success',
  AddIntegrationError = 'add-integration-error',
  AddIntegrationPending = 'add-integration-pending',
  EditEngagement = 'edit-engagement',
}

export enum TwoFASidebarModesEnum {
  Create = 'create',
  Edit = 'edit',
}

//#endregion

export interface IShellState {
  theme: ITheme;
  tenant?: SupportedTenantsEnum;
  sidenavIsShown: boolean | undefined;
  drawerIsShown: boolean;
  boxDetailsSidebarIsShown: boolean;
  twoFASidenavIsShown: boolean;
  feedbackSidenavIsShown: boolean;
  suggestGiftSidenavIsShown: boolean;
  isThemeLoading: boolean;
  lowItemsSnoozedAt: string;
  lowItemsLastShownAt: string;
  metadata: IMetadataState;
  emergencyNotification?: IEmergencyNotification | null;
  flags: {
    isOrganizationFormExpanded: boolean;
    isMskuOptionsFormShown: boolean;
    isAIExtensionInstalled: boolean;
  };
  build: string;
  commit: string;
}

export interface ITheme {
  portal_sidebar_color?: string;
  portal_sidebar_text_color?: string;
  portal_accent_color?: string;
  org_rect_logo?: string;
  org_square_logo?: string;
  shipping_page_body?: string;
  shipping_page_title?: string;
  shipping_page_button_color?: string;
  shipping_page_header_color?: string;
}

export interface IMetadataState extends IMetadata {
  isLoading: boolean;
  error: any;
}

export interface IEmergencyNotification {
  message?: string;
  hint?: string;
  is_active?: boolean;
}

export interface IMetadata {
  email_providers: ISelectorValue[] | null;
  crm_providers: ISelectorValue[] | null;
  delayed_shipping_base_url: string;
  ds_text_customization_tags: string[] | null;
}

export type TToggleActionPayload = {
  [key in keyof Partial<IShellState>]: boolean;
};

export interface ITwoFAToggleRef {
  open: () => void;
  close: () => void;
  toggle: () => void;
  isOpened: boolean;
}

export interface ICountryItem {
  name: string;
  two_digit_code: string;
  three_digit_code: string;
  numeric: number;
}

export interface IHeaderConfig {
  title?: string | React.ReactNode;
  action?: ((...args: any[]) => void) | undefined | null;
  isShowHeader?: boolean;
  isShowHeaderTitle?: boolean;
  additionalData?: React.ReactNode;
  className?: string;
}

export interface IFilterItem {
  label: string;
  key: string;
  onClick: () => void;
  isActive: boolean;
  className?: string;
}

export interface ISelectorValue {
  value: string;
  label: string;
}

export interface IChatGPTSelectorValue extends ISelectorValue {
  fieldName?: string;
  instruction: (text: string) => string;

  config: {
    temperature: number;
    max_tokens?: number;
    presence_penalty?: number;
    frequency_penalty?: number;
  };
}

export interface IDropdownWithContentOptions {
  close: () => void;
  isOpened: boolean;
}

export interface IUseFilterSelectorContainerProp extends IDropdownWithContentOptions {
  reset: () => void;
  select: () => void;
  children?: React.ReactNode;
}

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export interface IApiError {
  message?: string;
  status?: number;
}

export interface IIntegrationError {
  error?: string;
}

export interface IFormFieldConfig {
  disabled?: boolean;
}

export interface IDatePickerOutput {
  from?: string | null;
  to?: string | null;
}

// Commonly used redux interfaces

export interface IDownloadCSVSuccessPayload {
  url: string;
}

export interface ISendFeedbackPayload extends IPromiseCallbacks {
  message: string;
}

export interface IImproveTextPayload extends IPromiseCallbacks {
  text: string;
  temperature: number;
  max_tokens?: number;
  presence_penalty?: number;
  frequency_penalty?: number;
}

export interface IImproveTextResponse {
  text: string;
}
