import cn from 'classnames';
import addYears from 'date-fns/addYears';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as LockIcon } from '../../../assets/images/icon-lock.svg';
import { DateFormatsEnum } from '../../../constants/date';
import { ONE_LINK_REDEMTION_LIMIT } from '../../../constants/oneLink';
import {
  ALLOWED_DOMAIN,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  ENABLED_2FA,
  EXPIRATION_DATE,
  PASSWORD,
  REDEMPTION_LIMIT,
  SHIPPING,
  SHIPPING_NOTIFICATIONS_OPTIONS,
  SHIPPING_OPTION,
  SHIPPING_OPTIONS,
} from '../../../constants/shipping';
import { selectUserEmail } from '../../../store/selectors/auth';
import { select2FARecipientsList, selectAllowedDomain, selectSOBEmail } from '../../../store/selectors/bucket';
import { IOneLinkFormFields } from '../../../types/bucket';
import { BUTTON_BUTTON } from '../../../types/forms';
import { ITwoFAToggleRef } from '../../../types/shell';
import { getSubstringAfterChar } from '../../../utils/bucket';
import { TwoFASidebarToggle } from '../../index';
import {
  CheckboxGroup,
  CopyableButton,
  DateSinglePicker,
  HiddenInput,
  InputLabel,
  RadioButtonGroup,
  Toggle,
} from '../index';
import { Input, QuantityInput, ToggleInput } from '../inputs';
import { ICheckboxProps } from '../inputs/CheckboxGroup/CheckboxGroup';

import { addBucketValue } from '../../../store/actions/bucket';

import styles from './OneLinkShippingForm.module.scss';

interface IProps {
  className?: string;
  onChange: (name: string, value: number | string | boolean | null) => void;
  form: FormikProps<IOneLinkFormFields>;
  isDigital: boolean;
  isShippingOptionEnabled?: boolean;
}

const OneLinkShippingForm = ({ className, form, onChange, isDigital, isShippingOptionEnabled }: IProps) => {
  // The ref below is an imperative handle for the 2FA Sidebar control
  const twoFASidebarRef = React.useRef<ITwoFAToggleRef>(null);

  const recipients = useSelector(select2FARecipientsList);
  const userEmail = useSelector(selectUserEmail);
  const SOBEmail = useSelector(selectSOBEmail);
  const allowedDomain = useSelector(selectAllowedDomain);

  const [is2FAEnabled, setIs2FAEnabled] = React.useState<boolean>(!!recipients?.successfulRecipients?.length);

  const dispatch = useDispatch();

  const senderEmail = SOBEmail || userEmail;
  const senderEmailDomain = getSubstringAfterChar(senderEmail!, '@');

  const handleChange = useCallback(
    (name: string, value: boolean) => {
      form.setFieldValue(name, value, true);
      onChange(name, value);
    },
    [onChange, form.setFieldValue],
  );

  const notificationOptions: ICheckboxProps[] = useMemo(
    () => [
      {
        checked: form?.values?.[DISABLE_SENDER_EMAIL_NOTIFICATIONS],
        name: DISABLE_SENDER_EMAIL_NOTIFICATIONS,
        id: DISABLE_SENDER_EMAIL_NOTIFICATIONS,
        text: SHIPPING_NOTIFICATIONS_OPTIONS.SENDER.TEXT,
        textAbout: SHIPPING_NOTIFICATIONS_OPTIONS.SENDER.TEXT_ABOUT,
      },
    ],
    [form.values],
  );

  const domainRestrictionLabel = React.useMemo(() => {
    const btn =
      allowedDomain && senderEmailDomain && allowedDomain !== senderEmailDomain ? (
        <button
          className={styles.allowedDomainLabelButton}
          type={BUTTON_BUTTON}
          onClick={() => onChange(ALLOWED_DOMAIN, senderEmailDomain)}
        >
          Apply Sender's Domain
        </button>
      ) : null;

    return <>{btn}</>;
  }, [allowedDomain, senderEmailDomain, onChange]);

  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.info}>
        At ‘Create One Link’ flow you can’t add a recipient. All you have to do is to create a send and give your
        recipients the link with shipment information details. After they fill it your send will be initiated.
      </span>
      <form className={styles.form}>
        {!isDigital && isShippingOptionEnabled ? (
          <div className={cn(styles.optionsList)}>
            <span className={styles.label}>Delivery Options</span>
            <RadioButtonGroup
              options={SHIPPING_OPTIONS}
              value={form.values?.[SHIPPING]?.[SHIPPING_OPTION]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = e.target;
                return onChange(name, value);
              }}
              name={`${SHIPPING}.${SHIPPING_OPTION}`}
            />
          </div>
        ) : (
          <HiddenInput name={`${SHIPPING}.${SHIPPING_OPTION}`} value={form.values?.[SHIPPING]?.[SHIPPING_OPTION]} />
        )}
        <div className={styles.datePicker}>
          <ToggleInput
            onChange={onChange}
            name={EXPIRATION_DATE}
            toggleLabel="Set expiration date"
            value={form.values?.[EXPIRATION_DATE]!}
            direction="row"
            hint="Stops the campaign at the chosen time. You can also stop it whenever you want from ‘One Link Manager’."
          >
            {({ isActive }) =>
              isActive ? (
                <DateSinglePicker
                  minDate={new Date()}
                  maxDate={addYears(new Date(), 1)}
                  value={form.values?.[EXPIRATION_DATE]}
                  formatRule={DateFormatsEnum.End}
                  onSelect={(value) => onChange(EXPIRATION_DATE, value)}
                  defaultLabel="No expiration date"
                />
              ) : null
            }
          </ToggleInput>
        </div>
        <div className={styles.datePicker}>
          <ToggleInput
            onChange={onChange}
            name={REDEMPTION_LIMIT}
            toggleLabel="Redemption limit"
            value={form.values?.[REDEMPTION_LIMIT]!}
            direction="row"
            initValue={ONE_LINK_REDEMTION_LIMIT}
          >
            {({ isActive }) =>
              isActive ? (
                <QuantityInput
                  onChange={(value) => onChange(REDEMPTION_LIMIT, value!)}
                  value={form.values?.[REDEMPTION_LIMIT]!}
                  inputClassName={styles.quantityInput}
                />
              ) : null
            }
          </ToggleInput>
        </div>
        <CheckboxGroup items={notificationOptions} onChange={handleChange} />

        <div className={styles.advancedSettingContainer}>
          <InputLabel value="Advanced settings" />
          <div className={styles.advancedSettings}>
            <ToggleInput
              name={PASSWORD}
              value={form.values?.[PASSWORD]!}
              toggleLabel="Password protection"
              inputLabel="Your password"
              hint="Recipients will be asked to enter a password to access the send."
              onChange={onChange}
              isRequired
            >
              {({ isActive, isReadOnly, toggleInputStyles }) => (
                <>
                  <Input
                    value={form.values?.[PASSWORD]}
                    name={PASSWORD}
                    onChange={(e) => onChange?.(PASSWORD, e.target.value)}
                    inputClassName={cn(toggleInputStyles.input, toggleInputStyles.isCopyable, {
                      [toggleInputStyles.success]: form.values?.[PASSWORD]!.trim() && !isReadOnly,
                    })}
                    icon={<LockIcon />}
                    disabled={!isActive}
                    shouldTrimValue
                    readOnly={isReadOnly}
                  />
                  <CopyableButton
                    key={PASSWORD}
                    value={form.values?.[PASSWORD]!}
                    disabled={!isActive || (!form.values?.[PASSWORD]!.trim() && !isReadOnly)}
                    className={cn(toggleInputStyles.copyButton, {
                      [toggleInputStyles.success]: form.values?.[PASSWORD]!.trim(),
                    })}
                  >
                    copy
                  </CopyableButton>
                </>
              )}
            </ToggleInput>

            <div className={styles.allowedDomainInput}>
              <ToggleInput
                name={ALLOWED_DOMAIN}
                value={form.values?.[ALLOWED_DOMAIN]!}
                initValue={allowedDomain || senderEmailDomain}
                toggleLabel="Email domain limiting"
                inputLabel="Your domain"
                hint="Restrict access to only emails in your organization."
                onChange={onChange}
                isRequired
                disabled={is2FAEnabled}
              >
                {({ isActive, isReadOnly, toggleInputStyles }) => (
                  <Input
                    value={form.values?.[ALLOWED_DOMAIN]}
                    name={ALLOWED_DOMAIN}
                    onChange={(e) => onChange?.(ALLOWED_DOMAIN, e.target.value)}
                    inputClassName={cn(toggleInputStyles.input, {
                      [toggleInputStyles.success]: form.values?.[ALLOWED_DOMAIN]!.trim() && !isReadOnly,
                      [toggleInputStyles.error]: !form.isValid,
                    })}
                    disabled={!isActive}
                    shouldTrimValue
                    readOnly={isReadOnly}
                  />
                )}
              </ToggleInput>
              {domainRestrictionLabel}
            </div>
            <div className={styles.twoFA}>
              <InputLabel
                value="Enable 2FA"
                className={styles.dateInputLabel}
                hint="Recipients will be asked to verify their phone number or email address with a code."
              />
              <div className={styles.twoFAInput}>
                <Toggle
                  className={styles.twoFAToggle}
                  onChange={() => {
                    setIs2FAEnabled((prev) => !prev);
                    dispatch(addBucketValue({ [ENABLED_2FA]: !is2FAEnabled }));
                    if (!is2FAEnabled && twoFASidebarRef.current) {
                      twoFASidebarRef.current.open();
                    }
                  }}
                  checked={is2FAEnabled}
                  name={ENABLED_2FA}
                />
                <TwoFASidebarToggle
                  ref={twoFASidebarRef}
                  count={recipients?.successfulRecipients?.length || 0}
                  disabled={!is2FAEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OneLinkShippingForm;
