import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as HiddenIcon } from '../../assets/images/icon-invisible.svg';
import { ReactComponent as PreorderIcon } from '../../assets/images/icon-preorder-allowed.svg';

import {
  CHANGE_VISIBILITY_WARNING,
  HIDE_INVENTORY_ITEM_CHECKBOX_HELP_TEXT,
  HIDE_INVENTORY_ITEM_CHECKBOX_SHORT_LABEL,
  HIDE_INVENTORY_ITEM_LAST_PHYSICAL_WARNING,
  QUANTITY,
} from '../../constants/campaigns';
import { IS_HIDDEN_FOR_RECIPIENT } from '../../constants/inventories';
import { IChangeBucketItemPayload } from '../../types/bucket';
import { ICampaignItem } from '../../types/campaigns';
import { IOnDemandItem } from '../../types/inventories';
import { BoxDetailsSidebarModeEnum, PositionStylesEnum } from '../../types/shell';
import {
  getMskuItemPrice,
  getSKUOptionsTotalCount,
  isCustomizableItem,
  isItemDigital,
  isItemMsku,
  isItemOutOfStock,
} from '../../utils/inventories';
import { Checkbox, QuantityInput, RedCrossButton } from '../forms';
import {
  Accordion,
  AccordionItem,
  FCDetails,
  HelpTooltip,
  InventoryItemOptions,
  ItemCountWithWarning,
  MskuNestedItem,
  Price,
} from '../index';

import styles from './BoxDetailsItem.module.scss';

interface IProps {
  item: ICampaignItem | IOnDemandItem;
  mode?: BoxDetailsSidebarModeEnum;
  onChange?: (itemData: IChangeBucketItemPayload) => void;
  onRemove?: (item: ICampaignItem | IOnDemandItem) => void;
  canHide?: boolean;
  canRemove?: boolean;
  isDirectSend?: boolean;
}

const BoxDetailsItem: React.FC<IProps> = ({ item, mode, onChange, onRemove, canHide = true, isDirectSend }) => {
  const {
    item_id,
    count,
    quantity,
    name,
    price,
    image_url,
    type,
    sku_options,
    is_hidden_for_recipient,
    size,
    color,
    flavor,
    gender,
    other_option,
    product_sku,
    fulfillment_center_id,
    preorder_allowed: isPreorderAllowed,
  } = React.useMemo(() => item, [item]);

  const isDigital = React.useMemo(() => isItemDigital({ type }), [type]);
  const isMsku = React.useMemo(() => isItemMsku({ type }), [type]);
  const isCustomizable = React.useMemo(() => isCustomizableItem(item), [item]);

  const hasAdditionalOptions = React.useMemo(
    () => flavor || color || size || gender || other_option || isCustomizable,
    [flavor, color, size, gender, other_option, isCustomizable],
  );

  const itemDisplayedPrice = React.useMemo(() => {
    if (isMsku && sku_options) {
      return getMskuItemPrice(sku_options);
    }

    return price;
  }, [isMsku, price, sku_options]);

  const isEditModeEnabled = React.useMemo(() => mode === BoxDetailsSidebarModeEnum.Edit, [mode]);

  const hideCheckboxMessage = React.useMemo(() => {
    return !canHide ? HIDE_INVENTORY_ITEM_LAST_PHYSICAL_WARNING : HIDE_INVENTORY_ITEM_CHECKBOX_HELP_TEXT;
  }, [canHide]);

  const handleQuantityChange = React.useCallback(
    (value: number | undefined = 0) => {
      onChange?.({
        id: item_id,
        name: QUANTITY,
        value,
      });
    },
    [item_id, onChange],
  );

  const handleHideItem = React.useCallback(() => {
    onChange?.({
      id: item_id,
      value: !is_hidden_for_recipient,
      name: IS_HIDDEN_FOR_RECIPIENT,
    });
  }, [onChange, item_id, is_hidden_for_recipient]);

  const handleRemoveItem = React.useCallback(() => {
    onRemove?.(item);
  }, [onRemove, item]);

  const icon = React.useMemo(() => {
    if (isDigital || isMsku) {
      return null;
    }

    return isPreorderAllowed ? (
      <PreorderIcon className={styles.preorderIcon} />
    ) : (
      <ItemCountWithWarning
        className={styles.countLeft}
        count={count}
        id={item_id}
        type={type}
        isPreorderAllowed={isPreorderAllowed}
      />
    );
  }, [isDigital, isMsku, isPreorderAllowed, count, item_id, type]);

  const isOutOfStock = React.useMemo(
    () => isItemOutOfStock({ count, preorder_allowed: isPreorderAllowed, type, sku_options }),
    [count, isPreorderAllowed, type, sku_options],
  );

  const maxItemsCount = React.useMemo(() => {
    if (isMsku) {
      return getSKUOptionsTotalCount(sku_options);
    }

    return count;
  }, [count, isMsku, sku_options]);

  const itemLayout = React.useMemo(
    () => (
      <div className={styles.itemLayoutContainer}>
        {icon}
        <div className={cn(styles.contentWrapper, { [styles.disabled]: isOutOfStock })}>
          <div className={styles.itemImageContainer}>
            {!isEditModeEnabled && (
              <div className={styles.itemCountContainer}>
                {quantity && <span className={styles.itemQuantity}>x{quantity}</span>}
              </div>
            )}
            <div className={styles.iconInner}>
              <div className={styles.icon} style={{ backgroundImage: `url("${image_url}")` }} />
            </div>
          </div>

          <div className={styles.itemDetailsContainer}>
            <div className={styles.itemOptionsHeading}>
              <Price value={itemDisplayedPrice} className={styles.itemPrice} />
              <div className={styles.nameGroup}>
                {isPreorderAllowed && !isMsku && <p className={styles.preorderTitle}>Preorder</p>}
                <h3 className={styles.itemName}>{name}</h3>
              </div>
            </div>
            {hasAdditionalOptions && (
              <InventoryItemOptions
                size={size}
                color={color}
                flavor={flavor}
                otherOption={other_option}
                gender={gender}
                isCustomizable={isCustomizable}
              />
            )}
          </div>
          {isEditModeEnabled ? (
            <div className={cn(styles.itemControls, isMsku ? styles.mskuItemControls : null)}>
              <QuantityInput
                className={styles.quantityInput}
                value={quantity}
                onChange={handleQuantityChange}
                maxValue={isPreorderAllowed ? undefined : maxItemsCount}
              >
                {!isPreorderAllowed ? (
                  <>
                    {quantity >= maxItemsCount! ? (
                      <p className={styles.maxQuantityError}>Max inventory reached</p>
                    ) : null}
                  </>
                ) : undefined}
              </QuantityInput>
              {!isMsku && !isDirectSend && (
                <div className={styles.hideCheckboxContainer}>
                  <Checkbox
                    checked={is_hidden_for_recipient}
                    onChange={handleHideItem}
                    className={cn({ [styles.disabled]: !canHide })}
                    disabled={!canHide}
                    name={IS_HIDDEN_FOR_RECIPIENT}
                    text={HIDE_INVENTORY_ITEM_CHECKBOX_SHORT_LABEL}
                  />
                  <HelpTooltip
                    className={styles.hideTooltipContainer}
                    contentClassName={styles.helpHideTooltipContent}
                    id={IS_HIDDEN_FOR_RECIPIENT + item_id}
                    place={PositionStylesEnum.Bottom}
                  >
                    {hideCheckboxMessage}
                  </HelpTooltip>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <FCDetails fulfillmentCenterId={fulfillment_center_id} productSku={product_sku} />
              {is_hidden_for_recipient && <HiddenIcon className={styles.hiddenIcon} />}
            </React.Fragment>
          )}
        </div>
      </div>
    ),
    [
      isMsku,
      item_id,
      isEditModeEnabled,
      quantity,
      image_url,
      itemDisplayedPrice,
      name,
      hasAdditionalOptions,
      size,
      color,
      flavor,
      other_option,
      gender,
      isCustomizable,
      handleQuantityChange,
      is_hidden_for_recipient,
      handleHideItem,
      canHide,
      hideCheckboxMessage,
      fulfillment_center_id,
      product_sku,
      isDirectSend,
      isPreorderAllowed,
      icon,
      isOutOfStock,
      maxItemsCount,
    ],
  );

  return (
    <div className={styles.itemContainer}>
      {isEditModeEnabled && (
        <>
          <RedCrossButton
            disabled={!onRemove}
            onClick={handleRemoveItem}
            className={cn(styles.deleteButton, !onRemove || !canHide ? styles.disabled : '')}
          />
          {!onRemove && (
            <HelpTooltip
              className={cn(styles.deleteButton)}
              contentClassName={styles.helpHideTooltipContent}
              id={`user-cant-delete-${item_id}`}
              place={PositionStylesEnum.Bottom}
            >
              {CHANGE_VISIBILITY_WARNING}
            </HelpTooltip>
          )}
        </>
      )}
      {isMsku ? (
        <Accordion className={styles.mskuAccordion}>
          <AccordionItem
            titleClassName={styles.campaignContainer}
            iconContainerClassName={styles.iconContainer}
            contentClassName={styles.multipleSkuContainer}
            id={`msku-item-${item_id}`}
            title={itemLayout}
            content={sku_options?.map((skuOption) => (
              <MskuNestedItem
                isHighlighted={skuOption.fulfillment_center_id !== fulfillment_center_id}
                item={skuOption}
                key={skuOption.item_id}
              />
            ))}
          />
        </Accordion>
      ) : (
        <React.Fragment>
          <div className={styles.campaignContainer}>{itemLayout}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default BoxDetailsItem;
