import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '../../assets/images/icon-delete-button.svg';
import EditIcon from '../../assets/images/icon-edit-button.svg';

import { DropdownWithContent, NoResultsPlaceholder, ReportsStatus } from '../../components';
import Card from '../../components/Card/Card';
import Filter from '../../components/Filter/Filter';
import { AddButton, TextIconButton } from '../../components/forms';
import { NO_DEPARTMENT } from '../../constants/organizations';
import { routes, URL_VARS } from '../../constants/routing';
import { DEPARTMENT_IDS, TEMPLATE_FILTERS, TEMPLATE_LABEL } from '../../constants/templates';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { addTemplateValue } from '../../store/actions/templates';
import { selectAdminType, selectUserDepartmentId } from '../../store/selectors/auth';
import { selectCurrentOrganizationDepartmentList } from '../../store/selectors/organizations';
import { selectAllTemplates } from '../../store/selectors/templates';
import {
  TCollectiveEmailTemplate,
  TCollectiveNoteTemplate,
  TCollectiveTemplateItem,
  TemplateFilterTypesEnum,
  TemplateTypesEnum,
} from '../../types/templates';
import { getEmailTemplateLabelByType, includesOnlyThisDep, isTemplateEmail, sortByDepId } from '../../utils/template';
import { getDepartmentLabelById, hasPermission } from '../../utils/users';

import styles from './TemplatesContainer.module.scss';

interface IProps {
  onDelete?: (template: TCollectiveTemplateItem) => any;
  onAdd?: (...args: any) => any;
}

const TemplatesContainer = ({ onDelete, onAdd }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mobile } = useWindowSize();
  const { setHeader, setAdditionalData } = useHeaderDispatch();
  const [tab, setTab] = React.useState(TemplateFilterTypesEnum.All);
  const adminType = useSelector(selectAdminType);
  const allTemplates = useSelector(selectAllTemplates);
  const userDepartmentId = useSelector(selectUserDepartmentId);
  const departments = useSelector(selectCurrentOrganizationDepartmentList);

  const { DeptFilter, filteredItems: filteredTemplates } = useDeptFilter<TCollectiveTemplateItem>(
    allTemplates || [],
    DEPARTMENT_IDS,
    {
      className: styles.dropdownFilter,
      contentClassName: styles.dropdownContent,
      labelClassName: styles.dropdownLabel,
    },
  );

  const handleEditTemplate = React.useCallback(
    (template: TCollectiveTemplateItem) => {
      const { item_id: id } = template;

      dispatch(addTemplateValue(template));
      history.push(
        routes.templates.getTemplateUrl({
          flowId: URL_VARS.EDIT,
          itemId: id,
        }),
      );
    },
    [dispatch, history],
  );

  const handleDeleteTemplate = React.useCallback(
    (template: TCollectiveTemplateItem) => {
      if (onDelete) {
        onDelete(template);
      }
    },
    [onDelete],
  );

  const getFilteredTemplatesByTabName = React.useCallback(
    (tabId: number) => {
      switch (tabId) {
        case TemplateFilterTypesEnum.Email:
          return filteredTemplates.filter((item) => item.templateType === TemplateTypesEnum.Email);
        case TemplateFilterTypesEnum.Note:
          return filteredTemplates.filter((item) => item.templateType === TemplateTypesEnum.Note);
        default:
          return filteredTemplates;
      }
    },
    [filteredTemplates],
  );

  const renderControls = React.useCallback(
    (template: TCollectiveTemplateItem) => {
      const { department_ids: depIds } = template;
      /* Dep. admin - are able to manage templates for their department only
       * Org, dist and super admins - are able to manage templates within an organization they’re in
       */
      return hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) ||
        (hasPermission([DEPT_ADMIN], adminType) && includesOnlyThisDep(depIds, userDepartmentId)) ? (
        <div className={styles.controls}>
          <TextIconButton
            trigger={mobile}
            className={styles.actionBtn}
            icon={EditIcon}
            title="Edit"
            onClick={() => handleEditTemplate(template)}
          />
          <TextIconButton
            trigger={mobile}
            className={cn(styles.actionBtn, styles.delete)}
            icon={DeleteIcon}
            title="Delete"
            onClick={() => handleDeleteTemplate(template)}
          />
        </div>
      ) : null;
    },
    [mobile, adminType, handleEditTemplate, handleDeleteTemplate, userDepartmentId],
  );

  const getDepartmentLabel = React.useCallback((id: string) => getDepartmentLabelById(id, departments), [departments]);

  const renderDefaultLabel = React.useCallback((isDefault: boolean) => {
    return isDefault ? <span className={styles.default}>Default</span> : null;
  }, []);

  const getDepartmentsInfo = React.useCallback(
    ({ department_ids: depIds, is_default = false }: TCollectiveTemplateItem) => {
      const mainDept = (() => {
        if (depIds.includes(userDepartmentId)) {
          return userDepartmentId;
        }

        if (depIds.includes(NO_DEPARTMENT)) {
          return NO_DEPARTMENT;
        }

        return depIds[0];
      })();

      const additionalDeptIds = depIds.filter((id) => id !== mainDept);

      return (
        <div className={styles.deptInfo}>
          <span className={styles.mainDept}>
            {mainDept === NO_DEPARTMENT ? 'Global' : getDepartmentLabel(mainDept)}
          </span>
          {depIds.length > 1 && (
            <DropdownWithContent
              label={() => (
                <span className={styles.badge}>
                  {`+${additionalDeptIds.length} team${additionalDeptIds.length > 1 ? 's' : ''}`}
                </span>
              )}
              className={styles.additionalDept}
              contentClassName={styles.additionalDepartsList}
            >
              {() => (
                <React.Fragment>
                  {additionalDeptIds.map((id) => (
                    <div key={id}>{getDepartmentLabel(id)}</div>
                  ))}
                </React.Fragment>
              )}
            </DropdownWithContent>
          )}
          {!mobile && renderDefaultLabel(is_default)}
        </div>
      );
    },
    [mobile, getDepartmentLabel, renderDefaultLabel, userDepartmentId],
  );

  const filteredTemplatesForCurrentTab = React.useMemo(
    () => getFilteredTemplatesByTabName(tab).sort(sortByDepId(userDepartmentId)),
    [getFilteredTemplatesByTabName, tab, userDepartmentId],
  );

  const filterConfig = React.useMemo(() => {
    return TEMPLATE_FILTERS.map(({ tabId, label: optionLabel }) => {
      return {
        label: `${optionLabel} (${getFilteredTemplatesByTabName(tabId)?.length || 0})`,
        key: optionLabel,
        isActive: tabId === tab,
        onClick: () => setTab(tabId),
      };
    });
  }, [tab, getFilteredTemplatesByTabName]);

  const renderItems = React.useMemo(() => {
    return (
      <div className={styles.list}>
        {filteredTemplatesForCurrentTab && filteredTemplatesForCurrentTab.length ? (
          filteredTemplatesForCurrentTab.map((template) => {
            const { item_id: id, templateType, is_default = false } = template;
            return (
              <Card
                className={styles.card}
                key={`${id}-${templateType}`}
                top={
                  <React.Fragment>
                    <div className={styles.templatInfo}>
                      <span className={styles.title}>
                        {(template as TCollectiveNoteTemplate).title || (template as TCollectiveEmailTemplate).subject}
                      </span>
                      <span className={styles.type}>{templateType}</span>
                      {isTemplateEmail(templateType) && (
                        <ReportsStatus
                          className={styles.emailType}
                          status={getEmailTemplateLabelByType((template as TCollectiveEmailTemplate).type)}
                          backgroundColor={'#FBAB1C'}
                        />
                      )}
                    </div>
                    {getDepartmentsInfo(template)}
                  </React.Fragment>
                }
                bottom={
                  <React.Fragment>
                    {mobile && renderDefaultLabel(is_default)}
                    {renderControls(template)}
                  </React.Fragment>
                }
                topClassName={styles.top}
                middleClassName={styles.middle}
                bottomClassName={styles.controlsWrapper}
              />
            );
          })
        ) : (
          <NoResultsPlaceholder />
        )}
      </div>
    );
  }, [filteredTemplatesForCurrentTab, renderControls, mobile, getDepartmentsInfo, renderDefaultLabel]);

  const renderAddButton = React.useMemo(
    () =>
      hasPermission([DEPT_ADMIN, ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) && (
        <AddButton assetName={TEMPLATE_LABEL.toLowerCase()} className={styles.addButton} onClick={onAdd} />
      ),
    [onAdd, adminType],
  );

  React.useEffect(() => {
    if (typeof setAdditionalData !== 'function') {
      return;
    }

    setAdditionalData(mobile ? renderAddButton : null);

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [mobile, setAdditionalData, renderAddButton]);

  React.useEffect(() => {
    if (typeof setHeader === 'function') {
      setHeader({ title: 'Templates', action: () => history.push(routes.dashboard) });
    }
  }, [history]);

  return (
    <div className={cn(styles.container)}>
      <div className={styles.header}>
        <Filter config={filterConfig} className={styles.filter} />
        {/* Dept Filter has to have object */}
        {/* TODO: Change this in the context of EX-1244 task */}
        {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) ? DeptFilter({}) : null}
        {!mobile && renderAddButton}
      </div>
      <div className={styles.listWrapper}>{renderItems}</div>
    </div>
  );
};

export default TemplatesContainer;
