import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { ReactComponent as IconUser } from '../../../assets/images/icon-user.svg';
import { ActionButton, HiddenInput, Input, RadioButtonGroup, Textarea, Toggle } from '../../../components';
import {
  AWAITING_ADDRESS,
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIPPING_OPTION,
  SHIPPING_OPTIONS,
  SHIP_ORDER_STATUS,
} from '../../../constants/shipping';
import { MAX_BULK_NOTE_LENGTH, MAX_NOTE_LENGTH, MAX_ONE_LINK_NOTE_LENGTH, MESSAGE } from '../../../constants/templates';
import { IMaintenanceFormOutputData } from '../../../types/forms';
import { ISendDetailsFormValues } from '../../../types/shipping';

import styles from './SendDetailsForm.module.scss';

export interface ISendSummaryFormProps extends IMaintenanceFormOutputData {
  onChange: (name: keyof ISendDetailsFormValues, value: string | number | undefined | string[] | boolean) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  form: FormikProps<Partial<ISendDetailsFormValues>>;
  requiredFields: Record<string, boolean>;
  disabled?: boolean;
  className?: string;
  isOneLinkEngagement?: boolean;
  isBulkEngagement?: boolean;
  isEditingShipmentOptionsAllowed?: boolean;
  isNoteHidden?: boolean;
  isDirectSend?: boolean;
}

interface IFormSectionTitleProps {
  title: string;
  titleClassName?: string;
}

interface IFormRowProps {
  children: React.ReactElement[] | React.ReactElement | string;
  rowClassName?: string;
}

const SendDetailsForm: React.FC<ISendSummaryFormProps> = ({
  onChange,
  onCancel,
  onSubmit,
  form,
  disabled,
  requiredFields,
  className,
  isOneLinkEngagement,
  isBulkEngagement,
  isEditingShipmentOptionsAllowed,
  isNoteHidden,
  isDirectSend,
}) => {
  const FormSectionTitle = React.useMemo(
    () =>
      ({ title, titleClassName }: IFormSectionTitleProps) => {
        return <div className={cn(styles.sectionTitle, titleClassName)}>{title}</div>;
      },
    [],
  );

  const FormInputRow = React.useMemo(
    () =>
      ({ children, rowClassName }: IFormRowProps) => {
        return <div className={cn(styles.row, rowClassName)}>{children}</div>;
      },
    [],
  );

  const maxChars = React.useMemo(() => {
    switch (true) {
      case isBulkEngagement:
        return MAX_BULK_NOTE_LENGTH;
      case isOneLinkEngagement:
        return MAX_ONE_LINK_NOTE_LENGTH;

      default:
        return MAX_NOTE_LENGTH;
    }
  }, [isBulkEngagement, isOneLinkEngagement]);

  const getFieldErrors = React.useCallback(
    (fieldName: keyof ISendDetailsFormValues) => {
      return form.errors[fieldName];
    },
    [form.errors],
  );

  const handleFormFieldChange = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
      name: keyof ISendDetailsFormValues,
    ) => {
      onChange(name, e.target.value);
    },
    [onChange],
  );

  /*const handleCheckboxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, name: keyof ISendDetailsFormValues) => {
      onChange(name, e.target.checked);
    },
    [onChange, onChange],
  );*/

  return (
    <form onSubmit={form.handleSubmit} className={cn(styles.shippingForm, className)}>
      <FormSectionTitle title="Recipient's Details" />
      <FormInputRow>
        <Input
          helperText="First name"
          name={RECEIVER_FIRST_NAME}
          inputClassName={styles.inputField}
          placeholder="First name"
          disabled={disabled}
          required={requiredFields[RECEIVER_FIRST_NAME]}
          icon={<IconUser />}
          value={form.values[RECEIVER_FIRST_NAME]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_FIRST_NAME)}
          error={getFieldErrors(RECEIVER_FIRST_NAME)}
        />
        <Input
          helperText="Last name"
          name={RECEIVER_LAST_NAME}
          inputClassName={styles.inputField}
          placeholder="Last name"
          disabled={disabled}
          required={requiredFields[RECEIVER_LAST_NAME]}
          icon={<IconUser />}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_LAST_NAME)}
          value={form.values[RECEIVER_LAST_NAME]}
          error={getFieldErrors(RECEIVER_LAST_NAME)}
        />
      </FormInputRow>

      <FormInputRow>
        <Input
          helperText="Company"
          name={RECEIVER_COMPANY_NAME}
          inputClassName={styles.inputField}
          placeholder="Company"
          disabled={disabled}
          required={requiredFields[RECEIVER_COMPANY_NAME]}
          value={form.values[RECEIVER_COMPANY_NAME]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_COMPANY_NAME)}
          error={getFieldErrors(RECEIVER_COMPANY_NAME)}
        />
        <Input
          helperText="Phone"
          name={RECEIVER_PHONE}
          inputClassName={styles.inputField}
          placeholder="Phone"
          disabled={disabled}
          required={requiredFields[RECEIVER_PHONE]}
          value={form.values[RECEIVER_PHONE]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_PHONE)}
          error={getFieldErrors(RECEIVER_PHONE)}
        />
      </FormInputRow>

      <FormInputRow>
        <Input
          helperText="Address 1"
          name={RECEIVER_ADDRESS1}
          inputClassName={styles.inputField}
          placeholder="Address 1"
          disabled={disabled}
          required={requiredFields[RECEIVER_ADDRESS1]}
          value={form.values[RECEIVER_ADDRESS1]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_ADDRESS1)}
          error={getFieldErrors(RECEIVER_ADDRESS1)}
        />
      </FormInputRow>

      <FormInputRow>
        <Input
          helperText="Address 2"
          name={RECEIVER_ADDRESS2}
          inputClassName={styles.inputField}
          placeholder="Address 2"
          disabled={disabled}
          required={requiredFields[RECEIVER_ADDRESS2]}
          value={form.values[RECEIVER_ADDRESS2]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_ADDRESS2)}
          error={getFieldErrors(RECEIVER_ADDRESS2)}
        />
        <Input
          helperText="State"
          name={RECEIVER_STATE}
          inputClassName={styles.inputField}
          placeholder="State"
          disabled={disabled}
          required={requiredFields[RECEIVER_STATE]}
          value={form.values[RECEIVER_STATE]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_STATE)}
          error={getFieldErrors(RECEIVER_STATE)}
        />
      </FormInputRow>

      <FormInputRow>
        <Input
          helperText="Country"
          name={RECEIVER_COUNTRY}
          inputClassName={styles.inputField}
          placeholder="Country"
          disabled={disabled}
          required={requiredFields[RECEIVER_COUNTRY]}
          value={form.values[RECEIVER_COUNTRY]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_COUNTRY)}
          error={getFieldErrors(RECEIVER_COUNTRY)}
        />
        <Input
          helperText="ZIP Code"
          name={RECEIVER_ZIP}
          inputClassName={styles.inputField}
          placeholder="ZIP Code"
          disabled={disabled}
          required={requiredFields[RECEIVER_ZIP]}
          value={form.values[RECEIVER_ZIP]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_ZIP)}
          error={getFieldErrors(RECEIVER_ZIP)}
        />
      </FormInputRow>

      <FormInputRow>
        <Input
          helperText="City"
          name={RECEIVER_CITY}
          inputClassName={styles.inputField}
          placeholder="City"
          disabled={disabled}
          required={requiredFields[RECEIVER_CITY]}
          value={form.values[RECEIVER_CITY]}
          onChange={(e) => handleFormFieldChange(e, RECEIVER_CITY)}
          error={getFieldErrors(RECEIVER_CITY)}
        />
        <Toggle
          name={SHIP_ORDER_STATUS}
          className={cn(styles.toggle)}
          sliderClassName={styles.toggleSlider}
          infoClassName={styles.toggleText}
          disabled
          defaultValue={AWAITING_ADDRESS}
          value={form.values[SHIP_ORDER_STATUS]}
        >
          Delayed Shipping
        </Toggle>
      </FormInputRow>

      {isEditingShipmentOptionsAllowed ? (
        <React.Fragment>
          <FormSectionTitle title="Delivery Options" titleClassName={styles.secondaryTitle} />
          <FormInputRow rowClassName={styles.shippingOptionsRow}>
            <div className={cn(styles.optionsList, styles.inputField)}>
              <RadioButtonGroup
                containerClassName={styles.deliveryOptionsGroup}
                options={SHIPPING_OPTIONS}
                value={form.values[SHIPPING_OPTION]}
                disabled={disabled}
                onChange={(e) => handleFormFieldChange(e, SHIPPING_OPTION)}
                name={SHIPPING_OPTION}
              />
            </div>
          </FormInputRow>
        </React.Fragment>
      ) : (
        <HiddenInput name={SHIPPING_OPTION} value={form.values[SHIPPING_OPTION]} />
      )}
      {!isDirectSend && !isNoteHidden && (
        <>
          <FormSectionTitle title="Custom Message" titleClassName={styles.secondaryTitle} />
          <FormInputRow>
            <Textarea
              name={MESSAGE}
              value={form.values[MESSAGE]}
              onChange={(e) => handleFormFieldChange(e, MESSAGE)}
              className={styles.inputField}
              inputClassName={styles.noteTextArea}
              placeholder="Message"
              showCounter={true}
              maxCharsCount={maxChars}
            />
          </FormInputRow>
        </>
      )}
      <FormInputRow rowClassName={styles.controlsContainer}>
        {/*TODO: To be done in ticket related to notifications
        <Checkbox
          text="Notify Sender"
          onChange={(e) => handleCheckboxChange(e, DISABLE_SENDER_EMAIL_NOTIFICATIONS)}
          checked={form.values[DISABLE_SENDER_EMAIL_NOTIFICATIONS]}
        />*/}
        <ActionButton className={styles.cancelButton} title="Cancel" onClick={onCancel} />
        <ActionButton
          type="submit"
          className={styles.continueButton}
          disabled={!form.dirty || !!Object.keys(form.errors).length}
          title="Save changes"
          onClick={onSubmit}
        />
      </FormInputRow>
    </form>
  );
};

export default SendDetailsForm;
