import cn from 'classnames';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BoxItem, NoResultsPlaceholder, PaginatedList } from '../../components';
import { ActionButton, AddButton } from '../../components/forms';
import { IPaginatedListChangeEvent, IPaginatedListRef } from '../../components/PaginatedList/PaginatedList';
import {
  CAMPAIGN_LABEL,
  DEPARTMENT_IDS,
  INITIAL_CAMPAIGN_FORM_STATE,
  MAX_CAMPAIGN_ITEMS_PER_PAGE,
} from '../../constants/campaigns';
import { routes, URL_VARS } from '../../constants/routing';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { addCampaignValue, fetchCampaignsRequest } from '../../store/actions/campaigns';
import { selectAdminType } from '../../store/selectors/auth';
import {
  selectCampaignsByType,
  selectCampaignsCount,
  selectCanEditCampaigns,
  selectIsFinalCampaign,
} from '../../store/selectors/campaigns';
import { ICampaign, IFetchCampaignsRequest } from '../../types/campaigns';
import { IDeleteModalProps } from '../../types/modals';
import { hasPermission } from '../../utils/users';
import BoxDetailsSidebar from '../BoxDetailsSidebar/BoxDetailsSidebar';

import styles from './CampaignsContainer.module.scss';

interface IProps {
  openDeleteModal?: (prop: IDeleteModalProps) => any;
}

const CampaignsContainer = ({ openDeleteModal }: IProps) => {
  const { mobile } = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setHeader, setAdditionalData } = useHeaderDispatch();

  const canEditCampaigns = useSelector(selectCanEditCampaigns);
  const campaignItems = useSelector(selectCampaignsByType());
  const campaignsCount = useSelector(selectCampaignsCount);
  const isFinalCampaign = useSelector(selectIsFinalCampaign);
  const adminType = useSelector(selectAdminType);

  const [selectedBox, setSelectedBox] = useState<ICampaign | undefined>();

  const paginatedListRef = React.useRef<IPaginatedListRef<ICampaign>>(null);

  const { SearchInput, query } = useSearchFilter([], [], {
    className: styles.searchByFilterContainer,
    placeholder: 'Lookup campaigns',
    hint: 'Look up specific campaigns by their name.',
  });

  const {
    departments: selectedDepartment,
    DeptFilter,
    filteredItems,
  } = useDeptFilter<ICampaign>((campaignItems as ICampaign[]) || [], DEPARTMENT_IDS, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  const getRequestInfo = useCallback(
    (payload: IFetchCampaignsRequest) => {
      return {
        ...(selectedDepartment && { department_id: selectedDepartment as string }),
        ...(query && { search_query: query }),
        ...payload,
      };
    },
    [selectedDepartment, query],
  );

  const fetchCampaigns = useCallback(
    (payload: IFetchCampaignsRequest) => {
      dispatch(fetchCampaignsRequest(getRequestInfo(payload)));
    },
    [dispatch, getRequestInfo],
  );

  const handlePaginationChange = useCallback(
    ({ page, setPage }: IPaginatedListChangeEvent) => {
      fetchCampaigns({ page, page_size: MAX_CAMPAIGN_ITEMS_PER_PAGE });
      // In case there's an error on the endpoint, paginator will change the page number, which is not correct.
      // The fix is to wrap the request with the Promise and call the `setPage(page)` after the Promise is resolved
      setPage(page);
    },
    [fetchCampaigns],
  );

  const handleCreateCampaign = useCallback(() => {
    dispatch(addCampaignValue(INITIAL_CAMPAIGN_FORM_STATE));
    history.push(
      routes.campaigns.getCampaignUrl({
        flowId: URL_VARS.NEW,
        stepId: URL_VARS.DETAILS,
      }),
    );
  }, [dispatch, history]);

  const handleEditCampaign = useCallback(
    (box: ICampaign) => {
      dispatch(addCampaignValue(box));
      history.push(
        routes.campaigns.getCampaignUrl({
          flowId: URL_VARS.EDIT,
          stepId: URL_VARS.DETAILS,
          itemId: box.box_id,
        }),
      );
    },
    [dispatch, history],
  );

  const handleCloseSidebar = React.useCallback(() => {
    setSelectedBox(undefined);
  }, []);

  const handleDeleteCampaign = useCallback(
    (id: string) => {
      if (openDeleteModal && id) {
        openDeleteModal({
          id,
          cb: () => {
            handleCloseSidebar();
          },
        });
      }
    },
    [openDeleteModal, handleCloseSidebar],
  );

  const handleBoxClick = useCallback((box: ICampaign) => {
    setSelectedBox(box);
  }, []);

  const actionButtons = React.useMemo(
    () => [
      <ActionButton
        key="editButton"
        className={styles.editActionButton}
        title="Edit campaign"
        onClick={() => selectedBox && handleEditCampaign(selectedBox) && handleCloseSidebar}
      />,
      <ActionButton
        key="deleteButton"
        className={styles.deleteActionButton}
        title="Delete campaign"
        onClick={() => selectedBox && handleDeleteCampaign(selectedBox?.box_id)}
      />,
    ],
    [handleEditCampaign, handleDeleteCampaign, selectedBox, handleCloseSidebar],
  );

  const getBoxItem = useCallback(
    (box: ICampaign) => {
      return (
        <div key={box.box_id} className={styles.item}>
          <BoxItem
            key={box.box_id}
            box={box}
            withWarning
            className={cn(styles.itemWrapper, styles.wide)}
            iconClassName={styles.icon}
            onClick={() => handleBoxClick(box)}
            {...(canEditCampaigns
              ? {
                  onDelete: () => handleDeleteCampaign(box.box_id),
                  onEdit: () => handleEditCampaign(box),
                }
              : null)}
          />
        </div>
      );
    },
    [canEditCampaigns, handleBoxClick, handleDeleteCampaign, handleEditCampaign],
  );

  const renderControls = useMemo(
    () => (
      <div className={styles.controls}>
        {canEditCampaigns ? (
          <AddButton
            assetName={CAMPAIGN_LABEL.toLowerCase()}
            className={styles.addButton}
            onClick={handleCreateCampaign}
          />
        ) : null}
      </div>
    ),
    [canEditCampaigns, handleCreateCampaign],
  );

  useEffect(() => {
    fetchCampaigns({ page: 1, page_size: MAX_CAMPAIGN_ITEMS_PER_PAGE });

    paginatedListRef.current?.resetPageCount();
  }, [fetchCampaigns]);

  useEffect(() => {
    if (!setAdditionalData) {
      return;
    }

    setAdditionalData(mobile ? renderControls : null);

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [mobile, renderControls, setAdditionalData]);

  useEffect(() => {
    if (setHeader) {
      setHeader({ title: 'Campaigns List', action: () => history.push(routes.dashboard) });
    }
  }, [history, setHeader]);

  return (
    <div className={cn(styles.container)}>
      <div className={styles.header}>
        <div className={styles.filter}>
          {SearchInput}
          {/* Dept Filter has to have object */}
          {/* TODO: Change this in the context of EX-1244 task */}
          {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) ? DeptFilter({}) : null}
        </div>
        {!mobile && renderControls}
      </div>
      {filteredItems && filteredItems.length ? (
        <PaginatedList<ICampaign>
          totalCount={campaignsCount}
          ref={paginatedListRef}
          items={filteredItems || null}
          pageSize={MAX_CAMPAIGN_ITEMS_PER_PAGE}
          onChange={handlePaginationChange}
          isFinalItems={isFinalCampaign}
        >
          {({ items, pagination: Pagination }) => (
            <React.Fragment>
              <div className={styles.list}>{items?.map(getBoxItem)}</div>
              <Pagination className={styles.pagination} />
            </React.Fragment>
          )}
        </PaginatedList>
      ) : (
        <NoResultsPlaceholder label="No campaigns yet" className={styles.noItems} labelClassName={styles.label} />
      )}
      <BoxDetailsSidebar
        onClose={handleCloseSidebar}
        box={selectedBox}
        actionButtons={canEditCampaigns ? actionButtons : undefined}
      />
    </div>
  );
};

export default CampaignsContainer;
