import { createSelector } from 'reselect';

import { BLUEBIRD, NONE } from '../../constants/integrations';
import {
  AI_GIFT_SUGGESTION_ENABLED,
  CRM_SKIP_ALLOWED,
  DEPARTMENT_BUDGET_ENABLED,
  DIGITAL_BALANCE_LIMIT_ENABLED,
} from '../../constants/organizations';
import { DIGITAL_BUDGET_WARNING_RATE } from '../../constants/shell';
import { ISimplifiedOrganization } from '../../types/organizations';
import { IAppState } from '../reducers';
import { selectFirebaseClaims, selectIsDistributor, selectIsSuperAdmin, selectOrganizationInfo } from './auth';

export const selectRootState = (state: IAppState) => state.organizations;

export const selectCanEditOrganization = createSelector(
  selectIsSuperAdmin,
  selectIsDistributor,
  (isSuper, isDistributor) => isSuper || isDistributor,
);

export const selectOrganizations = createSelector(selectRootState, (organizations) =>
  organizations && organizations.items ? organizations.items : undefined,
);

export const selectOrganizationById = (id?: string) =>
  createSelector(selectOrganizations, (orgs) =>
    id && orgs && orgs.length ? orgs.find((org) => org.uid === id) : undefined,
  );

export const selectOrganizationDetails = createSelector(selectRootState, (organizations) =>
  organizations ? organizations.organizationDetails : undefined,
);

export const selectOrganizationsIsLoading = createSelector(selectRootState, (organizations) =>
  organizations ? organizations.isLoading : false,
);

export const selectOrganizationsDetailsIsLoading = createSelector(selectRootState, (organizations) =>
  organizations ? organizations.isOrganizationDetailsLoading : false,
);

//#region Current organization data

export const selectCurrentOrganizationId = createSelector(selectFirebaseClaims, (claims) =>
  claims ? (claims.org_id as string) : '',
);

export const selectCurrentOrganization = createSelector(
  selectOrganizations,
  selectCurrentOrganizationId,
  (organizations, orgId) => {
    return organizations?.find((organization) => organization.uid === orgId);
  },
);

export const selectCurrentOrganizationData = createSelector(
  selectRootState,
  (organizations) => organizations.current || undefined,
);

export const selectCurrentDepartmentBudgetData = createSelector(selectCurrentOrganizationData, (orgData) =>
  orgData ? orgData.departmentBudget : null,
);

export const selectIsDepartmentBudgetUnlimited = createSelector(
  selectCurrentDepartmentBudgetData,
  (budget) => budget?.available_budget === 0,
);

export const selectCurrentDepartmentRemainingBudget = createSelector(selectCurrentDepartmentBudgetData, (budget) => {
  if (!budget) {
    return 0;
  }

  const { available_budget, used_budget } = budget;

  return available_budget - used_budget;
});

export const selectCurrentOrganizationDepartmentList = createSelector(
  selectCurrentOrganizationData,
  ({ departmentList }) => departmentList || undefined,
);

export const selectCurrentOrganizationOptions = createSelector(
  selectCurrentOrganizationData,
  ({ options }) => options || undefined,
);

export const selectIsDigitalBalanceLimitEnabled = createSelector(
  selectCurrentOrganizationOptions,
  (options) => options?.[DIGITAL_BALANCE_LIMIT_ENABLED] || false,
);

export const selectCurrentOrganizationDigitalBalanceLimit = createSelector(
  selectCurrentOrganizationOptions,
  (options) => options?.digital_balance_limit_value || DIGITAL_BUDGET_WARNING_RATE,
);

export const selectCurrentOrganizationCRMType = createSelector(selectCurrentOrganizationOptions, (options) =>
  options ? options.crm_currently_supports : undefined,
);

export const selectIsCurrentOrganizationSupportedAnyCrm = createSelector(selectCurrentOrganizationCRMType, (crm) =>
  typeof crm !== 'undefined' ? crm !== NONE : undefined,
);

export const selectCurrentOrganizationEmailType = createSelector(selectCurrentOrganizationOptions, (options) =>
  options ? options.email_currently_supports : undefined,
);

export const selectIsCurrentOrganizationSupportedAnyEmail = createSelector(
  selectCurrentOrganizationEmailType,
  (email) => (typeof email !== 'undefined' ? email !== BLUEBIRD : undefined),
);

export const selectIsSOBAvailableInCurrentOrganization = createSelector(selectCurrentOrganizationOptions, (options) =>
  Boolean(options?.send_on_behalf_of_enabled),
);

export const selectIsLoadingDepartmentSummaries = createSelector(
  selectCurrentOrganizationData,
  (organizationData) => organizationData.isDepartmentSummariesLoading,
);

export const selectIsRemovePIILoading = createSelector(
  selectRootState,
  (organizations) => organizations.isOrganizationsPIILoading,
);

export const selectIsUpdatingDepartment = createSelector(
  selectCurrentOrganizationData,
  (organizationData) => organizationData.isDepartmentUpdating,
);

export const selectIsDepartmentBudgetEnabled = createSelector(
  selectCurrentOrganizationData,
  (orgData) => orgData?.options?.[DEPARTMENT_BUDGET_ENABLED],
);

export const selectIsAIGiftSuggestionEnabled = createSelector(
  selectCurrentOrganizationOptions,
  (options) => options?.[AI_GIFT_SUGGESTION_ENABLED],
);

export const selectIsSkipCrmAllowedInOrg = createSelector(
  selectCurrentOrganizationData,
  (orgData) => orgData?.options?.[CRM_SKIP_ALLOWED],
);

export const selectSimplifiedCurrentOrganization = createSelector(
  selectOrganizationInfo,
  selectCurrentOrganizationDepartmentList,
  selectCurrentOrganizationOptions,
  // tslint:disable-next-line:variable-name
  (organizationInfo, org_departments, org_options): ISimplifiedOrganization | undefined => {
    if (!organizationInfo || !org_departments || !org_options) {
      return void 0;
    }

    return {
      ...organizationInfo,
      org_departments,
      org_options,
    };
  },
);

//#endregion
