import cn from 'classnames';
import * as React from 'react';

import {
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
} from '../../../constants/shipping';
import { TEngagementCandidate } from '../../../types/bucket';
import { IReport } from '../../../types/reports';
import { LabelWithEditButton } from '../../forms/labels';

import styles from './RecipientDetails.module.scss';

interface IProps {
  className?: string;
  instance: IReport | TEngagementCandidate;
  isDigital: boolean;
  isEditable?: boolean;
  onEdit?: () => void;
}

const RecipientDetails = ({ className, instance, isDigital, isEditable, onEdit }: IProps) => (
  <div className={cn(styles.container, className)}>
    <LabelWithEditButton
      isEditable={isEditable}
      text="Recipient’s Details"
      onEdit={onEdit}
      className={styles.labelContainer}
    />
    <div className={styles.content}>
      <span>{`${instance[RECEIVER_FIRST_NAME]} ${instance[RECEIVER_LAST_NAME]}`}</span>
      {!isDigital && (
        <React.Fragment>
          <span>{instance[RECEIVER_COMPANY_NAME] || ''}</span>
          <span>
            {`${instance[RECEIVER_ADDRESS1] ? `${instance[RECEIVER_ADDRESS1]}, ` : ''}`}
            {`${instance[RECEIVER_ADDRESS2] ? `${instance[RECEIVER_ADDRESS2]}, ` : ''}`}
          </span>
          <span>
            {`${instance[RECEIVER_CITY] ? `${instance[RECEIVER_CITY]}, ` : ''}`}
            {`${instance[RECEIVER_STATE] ? `${instance[RECEIVER_STATE]}, ` : ''}`}
            {`${instance[RECEIVER_COUNTRY] ? `${instance[RECEIVER_COUNTRY]}` : ''}`}
          </span>
          <span>{instance[RECEIVER_ZIP]}</span>
          <span>{instance[RECEIVER_PHONE] && <span>{instance[RECEIVER_PHONE]}</span>}</span>
        </React.Fragment>
      )}
    </div>
  </div>
);

export default RecipientDetails;
