import cn from 'classnames';
import * as React from 'react';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

import styles from './Price.module.scss';

interface IProps {
  className?: string;
  hint?: string;
  value?: number | string | null;
  prefix?: string;
  thousandSeparator?: boolean | string;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  hyphenOnZero?: boolean;
}

const Price: FC<IProps> = ({
  value = 0,
  prefix = '$',
  className,
  hint = '',
  thousandSeparator,
  fixedDecimalScale = true,
  decimalScale = 2,
  hyphenOnZero,
}: IProps) => (
  <div title={hint} className={cn(styles.price, className)}>
    {hyphenOnZero && !value ? (
      <span className={styles.amount}>{prefix}-.--</span>
    ) : (
      <NumericFormat
        className={styles.amount}
        value={value}
        displayType="text"
        prefix={prefix}
        thousandSeparator={thousandSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
      />
    )}
  </div>
);

export default Price;
