import cn from 'classnames';
import * as React from 'react';
import { RequireAtLeastOne } from 'type-fest';

import Avatar from '../Avatar/Avatar';
import InitialLetterAvatar from '../InitialLetterAvatar/InitialLetterAvatar';
import styles from './UserInformation.module.scss';

interface IProps {
  wrapperClassName?: string;
  avatarClassName?: string;
  name: string;
  initialLetter: string;
  avatarImageURL?: string;
  withBorder?: boolean;
}

const UserInformation = ({
  wrapperClassName,
  avatarClassName,
  avatarImageURL,
  name,
  initialLetter,
  withBorder = true,
}: RequireAtLeastOne<IProps, 'initialLetter' | 'name'>) => {
  const initialLetterValue = React.useMemo(() => {
    if (name) {
      return name[0];
    }

    return initialLetter || '';
  }, [name, initialLetter]);

  return (
    <div className={cn(styles.userInformation, wrapperClassName)}>
      {avatarImageURL ? (
        <Avatar image={avatarImageURL} className={cn(avatarClassName, { [styles.border]: withBorder })} />
      ) : (
        <InitialLetterAvatar
          letter={initialLetterValue}
          className={cn(avatarClassName, { [styles.border]: withBorder })}
        />
      )}
      {name && <span className={cn(styles.username)}>{name}</span>}
    </div>
  );
};

export default UserInformation;
