import * as React from 'react';

import { BoxDetailsItem, Price } from '../../../components';
import { IChangeBucketItemPayload } from '../../../types/bucket';
import { ICampaignItem } from '../../../types/campaigns';
import { IOnDemandItem } from '../../../types/inventories';
import { IReportEngagementItem } from '../../../types/reports';
import { BoxDetailsSidebarModeEnum } from '../../../types/shell';
import { getBoxTotal, getCanRemoveItemFromCampaign, getIsItemLastVisibleInCampaign } from '../../../utils/campaigns';

import styles from './SendItemsForm.module.scss';

interface IProps {
  mode?: BoxDetailsSidebarModeEnum;
  items?: (ICampaignItem | IOnDemandItem | IReportEngagementItem)[] | null | undefined;
  onChange?: (itemData: IChangeBucketItemPayload) => void;
  onRemove?: (item: ICampaignItem | IOnDemandItem) => void;
  children?: React.ReactElement | React.ReactElement[];
  canRemoveAll?: boolean;
  isDirectSend?: boolean;
}

const SendItemsForm = ({ children, items, onChange, onRemove, mode, canRemoveAll = true, isDirectSend }: IProps) => {
  const boxDisplayedPrice = React.useMemo(() => getBoxTotal({ items }), [items]);

  return (
    <div className={styles.form}>
      {items?.map((item) => {
        const isLastVisibleItem = getIsItemLastVisibleInCampaign(items, item.item_id);
        const isCustomizableItem = item.item_customization_allowed;
        const canRemoveItem = canRemoveAll
          ? getCanRemoveItemFromCampaign(items, item.item_id)
          : items?.length > 1 && getCanRemoveItemFromCampaign(items, item.item_id);

        return (
          <BoxDetailsItem
            item={item}
            canHide={!isLastVisibleItem && !isCustomizableItem}
            key={item.item_id}
            mode={mode}
            onChange={onChange}
            onRemove={canRemoveItem ? onRemove : undefined}
            isDirectSend={isDirectSend}
          />
        );
      })}
      <div className={styles.price}>
        Total price <Price value={boxDisplayedPrice} className={styles.value} />
      </div>
      {children}
    </div>
  );
};

export default SendItemsForm;
