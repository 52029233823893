import cn from 'classnames';
import * as React from 'react';
import { animated } from 'react-spring';

import ScaleInOut from '../../animations/ScaleInOut';
import { ICampaignItem } from '../../types/campaigns';
import { IInventoryItem } from '../../types/inventories';
import { IReportEngagementItem } from '../../types/reports';
import { getMskuItemPrice, isItemMsku } from '../../utils/inventories';
import { ItemMetadata, Price } from '../index';

import styles from './InventoryPickerItem.module.scss';

export interface IInventoryPickerItemProps {
  item: IInventoryItem;
  onClick: (payload: ICampaignItem | IReportEngagementItem) => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  className?: string;
  iconClassName?: string;
  hint?: string;
}

const InventoryPickerItem: React.FC<IInventoryPickerItemProps> = ({
  item,
  className,
  iconClassName,
  isSelected,
  isDisabled,
  onClick,
  hint,
}) => {
  const {
    name,
    price,
    image_url: imageUrl,
    count,
    sku_options: skuOptions,
    item_id: itemId,
    item_customization_allowed: isCustomizable,
    type,
    preorder_allowed: isPreorder,
  } = item;

  const isMultipleSkuItem = React.useMemo(() => isItemMsku(item), [item]);

  const displayedPrice = React.useMemo(() => {
    if (isMultipleSkuItem) {
      return getMskuItemPrice(skuOptions);
    }

    return price;
  }, [price, skuOptions, isMultipleSkuItem]);

  const handleClick = React.useCallback(() => {
    if (!isDisabled) {
      onClick({ ...item, quantity: 1, is_hidden_for_recipient: false });
    }
  }, [item, isDisabled, onClick]);

  return (
    <div className={cn(styles.itemContainer, isDisabled ? styles.disabled : '', className)} title={hint}>
      <div className={cn(styles.iconContainer, iconClassName)}>
        <div
          className={cn(styles.icon, isDisabled ? styles.disabled : '')}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
        <ScaleInOut trigger={!isDisabled}>
          {({ style }) => (
            <animated.div
              style={style}
              className={cn(
                styles.controlContainer,
                isSelected ? styles.selected : '',
                isDisabled ? styles.disabled : '',
              )}
              onClick={handleClick}
            />
          )}
        </ScaleInOut>
        <ItemMetadata
          skuOptions={skuOptions}
          isPreorder={isPreorder}
          type={type}
          showCount
          itemId={itemId}
          title={name}
          count={count}
          isCustomizable={isCustomizable}
        />
      </div>
      <div className={cn(styles.priceContainer, isDisabled ? styles.disabled : '')}>
        <Price value={displayedPrice} className={styles.priceValue} />
      </div>
      <div className={cn(styles.titleContainer, isDisabled ? styles.disabled : '')}>
        <div className={styles.title}>{name}</div>
      </div>
    </div>
  );
};

export default InventoryPickerItem;
