import cn from 'classnames';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OneLinkSummaryComponent, SendSummaryComponent } from '../../components';

import { ALLOWED_DOMAIN, ENABLED_2FA, PASSWORD } from '../../constants/shipping';
import { EMAIL_TEMPLATE_ID, MESSAGE } from '../../constants/templates';
import { resetBucketErrors } from '../../store/actions/bucket';
import { postEngagementRequest } from '../../store/actions/engagement';
import { fetchBusinessReasonsRequest } from '../../store/actions/reasons';
import { selectBucketError, selectEngagementCandidate, selectIsDigitalBucket } from '../../store/selectors/bucket';
import { selectIsDelayedShipping } from '../../store/selectors/shipping';
import { IFlowStepProps, IOneLinkEngagementCandidate, TEngagementCandidate } from '../../types/bucket';
import { getBucketTotalPrice } from '../../utils/bucket';
import notification from '../../utils/notification';

import styles from './EngagementSummaryContainer.module.scss';

interface IProps extends IFlowStepProps {
  className?: string;
}

const EngagementSummaryContainer: FC<IProps> = ({ className, SenderInfo, isPYG, ...flowProps }) => {
  const dispatch = useDispatch();

  const engagement = useSelector(selectEngagementCandidate);
  const isDelayedShippingBucket = useSelector(selectIsDelayedShipping);
  const isDigital = useSelector(selectIsDigitalBucket);
  const error = useSelector(selectBucketError);
  const { isOneLink } = React.useMemo(() => flowProps, [flowProps]);

  const showNote = useMemo(() => !!engagement?.[MESSAGE], [engagement?.[MESSAGE]]);

  const showAdvancedSettings = useMemo(
    () =>
      Boolean(
        (engagement as IOneLinkEngagementCandidate)?.[PASSWORD] ||
          (engagement as IOneLinkEngagementCandidate)?.[ALLOWED_DOMAIN] ||
          (engagement as IOneLinkEngagementCandidate)?.[ENABLED_2FA],
      ),
    [engagement],
  );

  const showEmail = useMemo(
    () =>
      Boolean(
        typeof engagement?.[EMAIL_TEMPLATE_ID] !== 'undefined' &&
          engagement?.[EMAIL_TEMPLATE_ID] &&
          (isDigital || isDelayedShippingBucket),
      ),
    [engagement?.[EMAIL_TEMPLATE_ID], isDigital, isDelayedShippingBucket],
  );

  const total = useMemo(() => getBucketTotalPrice(engagement), [engagement]);

  const fetchReasons = useCallback(() => {
    dispatch(fetchBusinessReasonsRequest());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(postEngagementRequest({ engagement, metadata: flowProps }));
  }, [dispatch, engagement, flowProps]);

  useEffect(() => {
    if (error) {
      notification.error(error.message, {
        content: error.message,
        options: {
          onOpen: () => dispatch(resetBucketErrors()),
        },
      });
    }
  }, [error, dispatch]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  const commonProps = React.useMemo(
    () => ({
      showNote,
      showAdvancedSettings,
      showEmail,
      total,
      reportMode: false,
      onSubmit,
      SenderInfo,
      isDigital,
      isPYG,
    }),
    [showNote, showAdvancedSettings, showEmail, total, onSubmit, SenderInfo, isDigital, isPYG],
  );

  return (
    <div className={cn(styles.container, className)}>
      {isOneLink ? (
        <OneLinkSummaryComponent instance={engagement as IOneLinkEngagementCandidate} {...commonProps} showQuantity />
      ) : (
        <SendSummaryComponent instance={engagement as TEngagementCandidate} {...commonProps} showQuantity />
      )}
    </div>
  );
};

export default EngagementSummaryContainer;
