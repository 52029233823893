import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as iconCSVInfo } from '../../assets/images/icon-csv-info.svg';
import { ReactComponent as FileIcon } from '../../assets/images/icon-file.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/icon-upload.svg';
import { FileDetails, InfoLink, UploadResult } from '../../components';
import {
  ActionButton,
  Checkbox,
  ExpirationDateInput,
  HoldUntilDateInput,
  RadioButtonGroup,
  Selector,
  UploadInput,
} from '../../components/forms';
import { CSV_BULK_SEND_EXAMPLE_URL } from '../../config/app';
import {
  DELAYED_SHIPPING_EXPIRATION,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  IS_TOGGLE_ON_HOLD_ENABLED,
  SEND_ON_HOLD,
} from '../../constants/bucket';
import { DELAYED_SHIPPING_EXPIRATION_DATE_HINT } from '../../constants/engagement';
import { BUSINESS_REASON_ID } from '../../constants/reasons';
import { SHIPPING_OPTION, SHIPPING_OPTIONS } from '../../constants/shipping';
import useUploadFile from '../../hooks/useUploadFile';
import {
  addBucketValue,
  addBulkData,
  addShippingInformation,
  bulkEngagementRequest,
  clearBulkData,
  flushBucket,
  uploadBulkCsv,
} from '../../store/actions/bucket';
import {
  selectBucketItems,
  selectBulkData,
  selectBulkResult,
  selectBusinessReasonId,
  selectDelayedShippingExpirationDate,
  selectIsBulkSubmitted,
  selectIsBusinessReasonRequired,
  selectIsDisabledBulkSenderNotifications,
  selectIsPYGEnabled,
  selectIsShippingOptionEnabled,
  selectPYGCampaignsList,
  selectSendOnHold,
  selectShippingOption,
  selectUploadedCSV,
} from '../../store/selectors/bucket';
import { selectBusinessReasons } from '../../store/selectors/reasons';
import { IBulkEngagementRequest, IBulkEngagementResponse, IFlowStepProps } from '../../types/bucket';
import { IDropzoneResult } from '../../types/forms';
import { ISelectorValue } from '../../types/shell';
import { ShippingOptionsEnum } from '../../types/shipping';
import { UploadFileTypeEnum } from '../../types/upload';
import { formatFileSize, getFileInfo } from '../../utils/bucket';
import { isDigitalBox } from '../../utils/campaigns';
import notification from '../../utils/notification';

import styles from './UploadCSVContainer.module.scss';

const UploadCSVContainer: React.FC<IFlowStepProps> = ({ isBulk }) => {
  const dispatch = useDispatch();
  const uploadedFile = useSelector(selectUploadedCSV);
  const isDisabledSenderNotifications = useSelector(selectIsDisabledBulkSenderNotifications);
  const sendOnHoldDate = useSelector(selectSendOnHold);
  const sendExpirationDate = useSelector(selectDelayedShippingExpirationDate);
  const bulkRequestData = useSelector(selectBulkData);
  const isShippingOptionEnabled = useSelector(selectIsShippingOptionEnabled);
  const result = useSelector(selectBulkResult);
  const bucketItems = useSelector(selectBucketItems);
  const pygCampaigns = useSelector(selectPYGCampaignsList);
  const isPYGFlow = useSelector(selectIsPYGEnabled);
  const { uploadFile } = useUploadFile();

  const shippingOption = useSelector(selectShippingOption);

  const isSubmitted = useSelector(selectIsBulkSubmitted);

  const isBusinessReasonRequired = useSelector(selectIsBusinessReasonRequired);
  const businessReasonSelectedValue = useSelector(selectBusinessReasonId);
  const reasons = useSelector(selectBusinessReasons);

  const reasonsOptions = React.useMemo(() => {
    if (!reasons) {
      return [];
    }
    return reasons.map(({ name, uid }) => {
      return {
        label: name,
        value: uid,
      };
    });
  }, [reasons]);

  const isDisabled = React.useMemo(
    () => !uploadedFile || (isBusinessReasonRequired && !businessReasonSelectedValue),
    [businessReasonSelectedValue, isBusinessReasonRequired, uploadedFile],
  );

  const isLoaded = React.useMemo(() => !!uploadedFile, [uploadedFile]);

  const isNotDigitalSend = React.useMemo(() => {
    if (isPYGFlow) {
      const items = pygCampaigns?.map((campaign) => campaign.items).flat();
      return !isDigitalBox({ items });
    }
    return !isDigitalBox({ items: bucketItems });
  }, [bucketItems, isPYGFlow, pygCampaigns]);

  const clearUploadedFile = React.useCallback(() => {
    dispatch(addBulkData({ csv: null }));
  }, [dispatch]);

  const clearScreen = React.useCallback(() => {
    dispatch(clearBulkData());
  }, [dispatch]);

  const handleIsDisabledNotificationsChange = React.useCallback(() => {
    dispatch(addBulkData({ [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: !isDisabledSenderNotifications }));
  }, [dispatch, isDisabledSenderNotifications]);

  const handleShippingOptionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      dispatch(addShippingInformation({ [SHIPPING_OPTION]: value as ShippingOptionsEnum }, { isBulk }));
    },
    [dispatch, isBulk],
  );

  const handleSendOnHold = React.useCallback(
    (value: string) => {
      dispatch(addBucketValue({ [SEND_ON_HOLD]: value ? new Date(value).toISOString() : value }));
    },
    [dispatch],
  );

  const handleSendExpirationDate = React.useCallback(
    (value: string) => {
      dispatch(addBucketValue({ [DELAYED_SHIPPING_EXPIRATION]: value ? new Date(value).toISOString() : value }));
    },
    [dispatch],
  );

  const handleToggleChange = React.useCallback(
    (value: boolean) => {
      dispatch(addBucketValue({ [IS_TOGGLE_ON_HOLD_ENABLED]: value }));
    },
    [dispatch],
  );

  const handleSelectBusinessReason = React.useCallback(
    (reason: ISelectorValue) => {
      dispatch(addBucketValue({ [BUSINESS_REASON_ID]: reason?.value }));
    },
    [dispatch],
  );

  const showMessage = React.useCallback((response: IBulkEngagementResponse | { message?: string }) => {
    if ('message' in response) {
      return notification.error(response.message, { content: response.message });
    }
    if ('error_count' in response && 'success_count' in response) {
      const { error_count, success_count } = response;
      const message = `There ${success_count === 1 ? 'was' : 'were'} ${success_count} send${
        success_count === 1 ? '' : 's'
      } created and ${error_count} error${error_count === 1 ? '' : 's'}`;

      return notification.show(message, 'default', { content: message });
    }
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (bulkRequestData) {
      return new Promise<IBulkEngagementResponse>((resolve, reject) => {
        dispatch(bulkEngagementRequest({ data: bulkRequestData as IBulkEngagementRequest, resolve, reject }));
      })
        .then(showMessage)
        .catch(showMessage);
    }
  }, [dispatch, bulkRequestData, showMessage]);

  const onUploadStart = React.useCallback(
    ({ files, resolve, reject }: IDropzoneResult) => {
      dispatch(uploadBulkCsv());
      uploadFile(files[0], UploadFileTypeEnum.BulkEngagement).then(resolve).catch(reject);
    },
    [dispatch, uploadFile],
  );

  const onUploadSuccess = React.useCallback(
    (files: File[]) => dispatch(addBulkData({ csv: getFileInfo(files[0]) })),
    [dispatch],
  );

  const onUploadFailure = React.useCallback(({ message = 'An error was occurred during file uploading.' }) => {
    notification.error(message, { content: message });
  }, []);

  React.useEffect(() => {
    clearScreen();
  }, [clearScreen]);

  React.useEffect(() => {
    if (isSubmitted) {
      return () => {
        dispatch(flushBucket());
      };
    }
  }, [dispatch, isSubmitted]);

  return (
    <div className={cn(styles.container)}>
      <div className={styles.upload}>
        <div className={styles.title}>File uploader</div>
        <UploadInput
          accept=".csv"
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess}
          onFailure={onUploadFailure}
          className={styles.uploadField}
          multiple={false}
        >
          {({ isDragActive }) => {
            return (
              <div className={styles.placeholder}>
                {isLoaded ? <FileIcon /> : <UploadIcon />}
                {isDragActive ? (
                  <span className={styles.uploadMessage}>Drop the files here ...</span>
                ) : (
                  <span className={styles.uploadMessage}>
                    {isLoaded ? 'The file was uploaded' : 'Click or drag to upload CSV file'}
                  </span>
                )}
              </div>
            );
          }}
        </UploadInput>
        {uploadedFile ? (
          <FileDetails
            action={<button className={styles.clearIcon} onClick={clearUploadedFile} />}
            name={uploadedFile.name}
            icon={iconCSVInfo}
            description={`${formatFileSize(uploadedFile.size)}`}
          />
        ) : (
          <InfoLink
            url={CSV_BULK_SEND_EXAMPLE_URL}
            className={styles.exampleLink}
            toggleClassName={styles.toggle}
            label="Download Example"
            hint="Click on the link to download CSV file example"
          />
        )}
        {isShippingOptionEnabled && (
          <div className={cn(styles.optionsList)}>
            <span className={styles.label}>Delivery Options</span>
            <RadioButtonGroup
              options={SHIPPING_OPTIONS}
              value={shippingOption}
              onChange={handleShippingOptionChange}
              name={SHIPPING_OPTION}
            />
          </div>
        )}
        <div className={styles.actions}>
          <Checkbox
            className={styles.checkbox}
            onChange={handleIsDisabledNotificationsChange}
            checked={isDisabledSenderNotifications}
            text={<span className={styles.checkboxLabel}>Disable email sender notifications</span>}
            textAbout="If the box is checked, Imprint Engine will not generate email notifications with updates on every send. You will still be able to check the status of each one within the Delivery Reports."
          />
          <div className={styles.wrapper}>
            {isNotDigitalSend && (
              <>
                <HoldUntilDateInput
                  value={sendOnHoldDate}
                  onChange={handleSendOnHold}
                  onToggleChange={handleToggleChange}
                />
                <ExpirationDateInput
                  value={sendExpirationDate}
                  onChange={handleSendExpirationDate}
                  hint={DELAYED_SHIPPING_EXPIRATION_DATE_HINT}
                />
              </>
            )}
          </div>
          <div className={styles.selectorContainer}>
            <Selector
              name={BUSINESS_REASON_ID}
              isSearchable
              className={styles.input}
              closeMenuOnSelect
              value={reasonsOptions.find((reason) => reason.value === businessReasonSelectedValue) || null}
              onChange={(reason) => handleSelectBusinessReason(reason as ISelectorValue)}
              options={reasonsOptions}
              placeholder="Select business reason"
              helperText="Business Reason"
              onClear={() => handleSelectBusinessReason({ value: '' } as ISelectorValue)}
              required={isBusinessReasonRequired}
            />
          </div>
          <ActionButton className={styles.btn} title="Create Sends" onClick={handleSubmit} disabled={isDisabled} />
        </div>
      </div>
      <UploadResult className={styles.upload} result={result} />
    </div>
  );
};

export default UploadCSVContainer;
