import cn from 'classnames';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import { ICampaign, ICampaignItem, ICampaignSummary } from '../../types/campaigns';
import { IInventoryItem, IOnDemandItem } from '../../types/inventories';
import { InventorySidebarModeEnum, InventorySidebarTypeEnum } from '../../types/shell';
import { getBoxTotal } from '../../utils/campaigns';
import { hasMskuItem } from '../../utils/inventories';
import { ActionButton } from '../forms';
import { LabelWithEditButton } from '../forms/labels';
import { CampaignPreview, CampaignViewItem, InventoryViewItem } from '../index';

import styles from './InventorySidebar.module.scss';

interface ICommonProps {
  className?: string;
  listClassName?: string;
  itemClassName?: string;
  label?: string | React.ReactNode;
  onSelect?: (item: ICampaignItem | IInventoryItem) => void;
  onEdit?: () => void;
  mode?: InventorySidebarModeEnum;
  showBorder?: boolean;
  showSeparators?: boolean;
  selected?: string;
  items?: (IInventoryItem | IOnDemandItem | ICampaign | ICampaignSummary)[] | null;
  type: InventorySidebarTypeEnum;
  showCount?: boolean;
  showQuantity?: boolean;
  isEditable?: boolean;
  isDisabledFn?: (item: IInventoryItem | ICampaignItem) => boolean;
  reportMode?: boolean;
}

/* * * * * * * * * * * * * IMPORTANT NOTE ! * * * * * * * * * * * * */
/* If you need to add anything to this component, please, consider splitting it or making it less complicated */
/* Please, review to this ticket => https://bluebirdcx.atlassian.net/browse/EX-1165 */
const InventorySidebar: React.FC<ICommonProps> = ({
  className,
  listClassName,
  itemClassName,
  items,
  label,
  onSelect,
  onEdit,
  mode = InventorySidebarModeEnum.View,
  showBorder = true,
  showSeparators,
  selected,
  type,
  showCount = true,
  showQuantity,
  isEditable,
  isDisabledFn,
  reportMode = false,
}) => {
  const { mobile } = useWindowSize();
  const inventoryRef = useRef<HTMLDivElement>(null);

  const isSelectMode = useMemo(() => mode === InventorySidebarModeEnum.Select, [mode]);

  const handleItemSelect = useCallback(
    (item: IInventoryItem | ICampaignItem) => {
      if (typeof onSelect === 'function') {
        onSelect(item);
      }
    },
    [onSelect],
  );

  const renderCampaignItem = useCallback(
    (item: ICampaign) => {
      const { box_id: boxId, name, icon_url: iconUrl, items: campaignItems } = item;
      const hasMsku = hasMskuItem(campaignItems);

      return (
        <div
          key={boxId}
          className={cn(styles.item, styles.campaign, {
            [styles.selectItem]: isSelectMode,
            [styles.selected]: selected === boxId,
          })}
        >
          <CampaignViewItem
            iconClassName={styles.campaignIcon}
            className={itemClassName}
            name={name}
            hasMsku={hasMsku}
            price={getBoxTotal(item)}
            imageUrl={iconUrl}
            hint={
              <CampaignPreview
                showPreview
                campaign={item}
                className={styles.preview}
                reportMode={reportMode}
                showWarning
              />
            }
          />
        </div>
      );
    },
    [isSelectMode, selected, itemClassName, reportMode],
  );

  const renderInventoryItem = useCallback(
    (item: IInventoryItem | ICampaignItem) => {
      const { item_id: itemId } = item;
      const isDisabledInventoryItem = typeof isDisabledFn === 'function' ? isDisabledFn(item) : false;

      return (
        <div
          key={itemId}
          className={cn(styles.item, {
            [styles.selectItem]: isSelectMode,
            [styles.separated]: showSeparators,
            [styles.disabled]: isDisabledInventoryItem,
          })}
        >
          <InventoryViewItem
            item={item}
            showCount={showCount}
            showQuantity={showQuantity}
            className={itemClassName}
            actions={
              isSelectMode && (
                <ActionButton
                  title="Add"
                  onClick={() => handleItemSelect(item)}
                  className={styles.addBtn}
                  disabled={isDisabledInventoryItem}
                />
              )
            }
          />
        </div>
      );
    },
    [isSelectMode, handleItemSelect, showQuantity, showCount, itemClassName],
  );

  const onWheel: React.EventHandler<React.WheelEvent<HTMLDivElement>> = useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => {
      const container = inventoryRef.current;
      if (container && mobile) {
        const containerScrollPosition = container.scrollLeft;
        container.scrollTo({
          top: 0,
          left: containerScrollPosition + e.deltaY,
          behavior: 'smooth',
        });
      }
    },
    [mobile, inventoryRef],
  );

  return (
    <div className={cn(styles.inventory, { [styles.inventoryBorder]: !mobile && showBorder }, className)}>
      {label && (
        <span className={styles.inventoryLabel}>
          <LabelWithEditButton isEditable={isEditable} text={label} onEdit={onEdit} />
        </span>
      )}
      <div
        className={cn(styles.inventoryList, listClassName)}
        ref={inventoryRef}
        onWheel={mobile ? onWheel : undefined}
      >
        {items?.map((el) => {
          return type === InventorySidebarTypeEnum.Campaign
            ? renderCampaignItem(el as ICampaign)
            : renderInventoryItem(el as IInventoryItem);
        })}
      </div>
    </div>
  );
};
export default InventorySidebar;
