import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { apiUrl } from '../config/app';
import { selectUserAccessToken } from '../store/selectors/auth';
import { IRequestHelperParams, IRequestQuery } from '../types/http';
import { IApiError } from '../types/shell';
import notification from '../utils/notification';
import { Request } from '../utils/request';

interface IFetchParams extends IRequestHelperParams {
  auth?: boolean;
  showToastOnError?: boolean;
}

interface IState<BodyRequestType, ResponseType, ErrorType> {
  request: IPayload<BodyRequestType> | null;
  response: ResponseType | null;
  isLoading: boolean;
  error: ErrorType | null;
  timestamp: number | null;
}

interface IPayload<BodyRequestType> {
  query?: IRequestQuery;
  body?: BodyRequestType;
}

enum ActionTypes {
  request = 'request',
  success = 'success',
  failure = 'failure',
  reset = 'reset',
}

interface IAction<BodyRequestType, ResponseType, ErrorType> {
  type: ActionTypes;
  payload?: IPayload<BodyRequestType> | ResponseType;
  error?: ErrorType | unknown;
}

const initialState = {
  request: null,
  response: null,
  isLoading: false,
  error: null,
  timestamp: null,
};

function reducer<BodyRequestType, ResponseType, ErrorType>(
  state: IState<BodyRequestType, ResponseType, ErrorType>,
  action: IAction<BodyRequestType, ResponseType, ErrorType>,
) {
  const { type, payload, error } = action;

  switch (type) {
    case ActionTypes.request:
      return {
        ...state,
        request: payload as IPayload<BodyRequestType>,
        isLoading: true,
        error: null,
        timestamp: Date.now(),
      };
    case ActionTypes.success:
      return {
        ...state,
        isLoading: false,
        response: payload as ResponseType,
      };
    case ActionTypes.failure:
      return {
        ...state,
        isLoading: false,
        error: error as ErrorType,
      };
    case ActionTypes.reset:
      return initialState;
    default:
      return state;
  }
}
function useFetch<BodyRequestType, ResponseType, ErrorType = string | string[] | null | { text?: string }>(
  props: IFetchParams,
) {
  const {
    auth = true,
    isJson = true,
    headers: requestHeaders,
    endpoint = '',
    showToastOnError,
    ...params
  } = React.useMemo(() => props || {}, [props]);

  const firebase = useFirebase();
  const token = useSelector(selectUserAccessToken);
  const tokenRef = useRef({ token });
  const [{ request, response, error, isLoading, timestamp }, dispatch] = React.useReducer<
    React.Reducer<IState<BodyRequestType, ResponseType, ErrorType>, IAction<BodyRequestType, ResponseType, ErrorType>>
  >(reducer, initialState);

  React.useEffect(() => {
    tokenRef.current.token = token;
  }, [token]);

  const wait = React.useCallback((oldToken?: string, timeout = 5000) => {
    let intervalId: NodeJS.Timeout;
    let timeoutId: NodeJS.Timeout;

    return Promise.race([
      new Promise<void>((res) => {
        intervalId = setInterval(() => {
          if (typeof oldToken !== 'undefined' && oldToken !== tokenRef.current.token) {
            res();
          }
        }, 50);
      }),
      new Promise((res, rej) => {
        timeoutId = setTimeout(() => {
          rej();
        }, timeout);
      }),
    ]).finally(() => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    });
  }, []);

  // TODO: think about the merge with AuthRequest saga. Is it possible?
  const authRequest = React.useCallback(
    async ({ headers: initHeaders, retry = true, ...requestParams }: IRequestHelperParams) => {
      const headers = initHeaders || {};
      if (auth) {
        headers.Authorization = `Bearer ${tokenRef.current.token}`;
      }

      let responseData = await Request<ResponseType | ErrorType>({ headers, ...requestParams });

      if (!responseData.ok && responseData.status === 401) {
        if (retry) {
          await firebase
            .reloadAuth()
            .then(() => wait(token))
            .then(async () => {
              responseData = await authRequest({
                headers,
                retry: false,
                ...requestParams,
              });
            })
            .catch(() => {
              firebase.logout();
            });
        } else {
          await firebase.logout();
        }
      }

      return responseData;
    },
    [firebase, auth, token, wait],
  );

  const make = React.useCallback(
    async ({ query, body: rawBody }: IPayload<BodyRequestType> = {}) => {
      try {
        const body = (() => {
          if (!isJson) {
            return rawBody;
          }

          return typeof rawBody === 'object' ? JSON.stringify(rawBody) : undefined;
        })();

        dispatch({
          type: ActionTypes.request,
          payload: {
            query,
            body: rawBody,
          },
        });

        const responseData = await authRequest({
          ...params,
          endpoint: !/^(http|https).+/.test(endpoint) ? `${apiUrl}${endpoint}` : endpoint,
          headers: requestHeaders,
          isJson,
          ...(query ? { queryParams: query } : {}),
          body: body as IRequestHelperParams['body'],
        });

        if (!responseData) {
          throw new Error(`Something bad happened. Action wasn't performed.`);
        }

        if (!responseData.ok) {
          throw (responseData.body as { text?: string }).text
            ? JSON.parse((responseData.body as { text?: string }).text!)
            : responseData.body;
        }

        dispatch({ type: ActionTypes.success, payload: responseData.body as ResponseType });
        return responseData.body as ResponseType;
      } catch (err) {
        dispatch({ type: ActionTypes.failure, error: err });

        if (showToastOnError) {
          const message = (err as IApiError)?.message || '';
          await notification.error(message, { content: message });
        }

        throw err;
      }
    },
    [authRequest, requestHeaders, isJson, params, dispatch, endpoint, showToastOnError],
  );

  return {
    make,
    request,
    response,
    reset: () => dispatch({ type: ActionTypes.reset }),
    error,
    isLoading,
    timestamp,
  };
}

export default useFetch;
