import { FirebaseReducer, isEmpty, isLoaded } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { ISenderInfo } from '../../types/auth';
import { isAdminType } from '../../utils/users';
import { IAppState } from '../reducers';

export const selectAuth = (state: IAppState) => state.auth;

export const selectFirebase = (state: IAppState) => state.firebase;

export const selectIsAuthorized = createSelector(selectAuth, (auth) => (auth ? !!auth.userInfo : false));

export const selectIsAppLoading = createSelector(selectAuth, (auth) => (auth ? auth.isLoading : false));

export const selectFirebaseAuth = createSelector(selectFirebase, (firebase) => (firebase ? firebase.auth : undefined));

export const selectFirebaseProfile = createSelector(selectFirebase, (firebase) =>
  firebase ? firebase.profile : undefined,
);

export const selectUserInfo = createSelector(selectAuth, (auth) => (auth ? auth.userInfo : undefined));

export const selectDigitalNotificationsDisabled = createSelector(
  selectUserInfo,
  (user) => user?.digital_balance_emails_disabled || false,
);

export const selectFirstName = createSelector(selectAuth, (auth) => (auth ? auth.firstname : undefined));

export const selectSenderInfo = createSelector(selectUserInfo, (userInfo) => {
  return userInfo ? userInfo.sender : undefined;
});

export const selectAvailableBudget = createSelector(selectFirebaseProfile, (profile) =>
  profile ? Number(profile.available_budget) : 0,
);

export const selectUsedBudget = createSelector(selectFirebaseProfile, (profile) =>
  profile ? Number(profile.used_budget) : 0,
);

export const selectRemainingBudget = createSelector(
  selectAvailableBudget,
  selectUsedBudget,
  (availableBudget, usedBudget) => availableBudget - usedBudget,
);

export const selectFirebaseClaims = createSelector(selectFirebaseProfile, (profile) =>
  profile && profile.token ? profile.token.claims : undefined,
);

export const selectIsFirebaseProfileLoaded = createSelector(selectFirebaseProfile, (profile) =>
  profile ? profile.isLoaded : undefined,
);

export const selectIsSignedIn = createSelector(selectFirebaseAuth, (auth) =>
  auth ? isLoaded(auth) && !isEmpty(auth) : false,
);

export const selectIsSignedInProgress = createSelector(
  selectFirebaseAuth,
  selectIsAppLoading,
  (auth, isAppLoading) => (!isLoaded(auth) && isEmpty(auth)) || isAppLoading,
);

export const selectIsAuthenticated = createSelector(
  selectIsSignedIn,
  selectIsAuthorized,
  (isSignedIn, isAuthorized) => {
    return isSignedIn && isAuthorized;
  },
);

export const selectUserProfile = createSelector(
  selectIsSignedIn,
  selectFirebaseAuth,
  selectSenderInfo,
  (isSignedIn, auth, sender) => {
    if ((!auth && !sender) || !isSignedIn) {
      return undefined;
    }
    const { email, photoURL } = auth;
    const displayName = (({ displayName: name }, { first_name: firstName = '', last_name: lastName = '' }) => {
      if (name) {
        return name;
      }

      if (firstName || lastName) {
        return `${firstName ? `${firstName} ` : ''}${lastName}`;
      }

      return '';
    })(auth, sender || ({} as ISenderInfo));

    return {
      displayName,
      email,
      photoURL,
    };
  },
);

export const selectUserDepartmentId = createSelector(selectIsSignedIn, selectFirebaseProfile, (isSignedIn, profile) => {
  if (!profile || !isSignedIn) {
    return undefined;
  }

  return profile.department_id;
});

export const selectDigitalBudget = createSelector(selectUserInfo, (userInfo) => {
  return userInfo ? userInfo.digital_budget : undefined;
});

export const selectCurrentUserId = createSelector(selectUserInfo, (userInfo) => {
  return userInfo ? userInfo.user_id : undefined;
});

export const selectUserEmail = createSelector(selectSenderInfo, (sender) => {
  return sender ? sender.email : undefined;
});

export const selectIsDigitalBudgetLoading = createSelector(selectDigitalBudget, (digitalBudget) => {
  return digitalBudget ? digitalBudget.isLoading : false;
});

export const selectDigitalBudgetValue = createSelector(selectDigitalBudget, (digitalBudget) => {
  return digitalBudget ? digitalBudget.value : undefined;
});

export const selectUserAccessToken = createSelector(selectFirebaseAuth, (auth: FirebaseReducer.AuthState) =>
  auth.stsTokenManager ? auth.stsTokenManager.accessToken : undefined,
);

export const selectIsAuthenticatedInProgress = createSelector(
  selectIsSignedInProgress,
  selectIsAppLoading,
  (isSignedInProgress, isAppLoading) => isSignedInProgress || isAppLoading,
);

export const selectAdminType = createSelector(selectFirebaseClaims, (claims) =>
  claims ? claims.admin_type : undefined,
);

export const selectIsDeptAdmin = createSelector(selectAdminType, (adminType) => adminType === DEPT_ADMIN);

export const selectIsOrgAdmin = createSelector(selectAdminType, (adminType) => adminType === ORG_ADMIN);

export const selectIsDistributor = createSelector(selectAdminType, (adminType) => adminType === DISTRIBUTOR);

export const selectIsSuperAdmin = createSelector(selectAdminType, (adminType) => adminType === SUPER_ADMIN);

export const selectIsAdminTypeUser = createSelector(selectAdminType, isAdminType);

export const selectIsCrmSkipAllowedToUser = createSelector(selectSenderInfo, (sender) => {
  return sender ? sender.crm_skip_allowed : false;
});

export const selectOrganizationInfo = createSelector(selectUserInfo, (userInfo) => {
  if (!userInfo) {
    return null;
  }

  // tslint:disable-next-line:variable-name
  const { org_id: uid, org_name: name, org_logo_url: logo_url } = userInfo;

  return {
    uid,
    name,
    logo_url,
  };
});
