import { useSelector } from 'react-redux';

import { apiUrl } from '../config/app';
import { endpoints } from '../constants/routing';
import useAuthQuery from '../hooks/useAuthQuery';
import { selectUserAccessToken } from '../store/selectors/auth';
import { IFetchInventoryItemsRequestPayload, IFetchInventoryItemsResponse } from '../types/inventories';
import { QueryRequest } from '../utils/request';

const InventoryService = {
  getInventoryItems: (params: Partial<IFetchInventoryItemsRequestPayload>, token?: string) =>
    QueryRequest<IFetchInventoryItemsResponse>(
      {
        endpoint: `${apiUrl}${endpoints.inventoryItems}`,
        method: 'POST',
        body: JSON.stringify(params),
      },
      token,
    ).then((res) => {
      if (res.ok) {
        return res.body.inventory_items;
      }
      throw res;
    }),
};

export const useInventoryItems = (params: Partial<IFetchInventoryItemsRequestPayload>) => {
  const token = useSelector(selectUserAccessToken);

  return useAuthQuery(
    ['inventory-items', params],
    async () => {
      const data = await InventoryService.getInventoryItems(params, token);

      return data ?? null;
    },
    { keepPreviousData: true },
  );
};

export default InventoryService;
