import qs from 'query-string';
import { SupportedTenantsEnum } from '../../constants/auth';

import { IEmergencyNotification, IShellState } from '../../types/shell';
import * as ShellActions from '../actions/shell';

const initialState: IShellState = {
  theme: {
    portal_sidebar_text_color: undefined,
    portal_sidebar_color: undefined,
    portal_accent_color: undefined,
    org_rect_logo: undefined,
    org_square_logo: undefined,
  },
  isThemeLoading: false,
  sidenavIsShown: undefined,
  drawerIsShown: false,
  boxDetailsSidebarIsShown: false,
  twoFASidenavIsShown: false,
  feedbackSidenavIsShown: false,
  suggestGiftSidenavIsShown: false,
  lowItemsSnoozedAt: '',
  lowItemsLastShownAt: '',
  emergencyNotification: null,
  metadata: {
    email_providers: null,
    crm_providers: null,
    delayed_shipping_base_url: '',
    ds_text_customization_tags: null,
    isLoading: false,
    error: null,
  },
  flags: {
    isOrganizationFormExpanded: false,
    isMskuOptionsFormShown: false,
    isAIExtensionInstalled: false,
  },
  build: process.env.REACT_APP_BUILD_ID || '',
  commit: process.env.REACT_APP_COMMIT_SHA || '',
};

const reducer = (state: IShellState = initialState, action: any): IShellState => {
  switch (action.type) {
    case ShellActions.INIT_APP: {
      const { location } = action.payload;
      const query = qs.parse(location.search);

      return {
        ...state,
        tenant:
          query.tenant && Object.values(SupportedTenantsEnum).includes(query.tenant as SupportedTenantsEnum)
            ? (query.tenant as SupportedTenantsEnum)
            : state.tenant,
      };
    }
    case ShellActions.UPDATE_EMERGENCY_NOTIFICATION: {
      return {
        ...state,
        emergencyNotification: action.payload as IEmergencyNotification | null,
      };
    }
    case ShellActions.TOGGLE_UI_ELEMENT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ShellActions.FETCH_METADATA_REQUEST: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          isLoading: true,
          error: null,
        },
      };
    }
    case ShellActions.FETCH_METADATA_SUCCESS: {
      const { metadata } = action.payload;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...metadata,
          isLoading: false,
        },
      };
    }
    case ShellActions.FETCH_METADATA_FAILURE: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case ShellActions.FETCH_THEME_REQUEST: {
      return {
        ...state,
        isThemeLoading: true,
        theme: { ...state.theme },
      };
    }
    case ShellActions.FETCH_THEME_FAILURE: {
      return {
        ...state,
        isThemeLoading: false,
        theme: { ...state.theme },
      };
    }
    case ShellActions.FETCH_THEME_SUCCESS: {
      return {
        ...state,
        isThemeLoading: false,
        theme: { ...action.payload },
      };
    }
    case ShellActions.TOGGLE_ADDITIONAL_ORGANIZATION_FORM: {
      return {
        ...state,
        flags: {
          ...state.flags,
          isOrganizationFormExpanded:
            typeof action.payload === 'undefined' ? !state.flags.isOrganizationFormExpanded : action.payload,
        },
      };
    }
    case ShellActions.TOGGLE_MSKU_OPTIONS_FORM: {
      return {
        ...state,
        flags: {
          ...state.flags,
          isMskuOptionsFormShown:
            typeof action.payload === 'undefined' ? !state.flags.isMskuOptionsFormShown : action.payload,
        },
      };
    }
    case ShellActions.UPDATE_LOW_ITEMS_SNOOZED_AT: {
      return {
        ...state,
        lowItemsSnoozedAt: action.payload,
      };
    }
    case ShellActions.UPDATE_LOW_ITEMS_SHOWN_AT: {
      return {
        ...state,
        lowItemsLastShownAt: action.payload,
      };
    }
    case ShellActions.TOGGLE_IS_AI_EXTENSION_INSTALLED: {
      return {
        ...state,
        flags: {
          ...state.flags,
          isAIExtensionInstalled: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
