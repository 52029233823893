import cn from 'classnames';
import * as React from 'react';

import { BagCount, Price } from '../index';

import styles from './BucketTotal.module.scss';

interface IProps {
  price: number;
  onClick?: () => void;
  count: number;
  className?: string;
}

const BucketTotal = ({ className, count, onClick, price }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.price}>
        Total price <Price value={price} className={styles.value} />
      </div>
      <div className={styles.items}>
        <div className={styles.title}>Added items</div>
        <BagCount count={count} onClick={onClick} />
      </div>
    </div>
  );
};

export default BucketTotal;
