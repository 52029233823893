import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '../../assets/images/icon-delete-button.svg';
import EditIcon from '../../assets/images/icon-edit-button.svg';
import ResetIcon from '../../assets/images/icon-reset-password-button.svg';

import { Avatar, Card, Filter, IntegrationItem } from '../../components';
import Budget from '../../components/Budget/Budget';
import { AddButton, Checkbox, TextIconButton } from '../../components/forms';
import { UserIntegrationIcons } from '../../constants/integrations';
import { routes, URL_VARS } from '../../constants/routing';
import { DIGITAL_BUDGET_WARNING_RATE } from '../../constants/shell';
import {
  ADMIN_TYPE,
  CRM_SKIP_ALLOWED,
  DEPARTMENT_ID,
  DISTRIBUTOR,
  UserFilterTypesEnum,
  USER_FILTERS,
  USER_LABEL,
} from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { editUserRequest } from '../../store/actions/users';
import { selectCurrentOrganizationData } from '../../store/selectors/organizations';
import { selectCanEditUser, selectUsers } from '../../store/selectors/users';
import { IUser, TUserIntegrations, UserIntegrationStatusesEnum } from '../../types/users';
import { filterByRole, getDepartmentLabelById, getUserRoleLabel } from '../../utils/users';

import styles from './UsersContainer.module.scss';

interface IProps {
  onDelete: (id: string) => void;
  onResetPassword: (email: string) => void;
  checkCanEditUser: (user: Partial<IUser>) => boolean;
}

const UsersContainer = ({ onDelete, onResetPassword, checkCanEditUser }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mobile } = useWindowSize();
  const { setHeader, setAdditionalData } = useHeaderDispatch();

  const [tab, setTab] = React.useState(UserFilterTypesEnum.All);

  const userList = useSelector(selectUsers);
  const canEditUser = useSelector(selectCanEditUser);
  const { departmentList: departments, options: organizationOptions } =
    useSelector(selectCurrentOrganizationData) || {};

  const { DeptFilter, filteredItems } = useDeptFilter(userList || [], DEPARTMENT_ID, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  const isSkipCrmAllowedInOrg = React.useMemo(() => organizationOptions?.crm_skip_allowed, [organizationOptions]);
  const users = React.useMemo(() => (filteredItems as IUser[])?.filter(filterByRole(tab)), [filteredItems, tab]);

  const getDepartmentLabel = React.useCallback((id: string) => getDepartmentLabelById(id, departments), [departments]);

  const handleIntegrationClick = React.useCallback((name: string, uid?: string) => ({ name, uid }), []);

  const getIntegrationIcon = React.useCallback(
    (name: keyof TUserIntegrations) => {
      const { crm_currently_supports, email_currently_supports } = organizationOptions || {};

      const integrationName = (() => {
        switch (name) {
          case 'crm_integration':
            return crm_currently_supports;
          case 'email_integration':
            return email_currently_supports;
          default:
            break;
        }
      })();

      return integrationName ? UserIntegrationIcons[integrationName] : void 0;
    },
    [organizationOptions],
  );

  const renderIntegrations = React.useCallback(
    ({ integration, uid }: Partial<IUser>) =>
      integration ? (
        <div className={styles.integrationBar}>
          {Object.entries(integration).map(([name, status]) =>
            status !== UserIntegrationStatusesEnum.NA ? (
              <IntegrationItem
                key={name}
                active={status === UserIntegrationStatusesEnum.ON}
                icon={getIntegrationIcon(name as keyof TUserIntegrations)}
                className={styles.integrationItem}
                onClick={() => handleIntegrationClick(name, uid)}
              />
            ) : null,
          )}
        </div>
      ) : null,
    [getIntegrationIcon, handleIntegrationClick],
  );

  const handleCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, user: IUser) => {
      const { checked } = event.currentTarget;
      return new Promise((resolve, reject) => {
        const { distributor_org_ids, crm_skip_allowed, ...rest } = user;
        const candidate = {
          ...(rest[ADMIN_TYPE] === DISTRIBUTOR ? { distributor_org_ids } : {}),
          crm_skip_allowed: checked,
          ...rest,
        };
        dispatch(editUserRequest({ user: candidate, resolve, reject }));
      });
    },
    [dispatch],
  );

  const renderSkipCrmCheckbox = React.useCallback(
    (user: IUser) => {
      return (
        isSkipCrmAllowedInOrg && (
          <div className={styles.checkBox} onClick={(e) => e.stopPropagation()}>
            <Checkbox
              disabled={!checkCanEditUser(user)}
              checked={user[CRM_SKIP_ALLOWED] || false}
              onChange={(event) => handleCheckboxChange(event, user)}
              text="Allow Skip CRM"
            />
          </div>
        )
      );
    },
    [handleCheckboxChange, checkCanEditUser, isSkipCrmAllowedInOrg],
  );

  const renderControls = React.useCallback(
    (user: IUser) => (
      <React.Fragment>
        <TextIconButton
          trigger={mobile}
          className={styles.actionBtn}
          icon={ResetIcon}
          title="Reset Password"
          onClick={(e) => {
            e.stopPropagation();
            onResetPassword(user.email);
          }}
        />
        <TextIconButton
          trigger={mobile}
          className={styles.actionBtn}
          icon={EditIcon}
          title="Edit"
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              routes.users.getUserUrl({
                flowId: URL_VARS.EDIT,
                userId: user.uid,
              }),
            );
          }}
        />
        <TextIconButton
          trigger={mobile}
          className={cn(styles.actionBtn, styles.delete)}
          icon={DeleteIcon}
          title="Delete"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(user.uid);
          }}
        />
        {canEditUser && renderSkipCrmCheckbox(user)}
      </React.Fragment>
    ),
    [mobile, onResetPassword, onDelete, history, renderSkipCrmCheckbox, canEditUser],
  );

  const getCount = React.useCallback(
    (tabId: number) => filteredItems?.filter(filterByRole(tabId)).length,
    [filteredItems],
  );

  const handleUserClick = React.useCallback(
    (user: IUser) => {
      history.push(
        routes.users.getUserUrl({
          flowId: checkCanEditUser(user) ? URL_VARS.EDIT : URL_VARS.VIEW,
          userId: user.uid,
        }),
      );
    },
    [history, checkCanEditUser],
  );

  const filterConfig = React.useMemo(() => {
    return USER_FILTERS.map(({ tabId, label }) => {
      return {
        onClick: () => setTab(tabId),
        isActive: tabId === tab,
        key: label,
        label: `${label} (${getCount(tabId)})`,
      };
    });
  }, [tab, getCount]);

  const handleAddClick = React.useCallback(() => {
    history.push(
      routes.users.getUserUrl({
        flowId: URL_VARS.NEW,
      }),
    );
  }, [history]);

  const renderAddButton = React.useMemo(
    () =>
      canEditUser ? (
        <AddButton assetName={USER_LABEL.toLowerCase()} className={styles.addButton} onClick={handleAddClick} />
      ) : null,
    [canEditUser, handleAddClick],
  );

  React.useEffect(() => {
    if (!setAdditionalData) {
      return;
    }

    setAdditionalData(mobile ? renderAddButton : null);

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [setAdditionalData, mobile, renderAddButton]);

  React.useEffect(() => {
    if (setHeader) {
      setHeader({ title: 'Users', action: () => history.push(routes.dashboard) });
    }
  }, [history, setHeader]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.filter}>
          <Filter config={filterConfig} />
          {/* Dept Filter has to have object */}
          {/* TODO: Change this in the context of EX-1244 task */}
          {canEditUser ? DeptFilter({}) : null}
        </div>
        {!mobile && renderAddButton}
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          {users.map((user: IUser) => {
            const {
              uid,
              first_name,
              last_name,
              email,
              picture,
              used_budget,
              available_budget,
              admin_type,
              department_id,
              integration,
            } = user;
            return (
              <Card
                className={styles.card}
                key={uid}
                onClick={() => handleUserClick(user)}
                top={
                  <React.Fragment>
                    <Avatar className={styles.avatar} image={picture} />
                    <div className={styles.userInfo}>
                      <span className={styles.username}>
                        {first_name} {last_name}
                      </span>
                      <span className={styles.email}>{email}</span>
                    </div>
                    <div className={styles.roleDeptInfo}>
                      <span className={styles.department}>{getDepartmentLabel(department_id)}</span>
                      <span className={styles.role}>{getUserRoleLabel(admin_type)}</span>
                    </div>
                  </React.Fragment>
                }
                middle={
                  <React.Fragment>
                    {renderIntegrations({ integration, uid })}
                    <Budget
                      className={styles.budget}
                      usedBudget={used_budget}
                      availableBudget={available_budget}
                      fixedDecimalScale={false}
                      lowBudgetRate={DIGITAL_BUDGET_WARNING_RATE}
                    />
                  </React.Fragment>
                }
                bottom={checkCanEditUser(user) ? renderControls(user) : renderSkipCrmCheckbox(user)}
                topClassName={styles.top}
                middleClassName={styles.middle}
                bottomClassName={styles.controls}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UsersContainer;
