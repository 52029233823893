import cn from 'classnames';
import * as React from 'react';

import styles from './NavIcon.module.scss';

interface IProps {
  style?: any;
  children?: React.ReactNode;
}

const NavIcon = ({ style, children }: IProps) => (
  <div className={cn(styles.navIcon)} style={style}>
    {children}
  </div>
);

export default NavIcon;
