import { all } from 'redux-saga/effects';

import auth from './auth';
import bucket from './bucket';
import campaigns from './campaigns';
import dashboard from './dashboard';
import engagement from './engagement';
import fulfillmentCenters from './fulfillmentCenters';
import integrations from './integrations';
import inventories from './inventories';
import oneLink from './oneLink';
import organizations from './organizations';
import reasons from './reasons';
import reports from './reports';
import shell from './shell';
import signature from './signature';
import templates from './templates';
import tools from './tools';
import users from './users';

const allSagas: any = {
  ...auth,
  ...dashboard,
  ...integrations,
  ...campaigns,
  ...inventories,
  ...bucket,
  ...reports,
  ...oneLink,
  ...engagement,
  ...templates,
  ...users,
  ...reasons,
  ...organizations,
  ...shell,
  ...tools,
  ...fulfillmentCenters,
  ...signature,
};

export default function* rootSaga() {
  yield all(Object.values(allSagas).map((saga: any) => saga()));
}
