import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TwoFASidebarContainer } from '../../containers';
import { toggleUiElement } from '../../store/actions/shell';
import { selectIs2FADrawerShown } from '../../store/selectors/shell';
import { OneLinkStatusEnum } from '../../types/oneLink';
import { ITwoFAToggleRef } from '../../types/shell';
import { ActionButton } from '../forms';

import styles from './TwoFaSidebarToggle.module.scss';

interface IProps {
  className?: string;
  oneLinkId?: string;
  count?: number;
  disabled?: boolean;
  onClick?: () => void;
  status?: OneLinkStatusEnum;
}

const TwoFaSidebarToggle = React.forwardRef(
  (
    { className, disabled, count = 0, oneLinkId, onClick, status }: IProps,
    ref: React.ForwardedRef<ITwoFAToggleRef>,
  ) => {
    const dispatch = useDispatch();
    const isSidebarOpened = useSelector(selectIs2FADrawerShown);

    const handleToggleSidebar = React.useCallback(
      (flag?: boolean) => {
        dispatch(toggleUiElement({ twoFASidenavIsShown: flag }));
      },
      [dispatch],
    );

    const handleClick = React.useCallback(() => {
      handleToggleSidebar(!isSidebarOpened);
      if (onClick) {
        onClick();
      }
    }, [onClick, handleToggleSidebar, isSidebarOpened]);

    React.useImperativeHandle(ref, () => ({
      open: () => handleToggleSidebar(true),
      close: () => handleToggleSidebar(false),
      toggle: () => handleToggleSidebar(!isSidebarOpened),
      isOpened: isSidebarOpened,
    }));

    return (
      <>
        <ActionButton
          inline
          title={`${count} recipient${count === 1 ? '' : 's'}`}
          disabled={disabled}
          onClick={handleClick}
          className={cn(styles.container, className)}
        />
        <TwoFASidebarContainer status={status} oneLinkId={oneLinkId} />
      </>
    );
  },
);

export default TwoFaSidebarToggle;
