import cn from 'classnames';
import * as React from 'react';
import { animated } from 'react-spring';

import ScaleInOut from '../../animations/ScaleInOut';
import BulkEngDefaultIcon from '../../assets/images/icon-bulk-mock.svg';
import { ReactComponent as MultipleOptionsIcon } from '../../assets/images/icon-msku.svg';
import useWindowSize from '../../hooks/useWindowSize';
import { ICampaign } from '../../types/campaigns';
import { BUTTON_BUTTON } from '../../types/forms';
import { getBoxTotal } from '../../utils/campaigns';
import { hasMskuItem } from '../../utils/inventories';
import { CampaignPreview } from '../index';
import Price from '../Price/Price';

import styles from './BoxItem.module.scss';

export interface IBoxItemProps {
  onClick: (box: ICampaign) => void;
  className?: string;
  iconClassName?: string;
  box: ICampaign;
  onEdit?: () => void;
  onDelete?: () => void;
  onSelect?: () => void;
  selected?: boolean;
  withPreview?: boolean;
  withWarning?: boolean;
  isDisabled?: boolean;
}

const BoxItem: React.FC<IBoxItemProps> = ({
  onClick,
  className,
  iconClassName,
  box,
  onEdit,
  onDelete,
  onSelect,
  selected,
  withPreview,
  withWarning,
  isDisabled = false,
}) => {
  const { mobile } = useWindowSize();

  const total = React.useMemo(() => getBoxTotal(box), [box]);
  const hasMsku = React.useMemo(() => hasMskuItem(box.items), [box.items]);

  const campaignPreviewMarkup = React.useMemo(() => {
    return (
      <CampaignPreview
        showPreview={withPreview}
        showWarning={withWarning}
        campaign={box}
        contentClassName={styles.preview}
        className={styles.hint}
      />
    );
  }, [withPreview, withWarning, box]);

  const handleSelectClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onSelect?.();
    },
    [onSelect],
  );

  const handleBoxClick = React.useCallback(() => {
    if (!isDisabled) {
      onClick(box);
    }
  }, [box, onClick, isDisabled]);

  return (
    <div className={cn(styles.boxItem, { [styles.disabled]: isDisabled }, className)}>
      {!mobile && campaignPreviewMarkup}
      <button type={BUTTON_BUTTON} onClick={handleBoxClick}>
        <div className={cn(styles.iconInner, { [styles.disabled]: isDisabled }, iconClassName)}>
          {mobile && campaignPreviewMarkup}
          {!isDisabled && (
            <ScaleInOut trigger={!!onSelect}>
              {({ style }) => (
                <animated.div
                  style={style}
                  role={BUTTON_BUTTON}
                  className={cn(styles.selectBtn, { [styles.selected]: selected })}
                  onClick={handleSelectClick}
                />
              )}
            </ScaleInOut>
          )}
          <div
            className={styles.icon}
            style={{ backgroundImage: `url(${box.icon_url ? box.icon_url : BulkEngDefaultIcon})` }}
          />
          {hasMsku && <MultipleOptionsIcon className={styles.mskuIcon} />}
        </div>
        <div className={cn(styles.info, { [styles.disabled]: isDisabled })}>
          <span className={styles.title}>{box.name}</span>
          <div className={styles.priceWithControls}>
            <div className={styles.meta}>
              <span className={styles.description}>{box.description}</span>
              <Price value={total} className={styles.price} />
            </div>
            {(onEdit || onDelete) && (
              <div className={styles.controls}>
                {onEdit && (
                  <div
                    role={BUTTON_BUTTON}
                    className={styles.edit}
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit();
                    }}
                  >
                    Edit
                  </div>
                )}
                {onDelete && (
                  <div
                    role={BUTTON_BUTTON}
                    className={styles.delete}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                  >
                    Delete
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </button>
    </div>
  );
};

export default BoxItem;
