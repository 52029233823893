import * as yup from 'yup';

import {
  GenderEnum,
  IInventoryItem,
  IInventoryItemCandidate,
  InventoryFilterTypesEnum,
  InventoryTypesEnum,
} from '../types/inventories';
import { SearchByTypesEnum } from '../types/shell';
import { getMaxErrorMessage, getRequiredFieldErrorMessage } from '../utils/form';
import { isSameFC } from '../utils/inventories';
import { NO_FULFILLMENT_CENTER } from './fulfillmentCenters';
import { NO_DEPARTMENT } from './organizations';
import { URL_VARS } from './routing';

export const MAX_INVENTORY_ITEMS_PER_PAGE = process.env.REACT_APP_MAX_INVENTORY_PER_PAGE
  ? +process.env.REACT_APP_MAX_INVENTORY_PER_PAGE
  : 50;

export const MAX_ITEM_CUSTOMIZATION_TEXT_LABEL_LENGTH = 60;
export const MAX_ITEM_CUSTOMIZATION_DESCRIPTION_LENGTH = 1000;

export const DIGITAL = 'digital';
export const ALL = 'all';
export const PHYSICAL = 'physical';
export const MSKU = 'msku';

export const INVENTORY_ITEM_LABEL = 'Item';

export const ID = 'id';
export const ITEM_ID = 'item_id';
export const NAME = 'name';
export const PRICE = 'price';
export const DIGITAL_CAMPAIGN_KEY = 'digital_campaign_key';
export const DESCRIPTION = 'description';
export const SKU = 'product_sku';
export const TYPE = 'type';
export const IMAGE_URL = 'image_url';
export const COUNTRY_OF_ORIGIN = 'country_of_origin';
export const ORG_ID = 'org_id';
export const DEPARTMENT_IDS = 'department_ids';
export const FULFILLMENT_CENTER_ID = 'fulfillment_center_id';
export const ITEM_CUSTOMIZATION_ALLOWED = 'item_customization_allowed';
export const ITEM_CUSTOMIZATION_REQUIRED = 'item_customization_required';
export const ITEM_CUSTOMIZATION_TEXT_LABEL = 'item_customization_text_label';
export const IS_HIDDEN_FOR_RECIPIENT = 'is_hidden_for_recipient';
export const HTS_CODE = 'hts_code';
export const SIZE = 'size';
export const FLAVOR = 'flavor';
export const OTHER_OPTION = 'other_option';
export const COLOR = 'color';
export const GENDER = 'gender';
export const SKU_OPTIONS = 'sku_options';
export const PREORDER_ALLOWED = 'preorder_allowed';
export const SKU_OPTION_IDS = 'sku_option_ids';
export const ON_DEMAND = 'on_demand';
export const CSV_UPLOAD_SUCCESS = 'csv_upload_success';

export const LOW_INVENTORY_COUNT = 10;

export const UNKNOWN_COUNT_VALUE = -1;
export const NO_COUNT_ERROR = 'There is no inventory in storage.';
export const LOW_COUNT_WARNING = 'There are less than 10 items left in storage.';
export const LOW_COUNT_CAMPAIGN_WARNING =
  'Some inventory may be less than 10 or unavailable in storage and orders can take longer to process.';
export const UNKNOWN_COUNT_WARNING =
  "We cannot get your inventory count right now, either because the item hasn't arrived or there's a connection issue.";
export const MSKU_ITEM_ICON_HINT = 'You can review all the item options here.';
export const CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING =
  'This campaign contains items from different fulfilment centers. Please check the items and make the changes.';
export const CAMPAIGN_HAS_OUT_OF_STOCK_ITEMS_ERROR =
  'One or more items in this campaign have no inventory in storage. Those items will need to be removed or replaced to create a send.';
export const MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING =
  'This item contains options from different fulfillment centers. Please check the options and make the changes.';
export const PHYSICAL_INVENTORY_ITEM_FC_CHANGE_WARNING =
  'This change might affect MSKU and campaigns availability as well as not confirmed sends if any.';

export const HIDDEN_FOR_RECIPIENT_HINT_TEXT =
  'This item will be a surprise for the end recipient and not be shown in any web page or communications to them.';

export const PREORDER_ITEM_HINT_TEXT =
  'Preorder enabled. Orders for this item may be placed when there is no inventory in storage.';

export const CUSTOMIZABLE_ITEM_INDICATOR_HINT_TEXT = 'Customizable Item';

export const PREORDER_HINT_TEXT =
  'Preorder allows orders for an item to be placed even when the inventory in storage is 0.';

export const PREORDER_ITEM_WARNING = 'Please confirm with this client’s brand manager when enabling this setting.';

export const PREORDER_IS_ENABLED_FOR_CAMPAIGN_MESSAGE =
  'The order quantity of these items may exceed the amount of inventory in storage. These items may take longer to process and ship.';

export const MSKU_HAS_ITEM_WITH_NO_PREORDER_MESSAGE =
  'One or more options have no inventory available in storage. You may still send your campaign but those items will unavailable for users to order.';

export const INVENTORY_OUT_OF_STOCK_WARNING =
  'One or more items in this campaign have no inventory in storage. Those items will need to be removed or replaced to create a send.';

export const PHYSICAL_INVENTORY_ITEM_VALIDATION_SCHEMA = yup.object().shape({
  [NAME]: yup
    .string()
    .required(() => getRequiredFieldErrorMessage(NAME))
    .max(60, ({ max }) => getMaxErrorMessage(max))
    .trim(),
  [PRICE]: yup
    .number()
    .required(() => getRequiredFieldErrorMessage(PRICE))
    .moreThan(0, 'Price must be greater than 0.')
    .test('len', 'Price must be at most 6 characters.', (val) => `${val}`.length <= 6),
  [TYPE]: yup.string().oneOf(Object.values(InventoryTypesEnum)).required(),
  [DEPARTMENT_IDS]: yup.array().of(yup.string().required()).required(),
  [IMAGE_URL]: yup.string().required(),
  [COUNTRY_OF_ORIGIN]: yup.string().trim(),
  [PREORDER_ALLOWED]: yup.boolean(),
  [DESCRIPTION]: yup
    .string()
    .max(MAX_ITEM_CUSTOMIZATION_DESCRIPTION_LENGTH, ({ max }) => getMaxErrorMessage(max))
    .trim(),
  [SKU]: yup.string().required('Product SKU is a required field').trim(),
  [FULFILLMENT_CENTER_ID]: yup
    .string()
    .required(() => getRequiredFieldErrorMessage(FULFILLMENT_CENTER_ID))
    .nullable(),
  [SIZE]: yup.string().trim(),
  [FLAVOR]: yup.string().trim(),
  [OTHER_OPTION]: yup.string().trim(),
  [HTS_CODE]: yup.string().trim(),
  [GENDER]: yup.string().oneOf(Object.values(GenderEnum)),
  [ITEM_CUSTOMIZATION_ALLOWED]: yup.boolean(),
  [ITEM_CUSTOMIZATION_REQUIRED]: yup.boolean(),
  [ITEM_CUSTOMIZATION_TEXT_LABEL]: yup
    .string()
    .max(MAX_ITEM_CUSTOMIZATION_TEXT_LABEL_LENGTH, ({ max }) => getMaxErrorMessage(max))
    .trim(),
});

export const DIGITAL_INVENTORY_ITEM_VALIDATION_SCHEMA = yup.object().shape({
  [NAME]: yup
    .string()
    .required(() => getRequiredFieldErrorMessage(NAME))
    .max(60, ({ max }) => getMaxErrorMessage(max))
    .trim(),
  [PRICE]: yup
    .number()
    .required(() => getRequiredFieldErrorMessage(PRICE))
    .moreThan(0, 'Price must be greater than 0.')
    .test('len', 'Price must be at most 6 characters.', (val) => `${val}`.length <= 6),
  [TYPE]: yup.string().oneOf(Object.values(InventoryTypesEnum)).required(),
  [DEPARTMENT_IDS]: yup.array().of(yup.string().required()).required(),
  [IMAGE_URL]: yup.string().required(),
  [DIGITAL_CAMPAIGN_KEY]: yup.string().required().trim(),
  [DESCRIPTION]: yup
    .string()
    .max(MAX_ITEM_CUSTOMIZATION_DESCRIPTION_LENGTH, ({ max }) => getMaxErrorMessage(max))
    .trim(),
  [FULFILLMENT_CENTER_ID]: yup
    .string()
    .required(() => getRequiredFieldErrorMessage(FULFILLMENT_CENTER_ID))
    .nullable(),
});

export const MSKU_INVENTORY_ITEM_VALIDATION_SCHEMA = yup.object().shape(
  {
    [NAME]: yup
      .string()
      .required(() => getRequiredFieldErrorMessage(NAME))
      .max(60, ({ max }) => getMaxErrorMessage(max))
      .trim(),
    [TYPE]: yup.string().oneOf(Object.values(InventoryTypesEnum)).required(),
    [DEPARTMENT_IDS]: yup.array().of(yup.string().required()).required(),
    [IMAGE_URL]: yup.string().required(),
    [DESCRIPTION]: yup
      .string()
      .max(MAX_ITEM_CUSTOMIZATION_DESCRIPTION_LENGTH, ({ max }) => getMaxErrorMessage(max))
      .trim(),
    [FULFILLMENT_CENTER_ID]: yup
      .string()
      .required(() => getRequiredFieldErrorMessage(FULFILLMENT_CENTER_ID))
      .nullable(),
    [SKU_OPTIONS]: yup
      .array<IInventoryItem>()
      .required()
      .when([FULFILLMENT_CENTER_ID], (_, __, context) => {
        const hasItemsFromDifferentFC = !isSameFC(context.parent.sku_options || []);

        if (hasItemsFromDifferentFC) {
          return yup.array().max(0);
        }

        return yup.array().required().of(yup.object<IInventoryItem>().required()).min(2);
      }),
  },
  [[SKU_OPTIONS, FULFILLMENT_CENTER_ID]],
);

export const INITIAL_PHYSICAL_INVENTORY_ITEM_FORM_STATE: Omit<
  IInventoryItemCandidate,
  typeof ID | typeof ITEM_ID | typeof SKU_OPTION_IDS
> = {
  [NAME]: '',
  [PRICE]: 0,
  [TYPE]: InventoryTypesEnum.Physical,
  [ORG_ID]: '',
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
  [IMAGE_URL]: '',
  [COUNTRY_OF_ORIGIN]: '',
  [PREORDER_ALLOWED]: false,
  [DESCRIPTION]: '',
  [SKU]: '',
  [FULFILLMENT_CENTER_ID]: null,
  [FLAVOR]: '',
  [SIZE]: '',
  [HTS_CODE]: '',
  [COLOR]: '',
  [GENDER]: GenderEnum.Empty,
  [OTHER_OPTION]: '',
  [ITEM_CUSTOMIZATION_ALLOWED]: false,
  [ITEM_CUSTOMIZATION_REQUIRED]: false,
  [ITEM_CUSTOMIZATION_TEXT_LABEL]: '',
};

export const INITIAL_DIGITAL_INVENTORY_ITEM_FORM_STATE: Omit<
  IInventoryItemCandidate,
  typeof ID | typeof ITEM_ID | typeof SKU_OPTION_IDS
> = {
  [NAME]: '',
  [PRICE]: 0,
  [TYPE]: InventoryTypesEnum.Digital,
  [ORG_ID]: '',
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
  [IMAGE_URL]: '',
  [DIGITAL_CAMPAIGN_KEY]: '',
  [DESCRIPTION]: '',
  [SKU]: '',
  [FULFILLMENT_CENTER_ID]: NO_FULFILLMENT_CENTER,
};

export const INITIAL_MSKU_INVENTORY_ITEM_FORM_STATE: Omit<IInventoryItemCandidate, typeof ID | typeof ITEM_ID> = {
  [NAME]: '',
  [TYPE]: InventoryTypesEnum.MSKU,
  [ORG_ID]: '',
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
  [IMAGE_URL]: '',
  [DESCRIPTION]: '',
  [FULFILLMENT_CENTER_ID]: NO_FULFILLMENT_CENTER,
  [SKU_OPTIONS]: [],
};

export const INVENTORY_FILTERS = [
  {
    label: 'All',
    filterId: InventoryFilterTypesEnum.All,
  },
  {
    label: 'Physical',
    filterId: InventoryFilterTypesEnum.Physical,
  },
  {
    label: 'Digital',
    filterId: InventoryFilterTypesEnum.Digital,
  },
  {
    label: 'MSKU',
    filterId: InventoryFilterTypesEnum.MSKU,
  },
];

export const GenderLabels = {
  [GenderEnum.Male]: 'Male',
  [GenderEnum.Female]: 'Female',
  [GenderEnum.Unisex]: 'Unisex',
  [GenderEnum.Empty]: '',
};

export const GENDER_SELECT_OPTIONS_LIST = [
  {
    label: GenderLabels[GenderEnum.Male],
    value: GenderEnum.Male,
  },
  {
    label: GenderLabels[GenderEnum.Female],
    value: GenderEnum.Female,
  },
  {
    label: GenderLabels[GenderEnum.Unisex],
    value: GenderEnum.Unisex,
  },
];

export const FILTER_SELECT_DEFAULT_OPTIONS_LIST = [
  {
    label: 'Name',
    value: SearchByTypesEnum.Name,
  },
  {
    label: 'SKU',
    value: SearchByTypesEnum.ProductSKU,
  },
];

export const InventorySuccessToastMessages = {
  [URL_VARS.NEW]: 'New inventory item was added',
  [URL_VARS.EDIT]: 'Inventory item was edited',
  [CSV_UPLOAD_SUCCESS]: 'Inventory items were successfully uploaded',
};

export const NO_SKU_OPTIONS_LABEL_TEXT = 'Available items will appear here after you choose fulfillment center options';
export const NO_LOW_INVENTORY_ITEMS_MESSAGE = 'There is no inventory running low at the moment.';
