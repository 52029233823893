import cn from 'classnames';
import * as React from 'react';
import { FC } from 'react';

import Price from '../Price/Price';

import styles from './Budget.module.scss';

interface IProps {
  className?: string;
  availableBudget: number;
  usedBudget: number;
  fixedDecimalScale?: boolean;
  remainingBudgetHint?: string;
  availableBudgetHint?: string;
  lowBudgetRate?: number;
}

const Budget: FC<IProps> = ({
  className,
  availableBudget,
  usedBudget,
  fixedDecimalScale,
  remainingBudgetHint,
  availableBudgetHint,
  lowBudgetRate,
}) => {
  const remainingBudget = availableBudget - usedBudget < 0 ? 0 : Math.floor(availableBudget - usedBudget);

  return (
    <div className={cn(styles.container, className)}>
      <Price
        value={remainingBudget}
        thousandSeparator
        fixedDecimalScale={fixedDecimalScale}
        className={cn(styles.budgetLeft, {
          [styles.budgetLow]: lowBudgetRate && remainingBudget <= lowBudgetRate,
          [styles.outOfBudget]: remainingBudget < 1,
        })}
        hint={remainingBudgetHint}
      />
      <span className={styles.budgetDelimiter}>/</span>
      <Price
        value={Math.floor(availableBudget)}
        thousandSeparator
        fixedDecimalScale={fixedDecimalScale}
        className={styles.budgetAvailable}
        hint={availableBudgetHint}
      />
    </div>
  );
};

export default Budget;
