// @ts-ignore
import addressParser from 'parse-address-string';

import {
  AWAITING_ADDRESS,
  RECEIVER_ADDRESS1,
  RECEIVER_CITY,
  RECEIVER_COUNTRY,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIP_ORDER_STATUS,
} from '../constants/shipping';
import { ShippingAddressCandidate } from '../types/address';
import { IAddressParsed, ISearchItem } from '../types/crm';

export const transformSearchItemToShippingAddress = async (
  { MailingAddress, Email, Address, FirstName, LastName, Phone, Id, attributes: { type } }: ISearchItem,
  { isPYG }: { isPYG: boolean },
) => {
  const { city, country, postalCode, state, street } = MailingAddress || Address || {};

  const parsedAddress =
    !(city || country || postalCode || state) && street
      ? await parseAddress(street).catch((e) => console.warn('Address is not parsed', e))
      : { city, country, postalCode, state, street };

  return new ShippingAddressCandidate({
    [RECEIVER_FIRST_NAME]: FirstName,
    [RECEIVER_LAST_NAME]: LastName,
    [RECEIVER_PHONE]: Phone,
    [RECEIVER_CRM_RECORD_ID]: Id,
    [RECEIVER_CRM_RECORD_TYPE]: type,
    [RECEIVER_EMAIL]: Email,
    [RECEIVER_COUNTRY]: parsedAddress?.country || '',
    [RECEIVER_STATE]: parsedAddress?.state || '',
    [RECEIVER_CITY]: parsedAddress?.city || '',
    [RECEIVER_ZIP]: parsedAddress?.postalCode || '',
    [RECEIVER_ADDRESS1]: parsedAddress?.street || '',
    ...(isPYG ? { [SHIP_ORDER_STATUS]: AWAITING_ADDRESS } : {}),
  });
};

export const getSearchItemAddressDescription = ({ MailingAddress, Address }: ISearchItem) => {
  const { city, country, postalCode, state, street } = MailingAddress || Address || {};
  return `
    ${street ? `${street}` : ''}
    ${city ? ` ${city} ` : ''}
    ${state ? ` ${state} ` : ''}
    ${postalCode ? ` ${postalCode} ` : ''}
    ${country ? ` ${country}` : ''}
  `;
};

export const parseAddress = (addressString: string) =>
  new Promise<IAddressParsed>((resolve, reject) => {
    if (!addressString) {
      reject(new Error('No address string specified'));
    }

    try {
      addressParser(addressString, (error: any, result: IAddressParsed) => {
        if (error) {
          reject(error);
        }
        if (result.street_address1) {
          result.street = result.street_address1;
          delete result.street_address1;
        }
        if (result.postal_code) {
          result.postalCode = result.postal_code;
          delete result.postal_code;
        }
        resolve(result);
      });
    } catch (e) {
      reject(new Error('Cannot parse address string'));
    }
  });
