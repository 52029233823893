import { createSelector } from 'reselect';

import {
  CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  DISABLE_SHIPMENT_TRACKING,
  INTERNAL_NOTES,
  IS_TOGGLE_ON_HOLD_ENABLED,
  PRE_CREATED_ENGAGEMENT_ID,
  RECEIVER_REMINDER_INTERVAL,
  SEND_ON_BEHALF_THE_EMAIL,
  SEND_ON_HOLD,
} from '../../constants/bucket';
import { BUSINESS_REASON_REQUIRED, DISABLE_HANDWRITTEN_NOTE_STEP } from '../../constants/campaigns';
import { OFF } from '../../constants/integrations';
import { BUSINESS_REASON_ID } from '../../constants/reasons';
import { URL_VARS } from '../../constants/routing';
import {
  ALLOWED_DOMAIN,
  DIGITAL_SHIPPING_VALIDATION_SCHEMA,
  DIGITAL_SHIPPING_VALIDATION_SCHEMA_NO_CRM,
  ENABLED_2FA,
  INITIAL_DIGITAL_SHIPPING_FORM_STATE,
  INITIAL_DIGITAL_SHIPPING_FORM_STATE_NO_CRM,
  INITIAL_ONE_LINK_DIGITAL_SHIPPING_FORM_STATE,
  INITIAL_ONE_LINK_PHYSICAL_SHIPPING_FORM_STATE,
  INITIAL_SHIPPING_FORM_STATE,
  INITIAL_SHIPPING_FORM_STATE_NO_CRM,
  ONE_LINK_DIGITAL_SHIPPING_VALIDATION_SCHEMA,
  ONE_LINK_PHYSICAL_SHIPPING_VALIDATION_SCHEMA,
  PASSWORD,
  RECEIVER_CRM_RECORD_ID,
  RECIPIENTS,
  SENDER_COMPANY_NAME,
  SHIPPING_OPTION,
  SHIPPING_VALIDATION_SCHEMA,
  SHIPPING_VALIDATION_SCHEMA_NO_CRM,
  SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA,
  SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA_NO_CRM,
} from '../../constants/shipping';
import { SIGNATURE } from '../../constants/signature';
import {
  DIGITAL_GIFT_NAMES,
  EMAIL_MESSAGE,
  EMAIL_SUBJECT,
  EMAIL_TEMPLATE_ID,
  MESSAGE,
  NOTE_TEMPLATE_ID,
  RECEIVER_EMAIL,
} from '../../constants/templates';
import { ShippingDigitalAddressCandidate } from '../../types/address';
import { IOneLinkEngagementCandidate, TEngagementCandidate } from '../../types/bucket';
import { ICampaign, ICampaignItem } from '../../types/campaigns';
import { IInventoryWithType, IOnDemandItem } from '../../types/inventories';
import { EngagementReminderIntervalsEnum, EngagementSourceTypesEnum } from '../../types/reports';
import { isItemDigital } from '../../utils/inventories';
import { getSenderInfoObject } from '../../utils/users';
import { IAppState } from '../reducers';
import { selectIsCrmSkipAllowedToUser, selectUserEmail, selectUserInfo } from './auth';
import { selectFulfillmentCenterItems } from './fulfillmentCenters';
import {
  selectCurrentOrganizationId,
  selectIsCurrentOrganizationSupportedAnyCrm,
  selectIsSkipCrmAllowedInOrg,
} from './organizations';
import { selectIsDelayedShipping } from './shipping';
import { selectCurrentSignature } from './signature';
import { selectUsers } from './users';

export const selectRootState = (state: IAppState) => state.bucket;

export const selectBucketItems = createSelector(selectRootState, (bucket) => (bucket ? bucket.items : undefined));

export const selectCurrentFlow = createSelector(selectRootState, (bucket) => (bucket ? bucket.flow : undefined));

export const selectShouldStartFromScratch = (flowId: string | undefined) =>
  createSelector(selectCurrentFlow, (flow) => flow !== flowId);

export const selectIsOneLinkFlow = createSelector(selectCurrentFlow, (flowId) => flowId === URL_VARS.ONE_LINK);

export const selectIsDirectSendFlow = createSelector(selectCurrentFlow, (flowId) => flowId === URL_VARS.DIRECT_SEND);

export const selectPYGCampaignsList = createSelector(selectRootState, (bucket) =>
  bucket ? bucket.pick_campaigns : undefined,
);

export const selectPYGCampaignsIDsList = createSelector(selectPYGCampaignsList, (campaignsList) =>
  campaignsList ? campaignsList.map(({ box_id }) => box_id) : undefined,
);

export const selectIsPYGEnabled = createSelector(selectPYGCampaignsList, (ids) => Array.isArray(ids));

export const selectIsPYGCampaignsDigital = createSelector(selectPYGCampaignsList, (campaignsList) =>
  campaignsList?.every((campaign) => campaign.items.every(isItemDigital)),
);

export const selectPreCreatedBoxId = createSelector(selectRootState, (bucket) =>
  bucket ? bucket.pre_created_engagement_id : undefined,
);

export const selectIsDisabledNotecard = createSelector(selectRootState, (bucket) =>
  bucket ? bucket[DISABLE_HANDWRITTEN_NOTE_STEP] : false,
);

export const selectSendOnHold = createSelector(selectRootState, (bucket) =>
  bucket && bucket[SEND_ON_HOLD] ? bucket[SEND_ON_HOLD] : '',
);

export const selectIsToggleOnHoldEnabled = createSelector(selectRootState, (bucket) => {
  return bucket[IS_TOGGLE_ON_HOLD_ENABLED];
});

export const selectDelayedShippingExpirationDate = createSelector(selectRootState, (bucket) => {
  return bucket.delayed_shipping_expiration;
});

export const selectShippingInformation = createSelector(selectRootState, (bucket) =>
  bucket ? bucket.shipping : undefined,
);

export const selectShippingOption = createSelector(selectShippingInformation, (shippingInfo) =>
  shippingInfo ? shippingInfo[SHIPPING_OPTION] : undefined,
);

export const selectNoteInformation = createSelector(selectRootState, (bucket) => (bucket ? bucket.note : undefined));

export const selectPasswordInformation = createSelector(selectRootState, (bucket) =>
  bucket ? bucket[PASSWORD] : undefined,
);

export const selectAllowedDomain = createSelector(selectRootState, (bucket) =>
  bucket ? bucket[ALLOWED_DOMAIN] : undefined,
);

export const select2FAEnabled = createSelector(selectRootState, (bucket) => bucket[ENABLED_2FA]);

export const select2FARecipientsList = createSelector(selectRootState, (bucket) =>
  bucket ? bucket[RECIPIENTS] : undefined,
);

export const selectEmailInformation = createSelector(selectRootState, (bucket) => (bucket ? bucket.email : undefined));

export const selectSelectedTemplate = createSelector(selectNoteInformation, (note) => note?.note_template_id);

export const selectEmailSelectedTemplate = createSelector(selectEmailInformation, (email) => email?.email_template_id);

export const selectIsNoteSkipped = createSelector(selectRootState, (bucket) => bucket.isNoteSkipped);

export const selectUserSenderInformation = createSelector(selectUserInfo, (userInfo) =>
  getSenderInfoObject(userInfo?.sender),
);

export const selectSOBEmail = createSelector(selectRootState, (bucket) =>
  bucket ? bucket[SEND_ON_BEHALF_THE_EMAIL] : undefined,
);

export const selectIsSOBEnabled = createSelector(selectUserEmail, selectSOBEmail, (userEmail, SOBEmail) => {
  if (!userEmail || !SOBEmail) {
    return false;
  }

  return userEmail !== SOBEmail;
});

export const selectSOBUser = createSelector(
  selectUsers,
  selectSOBEmail,
  selectIsSOBEnabled,
  (users, SOBEmail, isSOBEnabled) => {
    if (!isSOBEnabled || !SOBEmail || !users) {
      return null;
    }

    return users.find(({ email }) => email === SOBEmail);
  },
);

export const selectSOBUserRemainingBudget = createSelector(selectSOBUser, (user) =>
  user ? Number(user.available_budget) - Number(user.used_budget) : null,
);

export const selectIsSOBUserEmailIntegrationDisabled = createSelector(selectSOBUser, (user) =>
  user ? user?.integration.email_integration === OFF : undefined,
);

export const selectSenderInformation = createSelector(
  selectUserSenderInformation,
  selectSOBEmail,
  selectUsers,
  (userSenderInfo, SOBEmail, users) => {
    if (SOBEmail) {
      return users ? getSenderInfoObject(users.find(({ email }) => email === SOBEmail)) : undefined;
    }
    return userSenderInfo;
  },
);

export const selectBucketIsFetching = createSelector(selectRootState, (bucket) => (bucket ? bucket.isFetching : false));

export const selectBucketError = createSelector(selectRootState, (bucket) => (bucket ? bucket.error : undefined));

export const selectCRMRecordId = createSelector(selectShippingInformation, (shippingInfo) =>
  shippingInfo ? shippingInfo[RECEIVER_CRM_RECORD_ID] : null,
);

export const selectIsDigitalBucket = createSelector(selectBucketItems, (items: IInventoryWithType[] | undefined) =>
  items && items.length ? items.every(isItemDigital) : false,
);

export const selectIsBusinessReasonRequired = createSelector(selectRootState, (bucket) =>
  bucket[PRE_CREATED_ENGAGEMENT_ID] !== CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID
    ? bucket[BUSINESS_REASON_REQUIRED]
    : false,
);

export const selectIsShippingOptionEnabled = createSelector(
  selectBucketItems,
  selectPYGCampaignsList,
  selectFulfillmentCenterItems,
  (
    bucketItems: (ICampaignItem | IOnDemandItem)[] | undefined,
    pickCampaigns: ICampaign[] | null | undefined,
    fcItems,
  ) =>
    !!(
      bucketItems &&
      bucketItems.length &&
      fcItems?.find((fcItem) => fcItem.uid === bucketItems[0].fulfillment_center_id)?.is_shipping_option_supported
    ) ||
    !!(
      pickCampaigns &&
      pickCampaigns.length &&
      pickCampaigns
        .map((campaign) => campaign.items)
        .flat()
        .some(
          (item) => fcItems?.find((fcItem) => fcItem.uid === item.fulfillment_center_id)?.is_shipping_option_supported,
        )
    ),
);

export const selectOnlyDigitalItems = createSelector(
  selectBucketItems,
  (items: (ICampaignItem | IOnDemandItem)[] | undefined) => (items && items.length ? items?.filter(isItemDigital) : []),
);

export const selectOnlyDigitalItemsNames = createSelector(
  selectOnlyDigitalItems,
  (items: (ICampaignItem | IOnDemandItem)[] | undefined) =>
    items && items.length ? items?.map((item) => item.name) : [],
);

export const selectCampaignBranding = createSelector(selectRootState, (bucket) => bucket.campaignBranding);

export const selectTemplateValues = createSelector(
  selectShippingInformation,
  selectSenderInformation,
  selectOnlyDigitalItemsNames,
  selectCurrentSignature,
  selectSOBUser,
  selectCampaignBranding,
  (shipping, sender, digitalNames, signature, SOBUser, campaignBranding) => ({
    ...(sender ? sender : {}),
    ...(shipping ? shipping : {}),
    [DIGITAL_GIFT_NAMES]: digitalNames,
    [SIGNATURE]: SOBUser ? SOBUser[SIGNATURE] : signature,
    ...(campaignBranding ? { [SENDER_COMPANY_NAME]: campaignBranding.ds_company_name } : {}),
  }),
);

export const selectIsSkipCrmEnabledForCurrentUserInOrg = createSelector(
  selectIsCrmSkipAllowedToUser,
  selectIsSkipCrmAllowedInOrg,
  (isCrmSkippedForUser, isSkipCrmEnabledInOrg) => {
    return isCrmSkippedForUser && isSkipCrmEnabledInOrg;
  },
);

export const selectRecipientValidationSchema = createSelector(
  selectIsCurrentOrganizationSupportedAnyCrm,
  selectIsDigitalBucket,
  selectIsDelayedShipping,
  selectIsSkipCrmEnabledForCurrentUserInOrg,
  selectIsDirectSendFlow,
  (isSupportedCRM, isDigital, isDelayed, isCRMSkipAllowedForUser, isDirectFlow) => {
    const withCRM = isSupportedCRM && !(isDirectFlow || isCRMSkipAllowedForUser);

    if (isDigital) {
      return withCRM ? DIGITAL_SHIPPING_VALIDATION_SCHEMA : DIGITAL_SHIPPING_VALIDATION_SCHEMA_NO_CRM;
    }
    if (isDelayed) {
      return withCRM ? SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA : SIMPLIFIED_SHIPPING_VALIDATION_SCHEMA_NO_CRM;
    }
    return withCRM ? SHIPPING_VALIDATION_SCHEMA : SHIPPING_VALIDATION_SCHEMA_NO_CRM;
  },
);

export const selectShippingInitialValues = createSelector(
  selectShippingInformation,
  selectIsDigitalBucket,
  selectIsCurrentOrganizationSupportedAnyCrm,
  (shippingInfo, isDigital, isSupportedCRM) => {
    const getFormSchema = isDigital
      ? () => (isSupportedCRM ? INITIAL_DIGITAL_SHIPPING_FORM_STATE : INITIAL_DIGITAL_SHIPPING_FORM_STATE_NO_CRM)
      : () => (isSupportedCRM ? INITIAL_SHIPPING_FORM_STATE : INITIAL_SHIPPING_FORM_STATE_NO_CRM);

    return { ...getFormSchema(), ...shippingInfo };
  },
);

export const selectOneLinkShippingData = createSelector(selectRootState, (bucket) => {
  const {
    expiration_date,
    shipping,
    disable_sender_email_notifications,
    receiver_reminder_interval,
    password,
    allowed_domain,
    max_submit_count,
  } = bucket || {};
  return {
    password,
    allowed_domain,
    expiration_date,
    disable_sender_email_notifications,
    receiver_reminder_interval,
    max_submit_count,
    ...(shipping
      ? {
          shipping: {
            shipping_option: shipping?.shipping_option,
          },
        }
      : {}),
  };
});

export const selectOneLinkShippingInitialValues = createSelector(
  selectOneLinkShippingData,
  selectIsDigitalBucket,
  (oneLinkShippingData, isDigital) => ({
    ...(isDigital ? INITIAL_ONE_LINK_DIGITAL_SHIPPING_FORM_STATE : INITIAL_ONE_LINK_PHYSICAL_SHIPPING_FORM_STATE),
    ...oneLinkShippingData,
  }),
);

export const selectOneLinkValidationSchema = createSelector(selectIsDigitalBucket, (isDigital) =>
  isDigital ? ONE_LINK_DIGITAL_SHIPPING_VALIDATION_SCHEMA : ONE_LINK_PHYSICAL_SHIPPING_VALIDATION_SCHEMA,
);

export const selectBucketBulk = createSelector(selectRootState, (bucket) => (bucket ? bucket.bulk : undefined));

export const selectIsBulkSubmitted = createSelector(selectBucketBulk, (bulk) => (bulk ? bulk.isSubmitted : false));

export const selectUploadedCSV = createSelector(selectBucketBulk, (bulk) => (bulk ? bulk.csv : null));

export const selectIsDisabledBulkSenderNotifications = createSelector(selectBucketBulk, (bulk) =>
  bulk ? bulk[DISABLE_SENDER_EMAIL_NOTIFICATIONS] : false,
);

export const selectIsDisabledBulkShipmentTracking = createSelector(selectBucketBulk, (bulk) =>
  bulk ? bulk[DISABLE_SHIPMENT_TRACKING] : false,
);

export const selectIsDisabledSenderNotifications = createSelector(selectRootState, (bucket) =>
  bucket ? bucket[DISABLE_SENDER_EMAIL_NOTIFICATIONS] : false,
);

export const selectReminderInterval = createSelector(
  selectRootState,
  (bucket) => bucket && bucket[RECEIVER_REMINDER_INTERVAL],
);

export const selectBusinessReasonId = createSelector(selectRootState, (bucket) =>
  bucket && bucket[BUSINESS_REASON_ID] ? bucket[BUSINESS_REASON_ID] : '',
);

export const selectBulkResult = createSelector(selectBucketBulk, (bulk) => (bulk ? bulk.result : null));

export const selectBulkData = createSelector(
  selectUploadedCSV,
  selectPreCreatedBoxId,
  selectBucketItems,
  selectCurrentOrganizationId,
  selectIsDisabledBulkSenderNotifications,
  selectIsDisabledBulkShipmentTracking,
  selectSendOnHold,
  selectNoteInformation,
  selectEmailInformation,
  selectPYGCampaignsIDsList,
  selectShippingOption,
  selectReminderInterval,
  selectBusinessReasonId,
  selectDelayedShippingExpirationDate,
  (
    // tslint:disable:variable-name
    csv,
    pre_created_engagement_id,
    items,
    org_id,
    disable_sender_email_notifications,
    disable_shipment_tracking,
    on_hold_until,
    note,
    email,
    pick_campaign_ids,
    shipping_option,
    receiver_reminder_interval,
    business_reason_id,
    delayed_shipping_expiration,
  ) => ({
    ...(csv ? { file_name: csv.name } : {}),
    pre_created_engagement_id,
    items,
    org_id,
    business_reason_id,
    disable_sender_email_notifications,
    disable_shipment_tracking,
    on_hold_until,
    shipping_option,
    ...(delayed_shipping_expiration ? { delayed_shipping_expiration } : {}),
    ...(note ? { note_template: note[MESSAGE] } : {}),
    ...(email
      ? {
          email_message: email[EMAIL_MESSAGE],
          email_subject: email[EMAIL_SUBJECT],
          email_template_id: email[EMAIL_TEMPLATE_ID],
        }
      : {}),
    ...(pick_campaign_ids ? { pick_campaign_ids } : {}),
    ...(!receiver_reminder_interval || receiver_reminder_interval === EngagementReminderIntervalsEnum.Custom
      ? {}
      : { receiver_reminder_interval }),
  }),
);
// tslint:enable:variable-name

export const selectIsBulkLoading = createSelector(selectBucketBulk, (bulk) => (bulk ? bulk.isLoading : false));

export const selectIsBoxStepValid = (isBulk?: boolean) =>
  createSelector(
    selectIsPYGEnabled,
    selectPreCreatedBoxId,
    selectPYGCampaignsIDsList,
    selectBucketItems,
    (isPyg, boxId, PYGCampaignIDs, bucketItems) => {
      const isValidSingle = Boolean(isPyg ? PYGCampaignIDs?.length : bucketItems?.length);
      const isValidBulk = !!(isPyg ? PYGCampaignIDs?.length : boxId);

      return isBulk ? isValidSingle && isValidBulk : isValidSingle;
    },
  );

export const selectIsShippingStepValid = () =>
  createSelector(
    selectShippingInformation,
    selectRecipientValidationSchema,
    selectIsOneLinkFlow,
    selectOneLinkShippingData,
    selectOneLinkValidationSchema,
    (shippingInfo, recipientValidationSchema, isOneLink, oneLinkShippingData, oneLinkValidationSchema) => {
      if (isOneLink) {
        return Boolean(oneLinkShippingData && oneLinkValidationSchema.isValidSync(oneLinkShippingData));
      }

      return Boolean(shippingInfo && recipientValidationSchema.isValidSync(shippingInfo));
    },
  );

export const selectIsNotesStepValid = () =>
  createSelector(selectNoteInformation, (note) => {
    const isNoteSkipped = note ? note[NOTE_TEMPLATE_ID] === null : false;
    return isNoteSkipped || (note ? !!(note[NOTE_TEMPLATE_ID] && note[MESSAGE]) : false);
  });

export const selectIsEmailStepValid = (isBulk: boolean) =>
  createSelector(selectEmailInformation, selectIsOneLinkFlow, (email, isOneLinkFlow) => {
    const isEmailSkipped = email ? email[EMAIL_TEMPLATE_ID] === null : false;

    const validSingle =
      isEmailSkipped ||
      (email
        ? !!(email[EMAIL_TEMPLATE_ID] && email[EMAIL_MESSAGE] && email[EMAIL_SUBJECT] && email[RECEIVER_EMAIL])
        : false);
    const validBulk =
      isEmailSkipped || (email ? !!(email[EMAIL_TEMPLATE_ID] && email[EMAIL_MESSAGE] && email[EMAIL_SUBJECT]) : false);

    // Validation conditions for Bulk and OneLink flows are the same
    return isBulk || isOneLinkFlow ? validBulk : validSingle;
  });

export const selectIsUploadStepValid = () => createSelector(selectUploadedCSV, Boolean);

export const selectInternalNotes = createSelector(selectRootState, (bucket) =>
  bucket && bucket[INTERNAL_NOTES] ? bucket[INTERNAL_NOTES] : '',
);

export const selectOneLinkExpirationDate = createSelector(
  selectOneLinkShippingData,
  (oneLinkShippingData) => oneLinkShippingData.expiration_date,
);

export const selectEngagementCandidate = createSelector(
  selectIsOneLinkFlow,
  selectIsDigitalBucket,
  selectIsDirectSendFlow,
  selectShippingInformation,
  selectOneLinkShippingData,
  selectNoteInformation,
  selectPasswordInformation,
  selectEmailInformation,
  selectBucketItems,
  selectPreCreatedBoxId,
  selectInternalNotes,
  selectBusinessReasonId,
  selectSendOnHold,
  selectSOBEmail,
  selectPYGCampaignsList,
  selectReminderInterval,
  select2FARecipientsList,
  select2FAEnabled,
  selectDelayedShippingExpirationDate,
  (
    isOneLink,
    isDigital,
    isDirectSend,
    singleFlowShippingData,
    oneLinkFlowShippingData,
    noteInformation,
    password,
    emailInformation,
    items,
    // tslint:disable:variable-name
    pre_created_engagement_id,
    internal_notes,
    business_reason_id,
    on_hold_until,
    send_on_behalf_the_email,
    pick_campaigns,
    receiver_reminder_interval,
    recipients,
    enabled_2fa,
    delayed_shipping_expiration,
  ): // tslint:enable:variable-name
  TEngagementCandidate | IOneLinkEngagementCandidate => {
    const shippingInfo = (() => {
      if (isOneLink) {
        const { shipping, expiration_date, ...rest } = oneLinkFlowShippingData;
        return {
          ...rest,
          ...(expiration_date ? { expiration_date } : {}),
          ...(isDigital ? {} : shipping),
          ...(enabled_2fa ? { recipients: recipients?.successfulRecipients } : {}),
          enabled_2fa,
        };
      }

      if (isDigital && singleFlowShippingData) {
        return new ShippingDigitalAddressCandidate(singleFlowShippingData);
      }

      return singleFlowShippingData;
    })();

    return {
      ...(shippingInfo ? shippingInfo : {}),
      ...(isDirectSend ? { engagement_source_type: EngagementSourceTypesEnum.DirectSend } : {}),
      ...(noteInformation ? noteInformation : {}),
      ...(emailInformation?.email_template_id ? emailInformation : {}),
      items,
      pre_created_engagement_id,
      internal_notes,
      business_reason_id,
      on_hold_until,
      delayed_shipping_expiration,
      pick_campaigns,
      ...(!receiver_reminder_interval || receiver_reminder_interval === EngagementReminderIntervalsEnum.Custom
        ? {}
        : { receiver_reminder_interval }),
      ...(send_on_behalf_the_email ? { send_on_behalf_the_email } : {}),
      ...(pick_campaigns ? { pick_campaigns } : {}),
      ...(password ? { password } : {}),
    };
  },
);

export const selectFlowValidation = (isBulk: boolean) =>
  createSelector(
    [
      selectIsBoxStepValid(isBulk),
      selectIsShippingStepValid(),
      selectIsNotesStepValid(),
      selectIsEmailStepValid(isBulk),
      selectIsUploadStepValid(),
    ],
    (box, shipping, note, email, upload) => ({
      box,
      shipping,
      note,
      email,
      upload,
      summary: box && shipping && note && email,
    }),
  );

export const selectIsReceiverReminderSettingEnabled = createSelector(
  [selectIsDelayedShipping, selectIsDigitalBucket],
  (isDelayedShipping, isDigital) => {
    return isDelayedShipping || isDigital;
  },
);
