import cn from 'classnames';
import * as React from 'react';

import { Price } from '../index';

import styles from './UserBudget.module.scss';

interface IProps {
  className?: string;
  availableBudget: number;
  usedBudget: number;
  hideAvailableBudget?: boolean;
}

const UserBudget = ({ className, availableBudget, usedBudget, hideAvailableBudget = false }: IProps) => {
  return (
    <div className={cn(className, styles.userBudget)}>
      <Price
        value={usedBudget ? availableBudget - usedBudget : 0}
        hint="Remaining Balance"
        className={styles.current}
      />
      {!hideAvailableBudget ? (
        <React.Fragment>
          <span className={styles.available}> / </span>
          <Price value={availableBudget} hint="Total Balance" className={styles.available} thousandSeparator />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default UserBudget;
