import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import IERectLogo from '../../../assets/images/ie_rect_logo.svg';

import { routes } from '../../../constants/routing';
import { useTheme } from '../../../contexts/ThemeProvider';
import { BUTTON_BUTTON } from '../../../types/forms';

import styles from './NavHeader.module.scss';

interface IProps {
  expanded: boolean;
}

const NavHeader = ({ expanded }: IProps) => {
  const history = useHistory();

  const theme = useTheme();

  const navHeaderClasses = cn(styles.navHeader, expanded ? styles.expanded : styles.collapsed);

  const handleOnClick = React.useCallback(() => history.push(routes.dashboard), [history]);

  const poweredByLogoMarkup = React.useMemo(() => {
    return (
      <div className={styles.poweredByContainer}>
        <span className={styles.text}>Powered by</span>
        <img src={IERectLogo} className={styles.image} alt="Powered by imprintengine.com" />
      </div>
    );
  }, []);

  return (
    <div className={navHeaderClasses}>
      <div className={styles.dummy} />
      <button type={BUTTON_BUTTON} className={styles.logo} onClick={handleOnClick}>
        {expanded ? (
          <div className={styles.expandedLogo}>{theme?.org_rect_logo && poweredByLogoMarkup}</div>
        ) : (
          <div className={styles.collapsedLogo} />
        )}
      </button>
    </div>
  );
};

export default NavHeader;
