import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as IconKeyboardDown } from '../../assets/images/keyboard_arrow_down.svg';
import { additionMenuItems, mainMenuItems } from '../../constants/nav';
import useWindowSize from '../../hooks/useWindowSize';
import { toggleUiElement } from '../../store/actions/shell';
import { selectAdminType, selectIsAuthenticated } from '../../store/selectors/auth';
import { selectIsSidenavShown } from '../../store/selectors/shell';
import { TSidenavItem } from '../../types/sidenav';
import { hasPermission } from '../../utils/users';
import DesktopSidenav from './DesktopSidenav/DesktopSidenav';
import MobileSidenav from './MobileSidenav/MobileSidenav';
import NavIcon from './NavIcon/NavIcon';
import NavItem from './NavItem/NavItem';
import NavText from './NavText/NavText';
import NonAuthSidenav from './NonAuthSidenav/NonAuthSidenav';

import styles from './Sidenav.module.scss';

const Sidenav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = React.useState('');
  const [wasExpanded, setWasExpanded] = React.useState(false);
  const { width, mobile, HD, fullHD } = useWindowSize();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const adminType = useSelector(selectAdminType);
  const sidenavIsShown = useSelector(selectIsSidenavShown);

  const setExpandedSideNav = React.useCallback(
    (value?: boolean) =>
      dispatch(toggleUiElement({ sidenavIsShown: typeof value !== 'undefined' ? value : !sidenavIsShown })),
    [dispatch, sidenavIsShown],
  );

  // Set initial values of the selectedItem
  React.useEffect(() => {
    if (isAuthenticated) {
      const getInitialSelectedItem = (menuItems: TSidenavItem[]): TSidenavItem | undefined => {
        return menuItems.find((item: TSidenavItem) => {
          if (item.subItems) {
            return getInitialSelectedItem(item.subItems);
          }
          return item.route ? location.pathname.includes(item.route) : undefined;
        });
      };

      const initialItem = getInitialSelectedItem([...mainMenuItems, ...additionMenuItems]);
      if (initialItem) {
        setSelectedItem(initialItem.title);
      }
    } else {
      setSelectedItem('');
    }
  }, [isAuthenticated, location]);

  React.useEffect(() => {
    if (!width) {
      return;
    }

    // If it's undefined - so it was never set or persisted, therefore this is a new user.
    // If such user has a fullHD screen - we should show sidenav opened initially.
    // Otherwise - show it closed
    if (typeof sidenavIsShown === 'undefined') {
      setExpandedSideNav(fullHD);
      return;
    }
  }, [width, setExpandedSideNav, fullHD]);

  const toggle = React.useCallback(() => {
    setExpandedSideNav(!sidenavIsShown);
  }, [sidenavIsShown, setExpandedSideNav]);

  const filterByDeviceType = React.useCallback(
    (item: TSidenavItem) => (mobile && item.usedInMobile) || (!mobile && item.usedInDesktop),
    [mobile],
  );

  const filterByRole = React.useCallback((item: TSidenavItem) => hasPermission(item.roles, adminType), [adminType]);

  const handleExpandedLogicWithSubItems = React.useCallback(
    ({ subItems, isSubItem }: TSidenavItem) => {
      /* if the user wants to choose Campaigns Editor from the minimized bar,
       * he should click on the ‘campaigns manager’ icon and the bar will become wider with text descriptions.
       * Once the user picked the sub page, the bar will become minimized again.
       */
      if (subItems && !sidenavIsShown) {
        toggle();
        setWasExpanded(true);
      } else if (wasExpanded && isSubItem) {
        toggle();
        setWasExpanded(false);
      } else {
        setWasExpanded(false);
      }
    },
    [toggle, sidenavIsShown, wasExpanded],
  );

  const handleMenuItemClick = React.useCallback(
    (menuItem: TSidenavItem) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      const { title, subItems } = menuItem;

      if (mobile && !subItems) {
        toggle();
      }

      // Disabling NavLink redirect for nav item with the sub items
      if (subItems) {
        event.preventDefault();
        handleExpandedLogicWithSubItems(menuItem);
      }

      setSelectedItem(title === selectedItem ? '' : title);
    },
    [mobile, HD, toggle, handleExpandedLogicWithSubItems, selectedItem],
  );

  const getMenuItems = React.useCallback(
    (items: TSidenavItem[]) => {
      return items
        .filter(filterByDeviceType)
        .filter(filterByRole)
        .map((menuItem: TSidenavItem) => {
          const { icon: IconComponent, title, route, subItems } = menuItem;

          // Element will be expanded if it or its children were selected earlier
          const isExpanded =
            title === selectedItem || subItems?.some((item: TSidenavItem) => item.title === selectedItem);

          return (
            <React.Fragment key={title}>
              <NavItem>
                <NavLink
                  isActive={(match, currentLocation) => {
                    if (!match) {
                      return false;
                    }

                    // If there is no route, so this element has subItems
                    // Сompare location path with children routes for the highlighting the parent element
                    return !!(
                      route || subItems?.some((el: TSidenavItem) => currentLocation.pathname?.includes(el.route || ''))
                    );
                  }}
                  className={cn(styles.menuItem, { [styles.expanded]: isExpanded && !!subItems })}
                  activeClassName={styles.activated}
                  to={route || ''}
                  onClick={handleMenuItemClick(menuItem)}
                >
                  {IconComponent && (
                    <NavIcon>
                      <IconComponent />
                    </NavIcon>
                  )}
                  {sidenavIsShown || mobile ? (
                    <React.Fragment>
                      <NavText title={title} />
                      {!!subItems && <IconKeyboardDown className={styles.expandIcon} />}
                    </React.Fragment>
                  ) : null}
                </NavLink>
                {sidenavIsShown && !!subItems && isExpanded && (
                  <div className={styles.subItems}>{getMenuItems(subItems)}</div>
                )}
              </NavItem>
            </React.Fragment>
          );
        });
    },
    [filterByDeviceType, filterByRole, handleMenuItemClick, sidenavIsShown, mobile, selectedItem],
  );
  const mainMenu = React.useMemo(() => getMenuItems(mainMenuItems), [getMenuItems]);
  const additionalMenu = React.useMemo(() => getMenuItems(additionMenuItems), [getMenuItems]);

  const nonAuth = React.useMemo(() => {
    return mobile ? (
      <MobileSidenav
        expanded={Boolean(sidenavIsShown)}
        toggle={toggle}
        mainMenu={mainMenu}
        additionalMenu={additionalMenu}
        isAuth={isAuthenticated}
      />
    ) : (
      <NonAuthSidenav />
    );
  }, [mobile, sidenavIsShown, mainMenu, additionalMenu, isAuthenticated, toggle]);

  const auth = React.useMemo(() => {
    return mobile ? (
      <MobileSidenav
        expanded={Boolean(sidenavIsShown)}
        toggle={toggle}
        mainMenu={mainMenu}
        additionalMenu={additionalMenu}
        isAuth={isAuthenticated}
      />
    ) : (
      <DesktopSidenav expanded={Boolean(sidenavIsShown)} toggle={toggle} mainMenu={mainMenu} />
    );
  }, [mobile, sidenavIsShown, toggle, mainMenu, additionalMenu]);

  return (
    <aside className={cn(styles.sideNav, !isAuthenticated && !mobile ? styles.nonAuth : null)}>
      {isAuthenticated ? auth : nonAuth}
    </aside>
  );
};

export default Sidenav;
