import * as React from 'react';
import { NumericFormat } from 'react-number-format';

import FadeInOut from '../../../../animations/FadeInOut';
import { IHoverContentCount } from '../../../../types/dashboard';
import { capitalize } from '../../../../utils/ui';
import { Price } from '../../../index';

import styles from './HoverArea.module.scss';

interface IProps {
  visible: boolean;
  total: number | undefined;
  count: IHoverContentCount | undefined;
}

const HoverArea = ({ total, count, visible }: IProps) => {
  const [cachedTotal, setTotal] = React.useState<number | undefined>();
  const [cachedCount, setCount] = React.useState<IHoverContentCount | undefined>();

  React.useEffect(() => {
    if (typeof total !== 'undefined') {
      setTotal(total);
    }

    if (typeof count !== 'undefined') {
      setCount(count);
    }
  }, [total, count]);

  return (
    <div className={styles.container}>
      <FadeInOut className={styles.hoverArea} isOpen={visible}>
        <div className={styles.totalSpent}>
          Amount spent
          <Price value={cachedTotal} className={styles.total} thousandSeparator />
        </div>
        {cachedCount && (
          <div className={styles.countsList}>
            {Object.entries(cachedCount).map(([key, value]) => (
              <div key={key} className={styles.countRow}>
                <div className={styles.label}>{capitalize(key, true)}</div>
                <div className={styles.value}>
                  <NumericFormat className={styles.count} displayType="text" value={value || 0} thousandSeparator />{' '}
                  send
                  {value !== 1 ? 's' : ''}
                </div>
              </div>
            ))}
          </div>
        )}
      </FadeInOut>
    </div>
  );
};

export default HoverArea;
