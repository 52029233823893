// min date format YYYY-MM-DD
export const MIN_BLUEBIRD_DATE = new Date('2020-01-01');

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export enum DateFormatsEnum {
  Full = "yyyy-MM-dd'T'HH:mm:ss",
  Start = "yyyy-MM-dd'T00:00:00.000Z'",
  End = "yyyy-MM-dd'T23:59:59.000Z'",
  yyyyMMdd = 'yyyy-MM-dd',
  ddMMMyyyy = 'dd MMM yyyy',
  MM_dd_yyyy = 'MM-dd-yyyy',
}
