import { addDays } from 'date-fns';

import { ICustomSend } from '../types/campaigns';
import { BUSINESS_REASON_ID } from './reasons';

export const DISABLE_SENDER_EMAIL_NOTIFICATIONS = 'disable_sender_email_notifications';
export const DISABLE_SHIPMENT_TRACKING = 'disable_shipment_tracking';

export const INTERNAL_NOTES = 'internal_notes';
export const PRE_CREATED_ENGAGEMENT_ID = 'pre_created_engagement_id';
export const SEND_ON_BEHALF_THE_EMAIL = 'send_on_behalf_the_email';
export const SEND_ON_HOLD = 'on_hold_until';
export const IS_TOGGLE_ON_HOLD_ENABLED = 'is_toggle_on_hold_enabled';
export const CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID = 'custom';
export const RECEIVER_REMINDER_INTERVAL = 'receiver_reminder_interval';
export const DELAYED_SHIPPING_EXPIRATION = 'delayed_shipping_expiration';
export const DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE = addDays(new Date(), 60);
export const MAX_DELAYED_SHIPPING_EXPIRATION_DATE = addDays(new Date(), 90);
export const MIN_DELAYED_SHIPPING_EXPIRATION_DATE = addDays(new Date(), 1);

export const INITIAL_SUMMARY_FORM_STATE = {
  [BUSINESS_REASON_ID]: '',
  [INTERNAL_NOTES]: '',
  [SEND_ON_HOLD]: '',
  [DELAYED_SHIPPING_EXPIRATION]: '',
};

export const EMPTY_CUSTOM_SEND: ICustomSend = {
  name: 'Added Items',
  description: '',
  box_id: 'custom',
  items: [],
};
