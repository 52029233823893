import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SendDetailsForm } from '../../../components';
import { routes } from '../../../constants/routing';
import {
  INITIAL_SEND_SUMMARY_FORM_STATE as initialFormValues,
  SEND_DETAILS_FORM_VALIDATION_SCHEMA,
  SIMPLIFIED_SEND_DETAILS_FORM_VALIDATION_SCHEMA,
} from '../../../constants/shipping';
import { fetchFulfillmentCentersRequest } from '../../../store/actions/fulfillmentCenters';
import { selectHasItemsWithEnabledShippingOptions } from '../../../store/selectors/fulfillmentCenters';
import { MaintenanceFormStateEnum } from '../../../types/forms';
import { EngagementSourceTypesEnum, IReportWithCampaignSummaries } from '../../../types/reports';
import { ISendDetailsFormValues } from '../../../types/shipping';
import { getRequiredFields } from '../../../utils/form';
import { getEditableSendFields, isAwaitingAddress } from '../../../utils/reports';

export interface IEditEngagementDetailsSidebarProps {
  onCancel: () => void;
  onSubmit: (values: Partial<ISendDetailsFormValues>) => void;
  report: IReportWithCampaignSummaries | null | undefined;
}

export interface IEditDetailsRef extends Partial<ISendDetailsFormValues> {
  getValues: () => Partial<ISendDetailsFormValues>;
}

const EditEngagementDetailsContainer: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<IEditEngagementDetailsSidebarProps> & React.RefAttributes<IEditDetailsRef>
> = React.forwardRef(({ report, onCancel, onSubmit }, ref?: React.Ref<IEditDetailsRef>) => {
  const dispatch = useDispatch();

  const isShippingOptionEnabled = useSelector(selectHasItemsWithEnabledShippingOptions(report?.items));

  const initialValues = React.useMemo(
    () => ({
      ...initialFormValues,
      ...getEditableSendFields(report),
    }),
    [report],
  );

  const isDelayedShippingSend = React.useMemo(() => report && isAwaitingAddress(report), [report]);

  const validationSchema = React.useMemo(
    () =>
      isDelayedShippingSend ? SIMPLIFIED_SEND_DETAILS_FORM_VALIDATION_SCHEMA : SEND_DETAILS_FORM_VALIDATION_SCHEMA,
    [isDelayedShippingSend],
  );

  const handleSubmit = React.useCallback(
    (
      values: Partial<ISendDetailsFormValues>,
      { resetForm, setSubmitting }: FormikHelpers<Partial<ISendDetailsFormValues>>,
    ) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      const successfulSubmit = () => {
        resetForm();
        setSubmitting(false);
      };

      return new Promise<void>(() => {
        setSubmitting(true);
        onSubmit(values);
      }).then(successfulSubmit);
    },
    [onSubmit, validationSchema],
  );

  const form = useFormik<Partial<ISendDetailsFormValues>>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  React.useImperativeHandle(
    ref,
    () => ({
      getValues: () => form.values,
    }),
    [form.values],
  );

  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), [validationSchema]);

  const isNoteHidden = React.useMemo(() => {
    if (report?.pick_campaigns) {
      return report.pick_campaigns.every((campaign) => campaign.disable_handwritten_note_step);
    }
  }, [report]);

  const isDirectSend = React.useMemo(
    () => report?.engagement_source_type === EngagementSourceTypesEnum.DirectSend,
    [report],
  );

  const handleFormFieldChange = React.useCallback(
    (name: keyof ISendDetailsFormValues, value: string | number | undefined | string[] | boolean) => {
      form.setFieldValue(name, value, true);
    },
    [form.setFieldValue],
  );

  React.useEffect(() => {
    dispatch(fetchFulfillmentCentersRequest());
  }, [dispatch]);

  return (
    <SendDetailsForm
      root={routes.reports.root}
      requiredFields={requiredFields}
      form={form}
      onChange={handleFormFieldChange}
      mode={MaintenanceFormStateEnum.Editing}
      onCancel={onCancel}
      onSubmit={form.handleSubmit}
      isEditingShipmentOptionsAllowed={isShippingOptionEnabled}
      isNoteHidden={isNoteHidden}
      isDirectSend={isDirectSend}
    />
  );
});

export default EditEngagementDetailsContainer;
