import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { DEPARTMENT_BUDGET_WARNING_RATE } from '../../../constants/shell';
import {
  selectCurrentDepartmentRemainingBudget,
  selectIsDepartmentBudgetUnlimited,
} from '../../../store/selectors/organizations';
import { FeatureBudgetEnum } from '../../../types/shell';
import FeatureBudget, { IFeatureBudgetProps } from '../FeatureBudget';

const DepartmentBudget: FC<Pick<IFeatureBudgetProps, 'className' | 'onLowBudget'>> = (props) => {
  const departmentValue = useSelector(selectCurrentDepartmentRemainingBudget);
  const isUnlimited = useSelector(selectIsDepartmentBudgetUnlimited);

  return (
    <FeatureBudget
      {...props}
      name={FeatureBudgetEnum.Department}
      hint="Department budget remaining"
      budgetValue={isUnlimited ? 'No Limit' : departmentValue}
      label="Department Budget:"
      warningValue={DEPARTMENT_BUDGET_WARNING_RATE}
    />
  );
};

export default DepartmentBudget;
