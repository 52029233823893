import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { actionTypes as FirebaseActions } from 'react-redux-firebase';

import { LOW_INVENTORY_NOTIFICATION_EMAILS, NO_DEPARTMENT_ITEM } from '../../constants/organizations';
import { IAuthResponse } from '../../types/auth';
import { IDepartmentBudgetSummariesResponse, IOrganizationItem, IOrganizationsState } from '../../types/organizations';
import { IReduxAction } from '../../types/redux';
import { sortByName } from '../../utils/organizations';
import * as AuthActions from '../actions/auth';
import * as OrganizationsActions from '../actions/organizations';

export const initialState: IOrganizationsState = {
  current: {
    options: null,
    departmentList: null,
    isDepartmentSummariesLoading: false,
    isDepartmentUpdating: false,
    uid: null,
    name: null,
    error: null,
    departmentBudget: null,
  },
  items: null,
  organizationDetails: null,
  isOrganizationDetailsLoading: false,
  isOrganizationsPIILoading: false,
  isLoading: false,
  error: null,
};

const reducer = (state: IOrganizationsState = initialState, action: any): IOrganizationsState => {
  switch (action.type) {
    case AuthActions.AUTHENTICATE_FAILURE:
    case AuthActions.AUTHORIZE_USER_FAILURE:
    case AuthActions.CHANGE_ORGANIZATION_SUCCESS:
    case FirebaseActions.LOGOUT: {
      return {
        ...state,
        current: initialState.current,
      };
    }
    case AuthActions.AUTHORIZE_USER_SUCCESS: {
      const {
        departments,
        organization_options = null,
        user_department_info = null,
        user,
      } = (action as IReduxAction<IAuthResponse>).payload || {};

      return {
        ...state,
        current: {
          ...state.current,
          departmentList: [...(departments ? departments : []), NO_DEPARTMENT_ITEM],
          options: organization_options,
          departmentBudget: user_department_info,
          uid: user?.org_id || null,
          name: user?.org_name || null,
        },
      };
    }
    case OrganizationsActions.FETCH_ORGANIZATIONS_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case OrganizationsActions.FETCH_ORGANIZATIONS_SUCCESS: {
      const { organizations: items } = action.payload || {};

      return {
        ...state,
        items,
        error: null,
        isLoading: false,
      };
    }
    case OrganizationsActions.FETCH_ORGANIZATIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_REQUEST:
    case OrganizationsActions.ADD_ORGANIZATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_SUCCESS: {
      const items = state.items ? state.items.concat(action.payload).sort(sortByName(false)) : { ...action.payload };

      return {
        ...state,
        items,
        isLoading: false,
        organizationDetails: null,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_SUCCESS: {
      const items = state.items ? state.items.slice() : null;

      const index = items?.findIndex((organization: IOrganizationItem) => organization.uid === action.payload.uid);

      if (typeof index !== 'undefined' && index !== -1) {
        items?.splice(index, 1, action.payload);
        // Sort once more, if possible name is changed
        items?.sort(sortByName(false));
      }

      return {
        ...state,
        isLoading: false,
        items,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_FAILURE:
    case OrganizationsActions.ADD_ORGANIZATION_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_REQUEST: {
      return {
        ...state,
        isOrganizationDetailsLoading: true,
        error: null,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_SUCCESS: {
      const { org_id: orgId } = action.payload;

      // add new department to organization current departmentList
      let departmentList = state.current.departmentList?.slice() || null;
      const isCurrentOrganization = state.current.uid === orgId;

      if (isCurrentOrganization) {
        departmentList = !!departmentList?.length ? departmentList?.concat(action.payload) : [action.payload];
      }

      // add new department to organizations items org_departments
      const organizationList = state.items ? cloneDeep(state.items) : null;
      const index = organizationList?.findIndex((org) => org.uid === orgId);

      if (typeof index !== 'undefined' && index !== -1) {
        organizationList![index].org_departments = organizationList![index].org_departments
          ? organizationList![index].org_departments.concat(action.payload)
          : [action.payload];
      }

      return {
        ...state,
        isOrganizationDetailsLoading: false,
        items: organizationList,
        error: null,
        current: {
          ...state.current,
          departmentList,
        },
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DEPARTMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isOrganizationDetailsLoading: false,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_REQUEST: {
      return {
        ...state,
        error: null,
        isOrganizationDetailsLoading: true,
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_SUCCESS: {
      const { uid, org_id: orgId } = action.payload;

      // update organizations current departmentList
      const departmentList = state.current.departmentList?.slice() || null;
      const isCurrentOrganization = state.current.uid === orgId;

      if (isCurrentOrganization) {
        const updatedDeptIndex = departmentList?.findIndex((department) => department.uid === uid);
        if (departmentList && typeof updatedDeptIndex !== 'undefined') {
          departmentList[updatedDeptIndex] = action.payload;
        }
      }

      // update organizations items departmentsList
      const organizationList = state.items ? cloneDeep(state.items) : null;
      const requiredOrgIndex = organizationList?.findIndex((org) => org.uid === orgId);

      if (typeof requiredOrgIndex !== 'undefined' && requiredOrgIndex !== -1) {
        const { org_departments: deptList } = organizationList![requiredOrgIndex];

        for (let i = 0; i < deptList?.length; i++) {
          if (deptList[i].uid === uid) {
            deptList[i] = action.payload;
            break;
          }
        }
      }

      return {
        ...state,
        items: organizationList,
        isOrganizationDetailsLoading: false,
        error: null,
        current: {
          ...state.current,
          departmentList,
        },
      };
    }
    case OrganizationsActions.EDIT_ORGANIZATION_DEPARTMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isOrganizationDetailsLoading: false,
      };
    }
    case OrganizationsActions.ADD_ORGANIZATION_DETAILS: {
      /* [START] The fields that are moved out from lodash merge because of wrong merging */

      const newLowInventoryEmailReceivers = Object.keys(action.payload).includes(LOW_INVENTORY_NOTIFICATION_EMAILS)
        ? action.payload[LOW_INVENTORY_NOTIFICATION_EMAILS]
        : state.organizationDetails?.[LOW_INVENTORY_NOTIFICATION_EMAILS];

      /* [END] The fields that are moved out from lodash merge because of wrong merging */

      return {
        ...state,
        // merge() is used here for the recursively merge.
        // action.payload could have nested objects
        organizationDetails: merge({}, omit(state.organizationDetails, [LOW_INVENTORY_NOTIFICATION_EMAILS]), {
          ...action.payload,
          [LOW_INVENTORY_NOTIFICATION_EMAILS]: newLowInventoryEmailReceivers,
        }),
      };
    }
    case OrganizationsActions.CLEAR_ORGANIZATION_DETAILS: {
      return {
        ...state,
        organizationDetails: null,
      };
    }
    case AuthActions.ADMIN_ROLE_CHANGED: {
      return initialState;
    }
    case OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_REQUEST: {
      return {
        ...state,
        current: {
          ...state.current,
          isDepartmentSummariesLoading: true,
          error: null,
        },
      };
    }
    case OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_FAILURE: {
      return {
        ...state,
        current: {
          ...state.current,
          isDepartmentSummariesLoading: false,
          error: action.payload,
        },
      };
    }
    case OrganizationsActions.GET_DEPARTMENT_BUDGET_SUMMARIES_SUCCESS: {
      const { department_budget_summaries } =
        (action as IReduxAction<IDepartmentBudgetSummariesResponse>).payload || {};

      return {
        ...state,
        current: {
          ...state.current,
          departmentList: department_budget_summaries
            ? department_budget_summaries.slice()
            : state.current.departmentList || null,
          isDepartmentSummariesLoading: false,
          error: null,
        },
      };
    }
    case OrganizationsActions.REMOVE_PII_REQUEST: {
      return {
        ...state,
        isOrganizationsPIILoading: true,
      };
    }
    case OrganizationsActions.REMOVE_PII_SUCCESS:
    case OrganizationsActions.REMOVE_PII_FAILURE: {
      return {
        ...state,
        isOrganizationsPIILoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
