import * as React from 'react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SendSummaryComponent } from '../../components';
import { routes } from '../../constants/routing';
import { EMAIL_TEMPLATE_ID, MESSAGE } from '../../constants/templates';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { fetchBusinessReasonsRequest } from '../../store/actions/reasons';
import { fetchReportByIdRequest, populateReportCampaignSummariesRequest } from '../../store/actions/reports';
import { selectIsSuperAdmin } from '../../store/selectors/auth';
import { selectDeliveryReportById, selectIsSingleDeliveryReportsFetching } from '../../store/selectors/reports';
import { EditSendModeEnum, ReportStatusEnum } from '../../types/reports';
import { IReportsRouteParams } from '../../types/routing';
import { isSameFC } from '../../utils/inventories';
import {
  getReportTotalPrice,
  isAwaitingAddress,
  isCustomReport,
  isDigitalReport,
  isPYGReport,
  isValidScheduledEngagement,
} from '../../utils/reports';
import { handleApiError } from '../../utils/ui';
import { EditSendSidebar } from '../index';

import styles from './ReportSummaryContainer.module.scss';

const ReportSummaryContainer: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reportId, type } = useParams<IReportsRouteParams>();
  const { setAction, setTitle } = useHeaderDispatch();

  const isEditAllowedByRole = useSelector(selectIsSuperAdmin);

  const [isEditSidebarShown, setIsEditSidebarShown] = React.useState<EditSendModeEnum | undefined>();

  const report = useSelector(selectDeliveryReportById(reportId, type));
  const isLoadingSingle = useSelector(selectIsSingleDeliveryReportsFetching);

  const isDigital = useMemo(() => !!report && isDigitalReport(report), [report]);

  const isEditingEngagementAllowed = React.useMemo(() => {
    const isEngagementCancelled = report?.status === ReportStatusEnum.Canceled;
    const hasItemsFromDifferentFC = !isSameFC(report?.items || []);

    if (
      !isEditAllowedByRole ||
      isDigital ||
      !report?.items?.length ||
      isEngagementCancelled ||
      hasItemsFromDifferentFC
    ) {
      return false;
    }

    return (
      isValidScheduledEngagement(report) ||
      report?.ship_order_status === ReportStatusEnum.Viewed ||
      report?.ship_order_status === ReportStatusEnum.AwaitingShipment ||
      report?.ship_order_status === ReportStatusEnum.AwaitingAddress
    );
  }, [isEditAllowedByRole, report?.items, report?.ship_order_status, isDigital, report?.status]);

  const isDelayedShipping = useMemo(() => !!report && isAwaitingAddress(report), [report]);

  const showEmailSection = useMemo(() => {
    return (
      typeof report?.[EMAIL_TEMPLATE_ID] !== 'undefined' &&
      report?.[EMAIL_TEMPLATE_ID] &&
      (isDigital || isDelayedShipping)
    );
  }, [report, isDigital, isDelayedShipping]);

  const fetchReport = useCallback(() => {
    if (reportId) {
      return new Promise((resolve, reject) => {
        dispatch(
          fetchReportByIdRequest({
            reportId,
            type,
            resolve,
            reject,
          }),
        );
      }).catch(
        handleApiError(`Something bad happened during the delivery report retrieval.`, () =>
          history.push(routes.reports.root),
        ),
      );
    }
  }, [dispatch, history, type, reportId]);

  const fetchReasons = useCallback(() => {
    dispatch(fetchBusinessReasonsRequest());
  }, [dispatch]);

  const fetchCampaignSummaries = useCallback(
    (campaignIds: string[]) => {
      if (reportId) {
        dispatch(populateReportCampaignSummariesRequest({ campaign_ids: campaignIds, reportId, type }));
      }
    },
    [dispatch, reportId, type],
  );

  const handleCloseEditSidebar = React.useCallback(() => {
    setIsEditSidebarShown(undefined);
  }, []);

  useEffect(() => {
    if (!report) {
      fetchReport();
    }
  }, [report, fetchReport]);

  useEffect(() => {
    const isCustom = !!report && isCustomReport(report);
    const isLoadedSummaries = !!report?.pick_campaigns;
    const isPYG = !!report?.pick_campaign_ids;

    if (report && !isLoadedSummaries && !isLoadingSingle && !isCustom) {
      const campaignIds = isPYG ? report.pick_campaign_ids : [report?.pre_created_engagement_id];

      fetchCampaignSummaries(campaignIds);
    }
  }, [report, fetchCampaignSummaries, isLoadingSingle]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  useEffect(() => {
    setTitle?.('Send Summary');
    setAction?.(() => history.push(routes.reports.root));
  }, [setTitle, setAction, history]);

  return (
    <div className={styles.container}>
      <SendSummaryComponent
        type={type}
        instance={report}
        showNote={!!report?.[MESSAGE]}
        showEmail={!!showEmailSection}
        total={getReportTotalPrice(report)}
        isDigital={isDigital}
        isPYG={!!report && isPYGReport(report)}
        isEditable={isEditingEngagementAllowed}
        onEdit={setIsEditSidebarShown}
        showQuantity
        reportMode
      />
      {isEditingEngagementAllowed && (
        <EditSendSidebar mode={isEditSidebarShown} report={report} onClose={handleCloseEditSidebar} />
      )}
    </div>
  );
};

export default ReportSummaryContainer;
