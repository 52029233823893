import cn from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionButton, FileDetails, ModalActions, ModalHeader } from '../..';
import { FulfillmentCenterSelect, UploadInput } from '../../forms/inputs';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { ReactComponent as iconCSVInfo } from '../../../assets/images/icon-csv-info.svg';
import { ReactComponent as FileIcon } from '../../../assets/images/icon-file.svg';
import { ReactComponent as UploadIcon } from '../../../assets/images/icon-upload.svg';

import useUploadFile from '../../../hooks/useUploadFile';
import { setInventoryCSVUploadFile } from '../../../store/actions/inventories';
import { selectInventoryUploadCSVFileInfo } from '../../../store/selectors/inventories';
import { IDropzoneResult } from '../../../types/forms';
import { UploadFileTypeEnum } from '../../../types/upload';
import { formatFileSize, getFileInfo } from '../../../utils/bucket';
import notification from '../../../utils/notification';

import { NO_FULFILLMENT_CENTER } from '../../../constants/fulfillmentCenters';
import commonStyles from '../../modals/CommonModals/CommonModals.module.scss';
import styles from './UploadInventoryModal.module.scss';

export interface IProps {
  onClose: () => void;
  onSubmit: (fileName: string, fcId: string) => void;
}

const UploadInventoryModal = ({ onClose, onSubmit }: IProps) => {
  const dispatch = useDispatch();
  const { uploadFile } = useUploadFile();

  const uploadedFile = useSelector(selectInventoryUploadCSVFileInfo);
  const isLoaded = React.useMemo(() => !!uploadedFile, [uploadedFile]);

  const [selectedFulfillmentCenterId, setSelectedFulfillmentCenterId] = useState<string | null>(null);

  const onUploadStart = React.useCallback(
    ({ files, resolve, reject }: IDropzoneResult) => {
      uploadFile(files[0], UploadFileTypeEnum.InventoryCSV).then(resolve).catch(reject);
    },
    [uploadFile],
  );

  const onUploadSuccess = React.useCallback(
    (files: File[]) => dispatch(setInventoryCSVUploadFile({ file: getFileInfo(files[0]) })),
    [dispatch],
  );

  const onUploadFailure = React.useCallback(({ message = 'An error occurred during file upload.' }) => {
    notification.error(message, { content: message });
  }, []);

  const clearUploadedFile = React.useCallback(() => {
    dispatch(setInventoryCSVUploadFile({ file: null }));
  }, [dispatch]);

  const uploadInventoryCSVFile = React.useMemo(() => {
    return (
      <>
        <div className={styles.contentHeader}>Upload Inventory</div>
        <div className={styles.title}>File uploader</div>
        <UploadInput
          accept=".csv"
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess}
          onFailure={onUploadFailure}
          className={styles.uploadField}
          multiple={false}
        >
          {({ isDragActive }) => {
            return (
              <div className={styles.placeholder}>
                {isLoaded ? <FileIcon /> : <UploadIcon />}
                {isDragActive ? (
                  <span className={styles.uploadMessage}>Drop the files here ...</span>
                ) : (
                  <span className={styles.uploadMessage}>
                    {isLoaded ? 'The file was uploaded' : 'Click or drag to upload CSV file'}
                  </span>
                )}
              </div>
            );
          }}
        </UploadInput>
        {uploadedFile && (
          <FileDetails
            action={<button className={styles.clearIcon} onClick={clearUploadedFile} />}
            name={uploadedFile.name}
            icon={iconCSVInfo}
            description={`${formatFileSize(uploadedFile.size)}`}
          />
        )}
      </>
    );
  }, [clearUploadedFile, isLoaded, onUploadFailure, onUploadStart, onUploadSuccess, uploadedFile]);

  const handleFCSelect = React.useCallback((selectValue: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = selectValue.currentTarget || {};
    setSelectedFulfillmentCenterId(value);
  }, []);

  const fulfillmentCenterSelect = React.useMemo(() => {
    return (
      <div className={styles.fcInputContainer}>
        <FulfillmentCenterSelect
          helperText="Select Fulfillment Center"
          placeholder="Search fulfillment center"
          value={selectedFulfillmentCenterId}
          excludedOptions={NO_FULFILLMENT_CENTER}
          onChange={handleFCSelect}
          isClearable={false}
          closeMenuOnSelect
        />
      </div>
    );
  }, [selectedFulfillmentCenterId]);

  const handleSubmit = React.useCallback(() => {
    if (!uploadedFile?.name || !selectedFulfillmentCenterId) {
      return;
    }

    onSubmit(uploadedFile.name, selectedFulfillmentCenterId);
    onClose();
  }, [onSubmit, uploadedFile, selectedFulfillmentCenterId, onClose]);

  return (
    <div className={cn(commonStyles.container, styles.container)}>
      <ModalHeader
        className={commonStyles.header}
        right={
          <div className={styles.closeButton}>
            <CloseIcon onClick={onClose} />
          </div>
        }
      />
      <div className={styles.content}>
        {uploadInventoryCSVFile}
        {fulfillmentCenterSelect}
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton
          className={styles.uploadBtn}
          disabled={!(uploadedFile?.name && selectedFulfillmentCenterId)}
          title="Upload Inventory"
          onClick={handleSubmit}
        />
      </ModalActions>
    </div>
  );
};

export default UploadInventoryModal;
