import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import { ReactComponent as AccountIcon } from '../../../assets/images/icon-input-account.svg';
import { ReactComponent as EmailIcon } from '../../../assets/images/icon-input-mail.svg';
import { countryList } from '../../../constants/countries';
import { NO_DEPARTMENT } from '../../../constants/organizations';
import {
  ADDRESS1,
  ADDRESS2,
  ADMIN_TYPE,
  AVAILABLE_BUDGET,
  CITY,
  COUNTRY,
  CRM_SKIP_ALLOWED,
  DEPARTMENT_ID,
  DISTRIBUTOR,
  DISTRIBUTOR_ORG_IDS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  ORG_ADMIN,
  PHONE,
  ROLES_SELECT_OPTIONS,
  STATE,
  SUPER_ADMIN,
  UID,
  ZIP,
} from '../../../constants/users';
import useWindowSize from '../../../hooks/useWindowSize';
import { selectAdminType, selectCurrentUserId } from '../../../store/selectors/auth';
import {
  selectCurrentOrganizationData,
  selectCurrentOrganizationId,
  selectOrganizations,
} from '../../../store/selectors/organizations';
import { IMaintenanceFormOutputData, MaintenanceFormStateEnum } from '../../../types/forms';
import { ISelectorValue } from '../../../types/shell';
import { IUser } from '../../../types/users';
import { getCountryNameByTwoDigits } from '../../../utils/country';
import { hasPermission } from '../../../utils/users';
import CountryList from '../../CountryList/CountryList';
import LookupSearch from '../../LookupSearch/LookupSearch';
import { HelpTooltip } from '../../tooltips';
import { ActionButton, DepartmentSelect, InputLabel, OrganizationSelect, Toggle } from '../index';
import { NumericInput } from '../inputs/Input';
import Input from '../inputs/Input/Input';
import Selector from '../inputs/Selector/Selector';

import styles from './UserForm.module.scss';

interface IProps extends IMaintenanceFormOutputData {
  onChange: (name: keyof IUser, value: string | number | undefined | string[] | boolean) => void;
  form: FormikProps<Partial<IUser>>;
  onResetPassword?: (email: string) => void;
  disabled?: boolean;
  requiredFields: Record<string, boolean>;
}

const UserForm = ({ onChange, onResetPassword, disabled, form, mode, requiredFields }: IProps) => {
  const [country, setCountry] = React.useState('');
  const adminType = useSelector(selectAdminType);
  const accountOrgId = useSelector(selectCurrentOrganizationId);
  const { mobile } = useWindowSize();
  const organizations = useSelector(selectOrganizations);
  const currentUserId = useSelector(selectCurrentUserId);
  const { options: organizationOptions } = useSelector(selectCurrentOrganizationData) || {};
  const isSkipCrmAllowedInOrg = React.useMemo(() => organizationOptions?.crm_skip_allowed, [organizationOptions]);

  const isReadOnly = React.useMemo(() => mode === MaintenanceFormStateEnum.Reading, [mode]);
  const isEditing = React.useMemo(() => mode === MaintenanceFormStateEnum.Editing, [mode]);

  const filteredCountries = React.useMemo(
    () => countryList.filter(({ name }) => name.toLocaleLowerCase().includes(country.toLocaleLowerCase())),
    [country],
  );

  const roleOptions = React.useMemo(() => {
    /* Only super can add distributor to the system
     * Possibility to edit distributor should be handled in other place
     */
    const showDistributorRole = form.values[ADMIN_TYPE] === DISTRIBUTOR || hasPermission([SUPER_ADMIN], adminType);

    return ROLES_SELECT_OPTIONS(adminType).filter((role) => {
      return role.value === DISTRIBUTOR ? showDistributorRole : true;
    });
  }, [adminType, form.values[ADMIN_TYPE]]);

  const isDisabledUserSelectorRole = React.useMemo(() => {
    return isEditing && hasPermission(DISTRIBUTOR, adminType) && form.values[UID] === currentUserId;
  }, [isEditing, adminType, currentUserId, form.values[UID]]);

  const getFieldErrors = React.useCallback(
    (fieldName: keyof IUser) => {
      return form.touched[fieldName] ? (form.errors[fieldName] as string | string[]) : undefined;
    },
    [form.touched, form.errors],
  );

  const setCountryNameByTwoDigit = React.useCallback((digitCode: string) => {
    const selectedCountry = getCountryNameByTwoDigits(digitCode);
    if (selectedCountry) {
      setCountry(selectedCountry.name);
    }
  }, []);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { name, value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      if (typeof onChange === 'function') {
        onChange(name as keyof IUser, value);
      }
    },
    [onChange],
  );

  const handleToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked: value } = e.currentTarget;
      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      if (typeof onChange === 'function') {
        onChange(name as keyof IUser, value);
      }
    },
    [onChange],
  );

  const handleCountrySelect = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleChange(e);
      const { value } = e.currentTarget;
      setCountryNameByTwoDigit(value);
      form.setFieldValue(COUNTRY, value, true);
    },
    [handleChange, form.setFieldValue, setCountryNameByTwoDigit],
  );

  React.useEffect(() => {
    // It's used for initial transform two digit
    if (form?.values?.[COUNTRY] && form.values[COUNTRY] !== country) {
      setCountryNameByTwoDigit(form.values[COUNTRY]!);
    }
  }, [form?.values?.[COUNTRY], country, setCountryNameByTwoDigit]);

  const selectedRole = React.useMemo(() => form?.values[ADMIN_TYPE], [form?.values?.[ADMIN_TYPE]]);
  const selectedDepartment = React.useMemo(() => form?.values[DEPARTMENT_ID], [form?.values?.[DEPARTMENT_ID]]);
  const distributorOrgIds = React.useMemo(
    () => form?.values?.[DISTRIBUTOR_ORG_IDS],
    [form.values[DISTRIBUTOR_ORG_IDS]],
  );

  /**
   * This effect watches the `selectedRole` for higher admin roles.
   * It sets `no_department` to higher admin roles if it was set by any reason before,
   * i.e. the user's role was raised from department admin to org_admin
   * FIXME: it should be validated on the BE side as well
   */
  React.useEffect(() => {
    if (
      hasPermission([ORG_ADMIN, SUPER_ADMIN, DISTRIBUTOR], selectedRole ?? '') &&
      selectedDepartment !== NO_DEPARTMENT
    ) {
      onChange(DEPARTMENT_ID, NO_DEPARTMENT);
    }
  }, [onChange, selectedRole, selectedDepartment]);

  /**
   * This effect watches the `selectedRole` for Distributor role.
   * 1. For all the rest roles it clears the `distributorOrgIds` array.
   * 2. For the Distributor role it adds the current org to the `distributorOrgIds` array.
   * FIXME: it should be validated on the BE side as well
   */
  React.useEffect(() => {
    if (selectedRole !== DISTRIBUTOR && distributorOrgIds?.length) {
      onChange(DISTRIBUTOR_ORG_IDS, []);
    }

    if (selectedRole === DISTRIBUTOR) {
      if (accountOrgId && !distributorOrgIds?.includes(accountOrgId)) {
        onChange(DISTRIBUTOR_ORG_IDS, distributorOrgIds?.concat([accountOrgId]));
      }
    }
  }, [onChange, accountOrgId, selectedRole, distributorOrgIds]);

  const handleSelectInputChange = React.useCallback(
    (name: keyof IUser, selected?: OnChangeValue<ISelectorValue, boolean> | null) => {
      const { value } = selected as ISelectorValue;
      return handleChange({ currentTarget: { name, value } } as unknown as
        | React.ChangeEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>);
    },
    [handleChange],
  );

  const handleResetPasswordClick = React.useCallback(() => {
    if (onResetPassword) {
      onResetPassword(form?.values?.email!);
    }
  }, [form?.values?.email, onResetPassword]);

  const formatAdminTypeValue = React.useCallback(
    (value: string): ISelectorValue => ({
      label: roleOptions.find((role) => role.value === value)?.label || '',
      value,
    }),
    [roleOptions],
  );

  const orgLabels = React.useMemo(() => {
    return organizations && distributorOrgIds?.length
      ? distributorOrgIds?.map((orgId) => organizations.find(({ uid }) => uid === orgId)?.name).join(', ')
      : null;
  }, [organizations, distributorOrgIds]);

  return (
    <React.Fragment>
      <form className={styles.container} onSubmit={form.handleSubmit}>
        <div className={styles.section}>
          <div className={styles.subtitle}>Permissions Settings</div>
          <Selector
            containerClassName={styles.inputContainer}
            helperText="Assign Role"
            onChange={(value) => (isReadOnly ? null : handleSelectInputChange(ADMIN_TYPE, value))}
            options={roleOptions}
            value={formatAdminTypeValue(form?.values[ADMIN_TYPE] || '')}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            closeMenuOnSelect
            readOnly={isReadOnly || isDisabledUserSelectorRole}
          />
          {!hasPermission([SUPER_ADMIN, ORG_ADMIN, DISTRIBUTOR], form?.values[ADMIN_TYPE] ?? '') && (
            <DepartmentSelect
              containerClassName={styles.inputContainer}
              helperText="Choose Department"
              isMulti={false}
              readOnly={isReadOnly}
              onChange={handleChange}
              value={form.values[DEPARTMENT_ID]}
              onBlur={form.handleBlur}
              error={form.touched[DEPARTMENT_ID] ? form.errors[DEPARTMENT_ID] : undefined}
              className={styles.input}
              name={DEPARTMENT_ID}
              isClearable={false}
              closeMenuOnSelect
            />
          )}
          {hasPermission([DISTRIBUTOR, SUPER_ADMIN], adminType) &&
            hasPermission([DISTRIBUTOR], form?.values[ADMIN_TYPE] ?? '') &&
            ((isReadOnly || isEditing) && !hasPermission([SUPER_ADMIN], adminType) ? (
              <div className={styles.orgCount}>
                <InputLabel value="Organizations" />
                <div className={styles.organizationsList}>
                  {form.values[DISTRIBUTOR_ORG_IDS]?.length}{' '}
                  {form.values[DISTRIBUTOR_ORG_IDS]?.length === 1 ? `organization` : `organizations`}
                  <HelpTooltip
                    id="controls-info"
                    offset={{ right: mobile ? 0 : 10 }}
                    className={styles.tooltip}
                    data-border
                    data-tip
                  >
                    {orgLabels}
                  </HelpTooltip>
                </div>
              </div>
            ) : (
              <OrganizationSelect
                containerClassName={styles.inputContainer}
                helperText="Choose Organizations"
                isMulti
                readOnly={isReadOnly}
                onChange={handleChange}
                concreteOption={accountOrgId}
                value={form.values[DISTRIBUTOR_ORG_IDS]}
                onBlur={() => {
                  form.setFieldTouched(DISTRIBUTOR_ORG_IDS);
                }}
                error={form.touched[DISTRIBUTOR_ORG_IDS] ? form.errors[DISTRIBUTOR_ORG_IDS] : undefined}
                className={styles.input}
                name={DISTRIBUTOR_ORG_IDS}
              />
            ))}
        </div>
        <div className={styles.section}>
          <div className={styles.subtitle}>Profile Information</div>
          <Input
            field={form.getFieldProps(EMAIL)}
            name={EMAIL}
            placeholder="Email"
            helperText="Email"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[EMAIL]}
            value={form.values[EMAIL]}
            onChange={handleChange}
            error={getFieldErrors(EMAIL)}
            readOnly={isReadOnly || isEditing}
            icon={<EmailIcon />}
          />
          <Input
            field={form.getFieldProps(FIRST_NAME)}
            name={FIRST_NAME}
            placeholder="First Name"
            helperText="First Name"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[FIRST_NAME]}
            value={form.values[FIRST_NAME]}
            onChange={handleChange}
            error={getFieldErrors(FIRST_NAME)}
            readOnly={isReadOnly}
            icon={<AccountIcon />}
          />
          <Input
            field={form.getFieldProps(LAST_NAME)}
            name={LAST_NAME}
            placeholder="Last Name"
            helperText="Last Name"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[LAST_NAME]}
            value={form.values[LAST_NAME]}
            onChange={handleChange}
            error={getFieldErrors(LAST_NAME)}
            readOnly={isReadOnly}
            icon={<AccountIcon />}
          />
          <Input
            field={form.getFieldProps(ADDRESS1)}
            name={ADDRESS1}
            placeholder="Address 1"
            helperText="Address 1"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[ADDRESS1]}
            value={form.values[ADDRESS1]}
            onChange={handleChange}
            error={getFieldErrors(ADDRESS1)}
            readOnly={isReadOnly}
          />
          <Input
            field={form.getFieldProps(ADDRESS2)}
            name={ADDRESS2}
            placeholder="Address 2"
            helperText="Address 2"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[ADDRESS2]}
            value={form.values[ADDRESS2]}
            onChange={handleChange}
            error={getFieldErrors(ADDRESS2)}
            readOnly={isReadOnly}
          />
          <Input
            field={form.getFieldProps(CITY)}
            name={CITY}
            placeholder="City"
            helperText="City"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[CITY]}
            value={form.values[CITY]}
            onChange={handleChange}
            error={getFieldErrors(CITY)}
            readOnly={isReadOnly}
          />
          <Input
            field={form.getFieldProps(STATE)}
            name={STATE}
            placeholder="State"
            helperText="State"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[STATE]}
            value={form.values[STATE]}
            onChange={handleChange}
            error={getFieldErrors(STATE)}
            readOnly={isReadOnly}
          />
          <Input
            field={form.getFieldProps(ZIP)}
            name={ZIP}
            placeholder="ZIP Code"
            helperText="ZIP Code"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[ZIP]}
            value={form.values[ZIP]}
            onChange={handleChange}
            error={getFieldErrors(ZIP)}
            readOnly={isReadOnly}
          />
          <LookupSearch
            name={COUNTRY}
            helperText="Country"
            containerClassName={styles.inputContainer}
            onFocus={(e, value) => setCountry(value)}
            onChange={(e, value) => setCountry(value)}
            readOnly={isReadOnly}
            disabled={disabled}
            required={requiredFields[COUNTRY]}
            isLoading={false}
            value={country}
            result={({ setActive }) => (
              <CountryList
                onSelect={(e) => {
                  handleCountrySelect(e);
                  setActive(false);
                }}
                items={filteredCountries}
                name={COUNTRY}
              />
            )}
          />
          <Input
            field={form.getFieldProps(PHONE)}
            name={PHONE}
            placeholder="Phone"
            helperText="Phone"
            className={styles.inputContainer}
            disabled={disabled}
            required={requiredFields[PHONE]}
            value={form.values[PHONE]}
            onChange={handleChange}
            error={getFieldErrors(PHONE)}
            readOnly={isReadOnly}
          />
          <NumericInput
            value={form.values[AVAILABLE_BUDGET]}
            name={AVAILABLE_BUDGET}
            helperText="Available Budget"
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[AVAILABLE_BUDGET] ? form.errors[AVAILABLE_BUDGET] : undefined}
            className={styles.inputContainer}
            decimalScale={0}
            prefix="$"
            errorClassName={styles.error}
            readOnly={isReadOnly}
            required={requiredFields[AVAILABLE_BUDGET]}
          />
          {isSkipCrmAllowedInOrg && (
            <Toggle
              field={form.getFieldProps(CRM_SKIP_ALLOWED)}
              name={CRM_SKIP_ALLOWED}
              className={styles.inputContainer}
              disabled={isReadOnly}
              hint={
                <React.Fragment>
                  This option allows user to skip CRM integration
                  <br />
                  and only manually type in the shipment information.
                </React.Fragment>
              }
              helperText="Allow Skip CRM"
              onChange={handleToggleChange}
              checked={!!form.values?.[CRM_SKIP_ALLOWED]}
            />
          )}
        </div>
        {isEditing && (
          <div className={cn(styles.inputContainer, styles.resetPassword)}>
            <ActionButton
              title="Reset password"
              className={styles.resetPasswordButton}
              onClick={handleResetPasswordClick}
              outlined
            />
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

export default UserForm;
