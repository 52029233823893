import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import {
  EMAIL_ADDRESS,
  ERROR_DUPLICATE_EMAIL,
  ERROR_DUPLICATE_PHONE,
  ERROR_EMAIL_INVALID,
  ERROR_EMAIL_OR_PHONE_REQUIRED,
  ERROR_PHONE_INVALID,
  ONE_LINK_STATUS,
  PHONE_NUMBER,
  URL,
} from '../constants/oneLink';
import { EMAIL_VALIDATION_REGEX } from '../constants/shell';
import { IOneLink, TOneLinkRecipient } from '../types/oneLink';
import { CopyableLinkTypesEnum } from '../types/shell';
import { formatDate } from './date';

export const getOneLinkStatusAndDate = (oneLink: IOneLink) => {
  const createdAtDate = formatDate(oneLink.created_at);

  const { label, color } = ONE_LINK_STATUS[oneLink.status];

  return {
    createdAtDate,
    label,
    color,
  };
};

export const getOneLinkCopyableInfo = (oneLink: IOneLink) => {
  const result = [];

  if (oneLink[URL]) {
    result.push({
      type: CopyableLinkTypesEnum.OneLink,
      data: {
        label: '',
        url: oneLink[URL],
      },
    });
  }

  return result;
};

export const validateRecipients = (
  recipients: TOneLinkRecipient[],
  uploadedRecipients?: TOneLinkRecipient[] | null,
  activeRecipient?: TOneLinkRecipient,
) => {
  const filteredRecipients = activeRecipient
    ? uploadedRecipients?.filter((recipient: TOneLinkRecipient) => {
        if (activeRecipient.id && recipient.id) {
          return recipient.id !== activeRecipient.id;
        }

        if (activeRecipient.uid && recipient.uid) {
          return recipient.uid !== activeRecipient.uid;
        }
        return true;
      })
    : uploadedRecipients;

  const uploadedRecipientsEmails = filteredRecipients?.map((recipient: TOneLinkRecipient) => recipient[EMAIL_ADDRESS]);
  const uploadedRecipientsPhones = filteredRecipients?.map((recipient: TOneLinkRecipient) => recipient[PHONE_NUMBER]);

  const successfulRecipients: TOneLinkRecipient[] = [];
  const failedRecipients: TOneLinkRecipient[] = [];

  const emailSet = new Set();
  const phoneSet = new Set();

  recipients?.forEach((recipient: TOneLinkRecipient) => {
    // Rule 1: email OR phone is required
    if (!hasEmailOrPhone(recipient)) {
      recipient.error = ERROR_EMAIL_OR_PHONE_REQUIRED;
      delete recipient.id;
      failedRecipients.push(recipient);
      return;
    }

    // Rule 2: email duplicates
    if (
      recipient[EMAIL_ADDRESS] &&
      (emailSet.has(recipient[EMAIL_ADDRESS]) || uploadedRecipientsEmails?.includes(recipient[EMAIL_ADDRESS]))
    ) {
      recipient.error = ERROR_DUPLICATE_EMAIL;
      delete recipient.id;
      failedRecipients.push(recipient);
      return;
    }

    // Rule 3: phone duplicates
    if (
      recipient[PHONE_NUMBER] &&
      (phoneSet.has(recipient[PHONE_NUMBER]) || uploadedRecipientsPhones?.includes(recipient[PHONE_NUMBER]))
    ) {
      recipient.error = ERROR_DUPLICATE_PHONE;
      delete recipient.id;
      failedRecipients.push(recipient);
      return;
    }

    // Rule 4: email check
    if (recipient[EMAIL_ADDRESS] && !isValidEmail(recipient[EMAIL_ADDRESS])) {
      recipient.error = ERROR_EMAIL_INVALID;
      delete recipient.id;
      failedRecipients.push(recipient);
      return;
    }

    // Rule 5: phone check
    if (recipient[PHONE_NUMBER] && !isValidPhoneNumber(recipient[PHONE_NUMBER])) {
      recipient.error = ERROR_PHONE_INVALID;
      delete recipient.id;
      failedRecipients.push(recipient);
      return;
    }

    // No errors, add to successful recipients
    delete recipient.error;
    successfulRecipients.push(recipient);

    // Add email and phone to sets for duplicate checks
    if (recipient[EMAIL_ADDRESS]) {
      emailSet.add(recipient[EMAIL_ADDRESS]);
    }

    if (recipient[PHONE_NUMBER]) {
      phoneSet.add(recipient[PHONE_NUMBER]);
    }
  });

  return { successfulRecipients, failedRecipients };
};

const isValidEmail = (email: string) => new RegExp(EMAIL_VALIDATION_REGEX).test(email);

const hasEmailOrPhone = (obj: { [EMAIL_ADDRESS]: string; [PHONE_NUMBER]: string }) =>
  obj[EMAIL_ADDRESS]?.length > 0 || obj[PHONE_NUMBER]?.length > 0;

export const phoneTransform = (val: string, field: string): string => {
  if (field === PHONE_NUMBER) {
    const candidate = !val.startsWith('+') ? `+${val}` : val;

    if (isPossiblePhoneNumber(candidate)) {
      const parsedPhone = parsePhoneNumber(candidate);
      return parsedPhone?.number ? parsedPhone.number : candidate;
    }
    return val;
  }

  return val;
};
