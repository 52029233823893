import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useWindowSize from '../../../hooks/useWindowSize';
import { toggleMskuOptionsForm } from '../../../store/actions/shell';
import { selectIsMskuOptionsFormShown } from '../../../store/selectors/shell';
import { IInventoryItem, InventoryFilterTypesEnum } from '../../../types/inventories';
import { isSameFC } from '../../../utils/inventories';
import AvailableItems from '../../AvailableItems/AvailableItems';
import { DraggableList, NoResultsPlaceholder } from '../../index';
import InventoryViewItem from '../../InventoryViewItem/InventoryViewItem';
import { ActionButton } from '../buttons';
import { InputLabel } from '../labels';

import styles from './EditMskuItemsForm.module.scss';

interface IProps {
  onChange: (value: IInventoryItem[]) => void;
  parentItemFulfillmentCenterID: string;
  isDifferentFC?: boolean;
  values?: IInventoryItem[];
  isAdding: boolean;
  required: boolean;
  readOnly?: boolean;
}

const EditMskuItemsForm = ({
  onChange,
  isAdding,
  values,
  required,
  readOnly,
  parentItemFulfillmentCenterID,
}: IProps) => {
  const dispatch = useDispatch();
  const { mobile } = useWindowSize();

  const isMskuEditModeEnabled = useSelector(selectIsMskuOptionsFormShown);

  const toggleIsMskuEditModeEnabled = React.useCallback(
    (isShown: boolean) => {
      dispatch(toggleMskuOptionsForm(isShown));
    },
    [dispatch],
  );

  const isPaginatedListShown = React.useMemo(
    () => isAdding || isMskuEditModeEnabled,
    [isMskuEditModeEnabled, isAdding],
  );

  const handleItemsChange = React.useCallback(
    (items: IInventoryItem[]) => {
      onChange(items);
    },
    [onChange],
  );

  const handleAvailableItemsSelect = React.useCallback(
    (item: IInventoryItem) => {
      const itemsList = values?.slice() || [];
      // Check if item was added earlier
      const requiredItem = itemsList?.find((el: IInventoryItem) => el.item_id === item.item_id);
      if (requiredItem) {
        return;
      }

      handleItemsChange(requiredItem && itemsList ? itemsList : [...itemsList, item]);
    },
    [values, handleItemsChange],
  );

  const handleOnDeleteAddedItems = React.useCallback(
    (item: IInventoryItem) => {
      const itemsList = values?.slice();
      const requiredItemIndex = itemsList?.findIndex((el: any) => el.item_id === item.item_id);

      if (itemsList && requiredItemIndex !== undefined && requiredItemIndex >= 0) {
        itemsList?.splice(requiredItemIndex, 1);
        handleItemsChange(itemsList);
      }
    },
    [handleItemsChange, values],
  );

  const isDisabledFunction = (item: IInventoryItem) =>
    Boolean(
      (values && values?.length > 0 && !isSameFC(values || [])) ||
        (parentItemFulfillmentCenterID && parentItemFulfillmentCenterID !== item.fulfillment_center_id) ||
        values?.find((i) => item.item_id === i.item_id),
    );

  const addedItemsMarkup: React.ReactNode = React.useMemo(() => {
    return (
      <DraggableList
        readOnly={readOnly}
        items={values || []}
        render={values?.map((item: IInventoryItem) => {
          const isHighlightedItem = parentItemFulfillmentCenterID !== item.fulfillment_center_id;

          return (
            <div key={item.item_id} className={styles.addedItem}>
              <InventoryViewItem
                item={item}
                isDraggable={!readOnly}
                className={styles.inventoryItem}
                iconClassName={styles.iconInner}
                infoClassName={styles.inventoryInfoContainer}
                onRemove={!readOnly ? () => handleOnDeleteAddedItems(item) : undefined}
                isHighlighted={isHighlightedItem}
              />
            </div>
          );
        })}
        onDragStop={onChange}
      />
    );
  }, [values, readOnly, parentItemFulfillmentCenterID, handleOnDeleteAddedItems, readOnly, onChange]);

  const shouldShowAddButton = React.useMemo(() => {
    if (isAdding || readOnly) {
      return false;
    }

    return !isMskuEditModeEnabled;
  }, [isAdding, isMskuEditModeEnabled, readOnly]);

  React.useEffect(() => () => toggleIsMskuEditModeEnabled(false), [toggleIsMskuEditModeEnabled]);

  return (
    <React.Fragment>
      <div className={cn(styles.container)}>
        {isPaginatedListShown && (
          <AvailableItems
            isDisabledFn={isDisabledFunction}
            containerClassName={styles.availableItemsContent}
            onSelect={handleAvailableItemsSelect}
            types={[InventoryFilterTypesEnum.Physical]}
          />
        )}
        {values?.length ? (
          <div
            className={cn(styles.addedItemsWrapper, {
              [styles.empty]: !(values && values?.length),
              [styles.wide]: isPaginatedListShown,
            })}
          >
            <InputLabel className={styles.label} value="Added Items" required={required} />
            <div className={styles.content}>
              {values && values?.length ? addedItemsMarkup : <NoResultsPlaceholder />}
            </div>
          </div>
        ) : null}
      </div>
      {shouldShowAddButton && (
        <ActionButton
          outlined={!mobile}
          title="Add more items"
          onClick={() => toggleIsMskuEditModeEnabled(true)}
          className={styles.addItemsBtn}
        />
      )}
    </React.Fragment>
  );
};

export default EditMskuItemsForm;
