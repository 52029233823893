import {
  IApiError,
  IEmergencyNotification,
  IImproveTextPayload,
  IMetadata,
  ISendFeedbackPayload,
  ITheme,
  TToggleActionPayload,
} from '../../types/shell';
import { IGenerateGiftSuggestRequestPayload, IGenerateGiftSuggestSuccessPayload } from '../../types/suggestedGift';

export const INIT_APP = 'INIT_APP';

export const FIREBASE_PROXY_ACTION = 'FIREBASE_PROXY_ACTION';
export const FIREBASE_PROXY_RELOAD = 'FIREBASE_PROXY_RELOAD';
export const FIREBASE_PROXY_LOGOUT = 'FIREBASE_PROXY_LOGOUT';

export const ROUTER_PROXY_ACTION = 'ROUTER_PROXY_ACTION';
export const ROUTER_PROXY_PUSH = 'ROUTER_PROXY_PUSH';
export const ROUTER_PROXY_REPLACE = 'ROUTER_PROXY_REPLACE';
export const ROUTER_PROXY_GET_STATE = 'ROUTER_PROXY_GET_STATE';

export const TOGGLE_UI_ELEMENT = 'TOGGLE_UI_ELEMENT';

export const FETCH_METADATA_REQUEST = 'FETCH_METADATA_REQUEST';
export const FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS';
export const FETCH_METADATA_FAILURE = 'FETCH_METADATA_FAILURE';

export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';

export const FETCH_THEME_REQUEST = 'FETCH_THEME_REQUEST';
export const FETCH_THEME_SUCCESS = 'FETCH_THEME_SUCCESS';
export const FETCH_THEME_FAILURE = 'FETCH_THEME_FAILURE';

export const TOGGLE_ADDITIONAL_ORGANIZATION_FORM = 'TOGGLE_ADDITIONAL_ORGANIZATION_FORM';
export const TOGGLE_MSKU_OPTIONS_FORM = 'TOGGLE_MSKU_OPTIONS_FORM';

export const UPDATE_EMERGENCY_NOTIFICATION = 'UPDATE_EMERGENCY_NOTIFICATION';

export const UPDATE_LOW_ITEMS_SNOOZED_AT = 'UPDATE_LOW_ITEMS_SNOOZED_AT';
export const UPDATE_LOW_ITEMS_SHOWN_AT = 'UPDATE_LOW_ITEMS_SHOWN_AT';

export const IMPROVE_TEXT_REQUEST = 'IMPROVE_TEXT_REQUEST';
export const IMPROVE_TEXT_SUCCESS = 'IMPROVE_TEXT_SUCCESS';
export const IMPROVE_TEXT_FAILURE = 'IMPROVE_TEXT_FAILURE';

export const GENERATE_GIFT_SUGGEST_REQUEST = 'GENERATE_GIFT_SUGGEST_REQUEST';
export const GENERATE_GIFT_SUGGEST_SUCCESS = 'GENERATE_GIFT_SUGGEST_SUCCESS';
export const GENERATE_GIFT_SUGGEST_FAILURE = 'GENERATE_GIFT_SUGGEST_FAILURE';

export const TOGGLE_IS_AI_EXTENSION_INSTALLED = 'TOGGLE_IS_AI_EXTENSION_INSTALLED';

export const initAppAction = (payload: any) => ({
  type: INIT_APP,
  payload,
});

export const firebaseProxyReload = () => ({
  type: FIREBASE_PROXY_ACTION,
  proxy: FIREBASE_PROXY_RELOAD,
});

export const firebaseProxyLogout = () => ({
  type: FIREBASE_PROXY_ACTION,
  proxy: FIREBASE_PROXY_LOGOUT,
});

export const routerProxyPush = (pathname: string, meta?: any) => ({
  type: ROUTER_PROXY_ACTION,
  proxy: ROUTER_PROXY_PUSH,
  pathname,
  meta,
});

export const routerProxyReplace = (pathname: string, meta?: any) => ({
  type: ROUTER_PROXY_ACTION,
  proxy: ROUTER_PROXY_REPLACE,
  pathname,
  meta,
});

export const routerProxyGetState = () => ({
  type: ROUTER_PROXY_ACTION,
  proxy: ROUTER_PROXY_GET_STATE,
});

export const toggleUiElement = (payload: TToggleActionPayload) => ({
  type: TOGGLE_UI_ELEMENT,
  payload,
});

export const sendFeedbackRequest = (payload: ISendFeedbackPayload) => ({
  type: SEND_FEEDBACK_REQUEST,
  payload,
});

export const sendFeedbackSuccess = (payload: any) => ({
  type: SEND_FEEDBACK_SUCCESS,
  payload,
});

export const sendFeedbackFailure = (payload: any) => ({
  type: SEND_FEEDBACK_FAILURE,
  payload,
});

export const fetchMetadataRequest = () => ({
  type: FETCH_METADATA_REQUEST,
});

export const fetchMetadataSuccess = (payload: { metadata: IMetadata }) => ({
  type: FETCH_METADATA_SUCCESS,
  payload,
});

export const fetchMetadataFailure = (payload: any) => ({
  type: FETCH_METADATA_FAILURE,
  payload,
});

export const fetchThemeRequest = () => ({
  type: FETCH_THEME_REQUEST,
});

export const fetchThemeSuccess = (payload: ITheme) => ({
  type: FETCH_THEME_SUCCESS,
  payload,
});

export const fetchThemeFailure = (payload: IApiError | Error | unknown) => ({
  type: FETCH_THEME_FAILURE,
  payload,
});

export const toggleOrganizationForm = (payload?: boolean) => ({
  type: TOGGLE_ADDITIONAL_ORGANIZATION_FORM,
  payload,
});

export const toggleMskuOptionsForm = (payload?: boolean) => ({
  type: TOGGLE_MSKU_OPTIONS_FORM,
  payload,
});

export const updateLowItemsSnoozedAt = (payload: string) => ({
  type: UPDATE_LOW_ITEMS_SNOOZED_AT,
  payload,
});

export const updateLowItemsShownAt = (payload: string) => ({
  type: UPDATE_LOW_ITEMS_SHOWN_AT,
  payload,
});

export const improveTextRequest = (payload: IImproveTextPayload) => ({
  type: IMPROVE_TEXT_REQUEST,
  payload,
});

export const improveTextSuccess = (payload: { text: string }) => ({
  type: IMPROVE_TEXT_SUCCESS,
  payload,
});

export const improveTextFailure = (payload: any) => ({
  type: IMPROVE_TEXT_FAILURE,
  payload,
});

export const updateEmergencyNotification = (payload: IEmergencyNotification | null) => ({
  type: UPDATE_EMERGENCY_NOTIFICATION,
  payload,
});

export const generateGiftSuggestRequest = (payload: IGenerateGiftSuggestRequestPayload) => ({
  type: GENERATE_GIFT_SUGGEST_REQUEST,
  payload,
});

export const generateGiftSuggestSuccess = (payload: IGenerateGiftSuggestSuccessPayload) => ({
  type: GENERATE_GIFT_SUGGEST_SUCCESS,
  payload,
});

export const generateGiftSuggestFailure = (payload: any) => ({
  type: GENERATE_GIFT_SUGGEST_FAILURE,
  payload,
});

export const toggleIsAIExtensionInstalled = (payload: boolean) => ({
  type: TOGGLE_IS_AI_EXTENSION_INSTALLED,
  payload,
});
