import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query';
import { UseQueryOptions } from '@tanstack/react-query/src/types';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { selectUserAccessToken } from '../store/selectors/auth';

export default function useAuthQuery<TResponse>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResponse>,
  options: UseQueryOptions<TResponse>,
) {
  const firebase = useFirebase();
  const token = useSelector(selectUserAccessToken);
  const tokenRef = useRef({ token });

  useEffect(() => {
    tokenRef.current.token = token;
  }, [token]);

  const wait = (oldToken?: string, timeout = 5000) => {
    let intervalId: NodeJS.Timeout;
    let timeoutId: NodeJS.Timeout;

    return Promise.race([
      new Promise<void>((res) => {
        intervalId = setInterval(() => {
          if (typeof oldToken !== 'undefined' && oldToken !== tokenRef.current.token) {
            res();
          }
        }, 50);
      }),
      new Promise((res, rej) => {
        timeoutId = setTimeout(() => {
          rej();
        }, timeout);
      }),
    ]).finally(() => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    });
  };

  const { ...results } = useQuery<TResponse>(queryKey, queryFn, options);
  useEffect(() => {
    if ((results.error as Response)?.status === 401) {
      if (results.errorUpdateCount === 1) {
        firebase
          .reloadAuth()
          .then(() => wait(token))
          .then(async () => {
            await results.refetch();
          });
      } else {
        firebase.logout();
      }
    }
  }, [results, firebase]);

  return results;
}
