import * as React from 'react';
import { useSelector } from 'react-redux';

import { MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING } from '../../../constants/inventories';
import { selectInventoryItemById } from '../../../store/selectors/inventories';
import { IInventoryItem } from '../../../types/inventories';
import { isSameFC } from '../../../utils/inventories';
import { ActionButton, BackButton } from '../../forms';
import { InventoryViewItem, ModalActions, ModalHeader, WarningMessage } from '../../index';

import styles from './MskuDetailsModal.module.scss';

interface IProps {
  name: string;
  skuOptions: IInventoryItem[];
  onClose: () => void;
  showCount?: boolean;
  parentId?: string;
}

const MSKUDetailsModal: React.FC<IProps> = ({ name, skuOptions, onClose, showCount = true, parentId }: IProps) => {
  const hasItemsFromDifferentFc = React.useMemo(() => {
    return !isSameFC(skuOptions);
  }, [skuOptions]);

  const parentItem = useSelector(selectInventoryItemById(parentId));

  return (
    <div className={styles.container}>
      <ModalHeader className={styles.header} left={<BackButton className={styles.back} onClick={onClose} />}>
        {name}
      </ModalHeader>
      <div className={styles.modalContent}>
        <React.Fragment>
          {hasItemsFromDifferentFc && (
            <WarningMessage mode="error" message={MSKU_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING} />
          )}
          {skuOptions.map((skuOption) => {
            const { fulfillment_center_id: itemFCId, item_id: itemId } = skuOption || {};
            const isItemHighLighted = itemFCId !== parentItem?.fulfillment_center_id;

            return (
              <InventoryViewItem
                isOutlined
                item={skuOption}
                key={itemId}
                showCount={showCount}
                isHighlighted={isItemHighLighted}
              />
            );
          })}
        </React.Fragment>
      </div>
      <ModalActions className={styles.footer}>
        <ActionButton title="Close" className={styles.button} onClick={onClose} />
      </ModalActions>
    </div>
  );
};

export default MSKUDetailsModal;
