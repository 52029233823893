import cn from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';

import FadeInOut from '../../animations/FadeInOut';
import Overlay from '../../components/Overlay/Overlay';
import useDynamicRoot from '../../hooks/useDynamicRoot';

import styles from './ModalContainer.module.scss';

interface IProps<T> {
  className?: string;
  overlayClassName?: string;
  children(props: T): React.ReactNode;
  childProps: T;
  closeModal: () => void;
  openModal: (props?: T) => void;
  isOpen: boolean;
  required?: boolean;
  resetStyles?: boolean;
}

// tslint:disable-next-line: prettier
const ModalPortal = <PropertyType,>({
  children,
  overlayClassName,
  childProps,
  closeModal,
  isOpen,
  className,
  required = false,
  resetStyles = false,
}: IProps<PropertyType>) => {
  const [containerCreated, setContainerCreated] = React.useState(false);
  const root = useDynamicRoot();
  const el = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (root) {
      el.current = document.createElement('div');
      root.appendChild(el.current);
      setContainerCreated(true);
    }
    return () => {
      if (el.current) {
        root?.removeChild(el.current);
      }
    };
  }, []);

  return containerCreated
    ? ReactDOM.createPortal(
        <React.Fragment>
          <FadeInOut
            isOpen={isOpen}
            className={cn(styles.modalContainer, className, {
              [styles.default]: !resetStyles,
            })}
          >
            {children(childProps)}
          </FadeInOut>
          <Overlay
            {...(!required ? { onClick: closeModal } : {})}
            active={isOpen}
            className={cn(styles.overlay, overlayClassName, { [styles.required]: required })}
          />
        </React.Fragment>,
        el.current!,
      )
    : null;
};

export default ModalPortal;
