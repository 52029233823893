import { createSelector } from 'reselect';

import { RECEIVER_EMAIL, SHIP_ORDER_STATUS } from '../../constants/shipping';
import { IShippingFormValues } from '../../types/shipping';
import { IAppState } from '../reducers';

export const selectShippingInformation = (state: IAppState): Partial<IShippingFormValues> | undefined =>
  state.bucket && state.bucket.shipping ? state.bucket.shipping : undefined;

export const selectReceiverEmail = createSelector(selectShippingInformation, (shipping) =>
  shipping ? shipping[RECEIVER_EMAIL] : undefined,
);

export const selectIsDelayedShipping = createSelector(selectShippingInformation, (shipping) =>
  shipping ? shipping[SHIP_ORDER_STATUS] : undefined,
);
