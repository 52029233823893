import cn from 'classnames';
import * as React from 'react';

import { InventoryTypesEnum } from '../../../../types/inventories';
import { CopyableButton } from '../../../forms';
import { ItemMetadata, Price } from '../../../index';

import styles from './LowInventoryItem.module.scss';

export interface ILowInventoryItem {
  className?: string;
  iconClassName?: string;
  iconUrl?: string;
  itemId: string;
  title: string;
  price: number;
  sku: string;
  count?: number;
  isDigital?: boolean;
  isCustomizable?: boolean;
  type: InventoryTypesEnum;
  isPreorder?: boolean;
}

const LowInventoryItem: React.FC<ILowInventoryItem> = ({
  className,
  iconClassName,
  sku,
  title,
  iconUrl,
  price,
  itemId,
  count,
  type,
  isCustomizable,
  isPreorder,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.iconInner, iconClassName)}>
        <div className={styles.icon} style={{ backgroundImage: `url(${iconUrl})` }} />
        <ItemMetadata
          type={type}
          isCustomizable={isCustomizable}
          itemId={itemId}
          title={title}
          count={count}
          isPreorder={isPreorder}
        />
      </div>
      <div className={styles.meta}>
        <Price value={price} className={styles.price} />
        <div className={styles.title}>{title}</div>
        <CopyableButton className={styles.sku} title={sku} value={sku} inline />
      </div>
    </div>
  );
};

export default LowInventoryItem;
