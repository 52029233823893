import cn from 'classnames';
import * as React from 'react';

import { IHoverContent, TSendsDashboardSummaryUnion } from '../../../types/dashboard';
import { GraphList } from '../../index';
import HoverArea from './HoverArea/HoverArea';

import styles from './SendsSummaryWidget.module.scss';

interface IProps {
  className?: string;
  items?: TSendsDashboardSummaryUnion;
  orgDetails: IHoverContent;
}

const SendsSummaryWidget = ({ className, items, orgDetails }: IProps) => {
  const [hoverContent, setHoverContent] = React.useState<IHoverContent | null>(orgDetails);

  if (!items || !Object.values(items).length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <GraphList
        onPointerLeave={() => setHoverContent(orgDetails)}
        className={styles.graphs}
        onHover={setHoverContent}
        items={items}
      />
      <HoverArea visible={!!hoverContent} count={hoverContent?.count} total={hoverContent?.total} />
    </div>
  );
};

export default SendsSummaryWidget;
